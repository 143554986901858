import { apiSlice } from '@/API/api.slice';
import { Endpoints } from '@/constants/api';
import Tally from '@/types/tally.types';

export const talliesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTallies: builder.query<Tally[], number[]>({
      query: (templateList) => `${Endpoints.Tally}?template_list=${templateList.join('~')}`,
    }),
  }),
});

export const { useGetTalliesQuery } = talliesApi;
