import { Box } from '@chakra-ui/react';
import { CellContext, ColumnFiltersState, createColumnHelper, SortingState, Table } from '@tanstack/react-table';
import { produce } from 'immer';
import React, { useRef } from 'react';

import PaginatedPreviewTable from '@/components/paginated-preview-table/PaginatedPreviewTable';
import PreviewCell from '@/components/preview-cell/PreviewCell';
import TableCell from '@/components/table-cell/TableCell';
import TableHeader from '@/components/table-header/TableHeader';
import TableHeaderSelectAll from '@/components/table-header-select-all/TableHeaderSelectAll';
import Tally from '@/types/tally.types';
import View, { ViewColoredItem } from '@/types/view.types';
import { customSortArray } from '@/utils/arrays';
import SortingUtils from '@/utils/sorting';

// Key to sort items on the table
const TALLY_SORT_KEY = 'tally_id';

// Key to sort items on the view object
const TALLY_FILTER_SORT_KEY = 'id';

enum ViewEditorTalliesColumns {
  DISPLAY_NAME = 'displayName',
  TALLY_ID = 'tallyId',
}

// Sort ascending by default
const DEFAULT_AVAILABLE_TABLE_SORTING: SortingState = [
  {
    desc: false,
    id: ViewEditorTalliesColumns.DISPLAY_NAME,
  },
];

interface ViewEditorTalliesProps {
  talliesData: Tally[];
  availableItemsColumnFilters: ColumnFiltersState;
  handleDebounceFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDebounceFilterPreview: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isTalliesDataLoading: boolean;
  tableInstanceRef: React.MutableRefObject<Table<Tally> | undefined>;
  previewItemsColumnFilters: ColumnFiltersState;
  previewTableInstanceRef: React.MutableRefObject<Table<Tally> | undefined>;
  unfilteredTallyIds: number[];
  unfilteredPreviewTallyIds: number[];
  updateView: (view: View) => void;
  view: View;
}

const ViewEditorTallies = (props: ViewEditorTalliesProps) => {
  const {
    talliesData,
    availableItemsColumnFilters,
    handleDebounceFilter,
    handleDebounceFilterPreview,
    isTalliesDataLoading,
    previewTableInstanceRef,
    previewItemsColumnFilters,
    tableInstanceRef,
    unfilteredTallyIds,
    unfilteredPreviewTallyIds,
    updateView,
    view,
  } = props;
  const { filter } = view;

  // A memoized list of all tallies structure ids which are currently contained in the view filter
  const currentTallyIds = React.useMemo(() => {
    return filter.on_tallies.map((tally) => Number(tally.id));
  }, [filter]);

  // Contains a list of all available tallies which are currently selected
  const [selectedAvailableTallyIds, setSelectedAvailableTallyIds] = React.useState<Set<number>>(
    new Set(currentTallyIds),
  );
  // Contains a list of all tally rows in the preview table which are currently selected (impacts color highlighting and DnD)
  const [selectedPreviewRowTallyIds, setSelectedPreviewRowTallyIds] = React.useState<Set<number>>(new Set());
  const [selectAllAvailableIsToggled, setSelectAllAvailableIsToggled] = React.useState<boolean>(false);
  const [selectAllPreviewIsToggled, setSelectAllPreviewIsToggled] = React.useState<boolean>(false);

  // Contains a list of all tallies in the preview table. These are the tallies which will be saved to the view
  const [previewData, setPreviewData] = React.useState<Tally[]>([]);
  const [availableTableSortingState, setAvailableTableSortingState] = React.useState<SortingState>(
    DEFAULT_AVAILABLE_TABLE_SORTING,
  );
  const [previewTableSortingState, setPreviewTableSortingState] = React.useState<SortingState>([]);

  const [orderChanged, setOrderChanged] = React.useState<boolean>(false);

  // A memoized list of all tally structure ids which are available in the preview table
  // This list includes all tallies which are currently selected, as well as all tallies which are not selected
  const allTallyIds = React.useMemo(() => {
    if (talliesData?.length) {
      return new Set(talliesData.map((tally) => tally.tally_id));
    }

    return new Set<number>();
  }, [talliesData, selectAllAvailableIsToggled]);

  // Selected tallies IDs
  const selectedIDs = React.useMemo(() => {
    return previewData.map((item) => item.tally_id);
  }, [previewData]);

  const currentTallies = React.useMemo(() => {
    const tallies = talliesData?.filter((tally) => selectedAvailableTallyIds.has(tally.tally_id)) ?? [];

    // Sort current(selected) tallies based on the order they have been saved
    const sortedTallies = customSortArray(tallies, [...selectedAvailableTallyIds], TALLY_SORT_KEY);

    return sortedTallies;
  }, [talliesData, selectedAvailableTallyIds]);

  const normalizedTallyDataRef = useRef<ViewColoredItem[]>([]);

  normalizedTallyDataRef.current = currentTallies.map((tally) => {
    return {
      color: null,
      colorText: null,
      id: tally.tally_id,
    };
  });

  // When the current tallies change, update the preview table data
  React.useMemo(() => {
    setPreviewData(currentTallies);
  }, [currentTallies]);

  React.useMemo(() => {
    if (selectAllAvailableIsToggled) {
      // Add all selected tallies
      updateView(
        produce(view, (draft) => {
          // eslint-disable-next-line camelcase
          draft.filter.on_tallies = normalizedTallyDataRef.current;
        }),
      );
    } else {
      // Remove all tallies
      updateView(
        produce(view, (draft) => {
          // eslint-disable-next-line camelcase
          draft.filter.on_tallies = [];
        }),
      );
    }
  }, [selectAllAvailableIsToggled]);

  const handleSelectAllAvailableTallies = React.useCallback(() => {
    if (!talliesData?.length) return;

    // Handle selecting of all items
    if (!selectAllAvailableIsToggled) {
      // If there are no filters, select all tallies
      if (!unfilteredTallyIds.length) {
        setSelectedAvailableTallyIds(allTallyIds);
      } else {
        // If there are filters, select all tallies that match the filters
        const updatedSelectedTallyIds = produce(selectedAvailableTallyIds, (draft) => {
          unfilteredTallyIds.forEach((id) => draft.add(id));
        });

        setSelectedAvailableTallyIds(updatedSelectedTallyIds);
      }

      setSelectAllAvailableIsToggled(true);

      return;
    }

    // Handle deselecting of all items
    // If there are no filters, deselect all tallies
    if (!unfilteredTallyIds.length) {
      setSelectedAvailableTallyIds(produce(selectedAvailableTallyIds, (draft) => draft.clear()));
    } else {
      // If there are filters, deselect all tallies that match the filters
      const updatedSelectedTallyIds = produce(selectedAvailableTallyIds, (draft) => {
        unfilteredTallyIds.forEach((id) => draft.delete(id));
      });

      setSelectedAvailableTallyIds(updatedSelectedTallyIds);
    }

    setSelectAllAvailableIsToggled(false);
  }, [talliesData, selectAllAvailableIsToggled, selectedAvailableTallyIds, unfilteredTallyIds]);

  const handleSelectAllPreviewTallies = React.useCallback(() => {
    if (!previewData?.length) return;

    // Handle selecting of all items
    if (!selectAllPreviewIsToggled) {
      // If there are no filters, select all tallies
      if (!unfilteredPreviewTallyIds.length) {
        setSelectedPreviewRowTallyIds(new Set([...currentTallyIds]) as Set<number>);
      } else {
        // If there are filters, select all tallies that match the filters
        const updatedSelectedPreviewTallyIds = produce(selectedPreviewRowTallyIds, (draft) => {
          unfilteredPreviewTallyIds.forEach((id) => draft.add(id));
        });

        setSelectedPreviewRowTallyIds(updatedSelectedPreviewTallyIds);
      }

      setSelectAllPreviewIsToggled(true);

      return;
    }

    // Handle deselecting of all items
    // If there are no filters, deselect all tallies
    if (!unfilteredPreviewTallyIds.length) {
      setSelectedPreviewRowTallyIds(produce(selectedPreviewRowTallyIds, (draft) => draft.clear()));
    } else {
      // If there are filters, deselect all tallies that match the filters
      const updatedSelectedPreviewTallyIds = produce(selectedPreviewRowTallyIds, (draft) => {
        unfilteredPreviewTallyIds.forEach((id) => draft.delete(id));
      });

      setSelectedPreviewRowTallyIds(updatedSelectedPreviewTallyIds);
    }

    setSelectAllPreviewIsToggled(false);
  }, [previewData, selectAllPreviewIsToggled, selectedPreviewRowTallyIds, unfilteredPreviewTallyIds, currentTallyIds]);

  // Available tallies search bar
  const getAvailableItemsTableHeader = () => {
    return <TableHeader label="Available Tallies" placeholder="Search Available" handleFilter={handleDebounceFilter} />;
  };

  // Available tallies select all button on the table header
  const getAvailableItemsTableHeaderButton = () => {
    return (
      <TableHeaderSelectAll
        handleSelectAll={handleSelectAllAvailableTallies}
        selectAllIsToggled={selectAllAvailableIsToggled}
      />
    );
  };

  // Selected tallies search bar
  const getPreviewTableHeader = () => {
    return (
      <TableHeader label="Selected Tallies" placeholder="Search Selected" handleFilter={handleDebounceFilterPreview} />
    );
  };

  // Selected tallies select all button on the table header
  const getPreviewItemsTableHeaderButton = () => {
    return (
      <TableHeaderSelectAll
        handleSelectAll={handleSelectAllPreviewTallies}
        selectAllIsToggled={selectAllPreviewIsToggled}
      />
    );
  };

  const columnHelper = createColumnHelper<Tally>();

  const handleTallyRowClick = (rowTallyId: number, isCurrentlySelected: boolean) => {
    if (isCurrentlySelected) {
      updateView(
        produce(view, (draft) => {
          // eslint-disable-next-line camelcase
          draft.filter.on_tallies = draft.filter.on_tallies.filter((onTally) => onTally.id !== rowTallyId);
        }),
      );
      setSelectedAvailableTallyIds((prev) => {
        return produce(prev, (draft) => {
          draft.delete(rowTallyId);
        });
      });
    } else {
      updateView(
        produce(view, (draft) => {
          // eslint-disable-next-line camelcase
          draft.filter.on_tallies.push({
            color: null,
            colorText: null,
            id: rowTallyId,
          });
        }),
      );
      setSelectedAvailableTallyIds((prev) =>
        produce(prev, (draft) => {
          draft.add(rowTallyId);
        }),
      );
    }
  };

  const handlePreviewCellClick = (rowTallyId: number) => {
    setSelectedPreviewRowTallyIds((prev) => {
      const updated = new Set(prev);

      if (prev.has(rowTallyId)) updated.delete(rowTallyId);
      else updated.add(rowTallyId);

      return updated;
    });
  };

  const getCell = (props: CellContext<Tally, unknown>) => {
    // eslint-disable-next-line react/prop-types
    const { row } = props;
    // eslint-disable-next-line react/prop-types, camelcase
    const { tally_id: tallyId, tally_name: tallyName, template_name: templateName } = row.original;

    // eslint-disable-next-line react/prop-types
    if (!row?.original) return null;

    const isSelected = selectedAvailableTallyIds.has(tallyId);
    const appendedTemplateName = templateName ? `(${templateName})` : '';

    return (
      <TableCell
        onClick={() => handleTallyRowClick(tallyId, isSelected)}
        isSelected={isSelected}
        text={`${tallyName} ${appendedTemplateName}`}
      />
    );
  };

  const getPreviewCell = (props: CellContext<Tally, unknown>) => {
    // eslint-disable-next-line react/prop-types
    const { row } = props;

    // eslint-disable-next-line react/prop-types
    if (!row?.original) return null;

    // eslint-disable-next-line react/prop-types, camelcase
    const { tally_id: tallyId, tally_name: tallyName, template_name: templateName } = row.original;

    // eslint-disable-next-line react/prop-types
    const isSelected = selectedPreviewRowTallyIds.has(tallyId);
    const appendedTemplateName = templateName ? `(${templateName})` : '';

    return (
      <PreviewCell
        onClick={() => handlePreviewCellClick(tallyId)}
        isSelected={isSelected}
        text={`${tallyName} ${appendedTemplateName}`}
      />
    );
  };

  const availableItemsColumns = [
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.tally_name, {
      cell: getCell,
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorTalliesColumns.DISPLAY_NAME,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.tally_id, {
      cell: undefined,
      enableHiding: true,
      id: ViewEditorTalliesColumns.TALLY_ID,
    }),
  ];

  const previewColumnsHelper = createColumnHelper<Tally>();

  const previewColumns = [
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.tally_name, {
      cell: getPreviewCell,
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorTalliesColumns.DISPLAY_NAME,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.tally_id, {
      cell: undefined,
      enableHiding: true,
      id: ViewEditorTalliesColumns.TALLY_ID,
    }),
  ];

  const handleOrderTallies = (data: Tally[]) => {
    // Set ordered preview data
    setPreviewData(data);
    // Set order changed (needed to update the view filter on personnel)
    setOrderChanged(true);
  };

  React.useMemo(() => {
    if (orderChanged) {
      // Current(selected) tallies
      const talliesFilter = [...view.filter.on_tallies];

      // Sort current(selected) tallies based on the order the user defines(via drag&drop or with move buttons)
      const sortedTallies = customSortArray(talliesFilter, [...selectedIDs], TALLY_FILTER_SORT_KEY);

      const updatedView = produce(view, (draft) => {
        // eslint-disable-next-line camelcase
        draft.filter.on_tallies = sortedTallies;
      });

      // Update view with the new personnel(ordered)
      updateView(updatedView);
      // Reset order changed
      setOrderChanged(false);
    }
  }, [previewData, orderChanged, selectedIDs]);

  const handlePreviewTableSorting = () => {
    const isDesc = SortingUtils.getSorting(previewTableSortingState, ViewEditorTalliesColumns.DISPLAY_NAME);
    SortingUtils.setSorting(ViewEditorTalliesColumns.DISPLAY_NAME, isDesc, setPreviewTableSortingState);
    // Set order changed (needed to update the view filter on personnel)
    setOrderChanged(true);
  };

  const handleAvailableTableSorting = () => {
    const isDesc = SortingUtils.getSorting(availableTableSortingState, ViewEditorTalliesColumns.DISPLAY_NAME);
    SortingUtils.setSorting(ViewEditorTalliesColumns.DISPLAY_NAME, isDesc, setAvailableTableSortingState);
  };

  return (
    <PaginatedPreviewTable
      availableItemsTableColumnFilters={availableItemsColumnFilters}
      availableItemsTableColumns={availableItemsColumns}
      availableItemsTableColumnVisibility={{
        tallyId: false,
      }}
      availableItemsTableData={talliesData ?? []}
      availableItemsTableHeader={getAvailableItemsTableHeader()}
      availableItemsTableHeaderButton={getAvailableItemsTableHeaderButton()}
      availableItemsTableInstanceRef={tableInstanceRef}
      availableItemsTableSortingState={availableTableSortingState}
      availableRowUniqueKey={TALLY_SORT_KEY}
      headerSortHandlers={[
        {
          columnId: ViewEditorTalliesColumns.DISPLAY_NAME,
          sortHandler: () => handleAvailableTableSorting(),
        },
      ]}
      isLoading={isTalliesDataLoading}
      previewHeaderSortHandlers={[
        {
          columnId: ViewEditorTalliesColumns.DISPLAY_NAME,
          sortHandler: () => handlePreviewTableSorting(),
        },
      ]}
      previewTableColumns={previewColumns}
      previewItemsTableInstanceRef={previewTableInstanceRef}
      previewTableColumnFilters={previewItemsColumnFilters}
      previewTableColumnVisibility={{
        tallyId: false,
      }}
      previewTableData={previewData}
      previewTableHeader={getPreviewTableHeader()}
      previewTableHeaderButton={getPreviewItemsTableHeaderButton()}
      previewRowsAreDraggable={true}
      previewRowUniqueKey={TALLY_SORT_KEY}
      previewTableSortingState={previewTableSortingState}
      previewTableDragHandler={(items) => handleOrderTallies(items as Tally[])}
      previewTableUniqueSelectedIds={selectedPreviewRowTallyIds}
      showColorPicker={false}
      handleResetSorting={() => setPreviewTableSortingState([])}
    />
  );
};

export default ViewEditorTallies;
