import { AccessorFnColumnDef, CellContext, ColumnFiltersState } from '@tanstack/react-table';
import { Draft, WritableDraft } from 'immer';
import React from 'react';
import { FaTrashCan } from 'react-icons/fa6';
import { createUseStyles } from 'react-jss';
import { useImmer } from 'use-immer';

import { cellFactory } from '@/components/available-preview-list/aplUtils';
import PaginatedPreviewTable from '@/components/paginated-preview-table/PaginatedPreviewTable';
import TableHeader from '@/components/table-header/TableHeader';
import TableHeaderSelectAll from '@/components/table-header-select-all/TableHeaderSelectAll';
import { TableOrderDesc } from '@/constants/enums';

enum ColumnFilterStateProperty {
  AvailableItemsColumnFilters = 'availableItemsColumnFilters',
  PreviewItemsColumnFilters = 'previewItemsColumnFilters',
}

type TableColumnIds = { [key: string]: string }

// An item with a unique id and name, which is filterable by department and template
type DINT = {
  readonly departments: number[];
  readonly id: number;
  readonly name: string;
  readonly templates: number[];
};

type APLItem<T> = T & DINT;

type ItemMap<T> = Map<number, APLItem<T>>;

interface ITableState<T> {
  availableItems: ItemMap<T>;
  // React Table column def appeasement
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availableColumns: AccessorFnColumnDef<APLItem<T>, any>[];
  availableItemsColumnFilters: ColumnFiltersState;
  isLoading: boolean;
  previewColumns: AccessorFnColumnDef<APLItem<T>>[];
  previewItems: ItemMap<T>;
  previewItemsColumnFilters: ColumnFiltersState;
  selectAllAvailableIsToggled: boolean;
  selectedPreviewRowViewIds: Set<number>;
  tableOrderDesc: TableOrderDesc;
  unfilteredViewIds: number[];
}

interface IAvailablePreviewListProps<T> {
  // React Table column def appeasement
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly availableColumns: AccessorFnColumnDef<APLItem<T>, any>[];
  readonly availableItems: ItemMap<T>;
  readonly availableItemsPrimaryColumnId: string;
  readonly availableItemsTableLabel: string;
  readonly availableRowUniqueKey: string;
  readonly currentlySelectedItemIds: number[];
  readonly isOpen: boolean;
  // React Table column def appeasement
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly previewColumns: AccessorFnColumnDef<APLItem<T>, any>[];
  readonly previewItems: ItemMap<T>;
  readonly previewItemsPrimaryColumnId: string;
  readonly previewItemsTableLabel: string;
  readonly previewRowUniqueKey: string;
  readonly primaryAvailableColumnIndex: number;
  readonly primaryPreviewColumnIndex: number;
  readonly selectedDepartments: number[];
  readonly selectedItemsChangedCallback: (selectedIds: number[]) => void;
  readonly selectedTemplates: number[];
  readonly tableColumnIds: TableColumnIds;
  readonly previewRowsAreDraggable?: boolean;
  readonly showColorPicker?: boolean;
  readonly showSideControls?: boolean;
}

const useAvailableRowStyle = createUseStyles({
  row: {
    '&:hover': {
      backgroundColor: '#2380f8',
      color: 'white',
    },
    cursor: 'pointer',
    transition: 'all 0.2s linear',
  },
});

const usePreviewRowStyle = createUseStyles({
  icon: {
    '&:hover': {
      color: '#d21515',
    },
    color: '#5b5757',
    cursor: 'pointer',
    transition: 'all 0.2s linear',
  },
});

const clearColumnFilter = <T, >(
  columnName: keyof TableColumnIds,
  filterStateDraft: WritableDraft<ITableState<T>>,
  columnFilterProperty: ColumnFilterStateProperty,
) => {

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  filterStateDraft[columnFilterProperty.valueOf()] = filterStateDraft[columnFilterProperty.valueOf()].filter((filterValue) => filterValue.id !== columnName,
  );
};

const updateColumnFilter = <T, >(
  columnName: keyof TableColumnIds,
  filterStateDraft: WritableDraft<ITableState<T>>,
  columnFilterProperty: ColumnFilterStateProperty,
  filterValue: string | number[],
) => {
  clearColumnFilter(columnName, filterStateDraft, columnFilterProperty);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  filterStateDraft[columnFilterProperty.valueOf()].push({
    id: columnName,
    value: filterValue,
  });
};

const AvailablePreviewList = <T, >(props: IAvailablePreviewListProps<T>): React.JSX.Element => {
  const {
    availableColumns,
    availableItems,
    availableItemsPrimaryColumnId,
    availableItemsTableLabel,
    availableRowUniqueKey,
    currentlySelectedItemIds,
    isOpen,
    previewColumns,
    previewItems,
    previewItemsPrimaryColumnId,
    previewItemsTableLabel,
    previewRowUniqueKey,
    primaryAvailableColumnIndex,
    primaryPreviewColumnIndex,
    previewRowsAreDraggable = false,
    selectedDepartments,
    selectedItemsChangedCallback,
    selectedTemplates,
    showColorPicker = false,
    showSideControls = false,
    tableColumnIds,
  } = props;

  const [tableState, updateTableState] = useImmer<ITableState<T>>({
    availableColumns: [],
    availableItems: availableItems ?? new Map(),
    availableItemsColumnFilters: [],
    isLoading: false,
    previewColumns: [],
    previewItems: previewItems ?? new Map(),
    previewItemsColumnFilters: [],
    selectAllAvailableIsToggled: false,
    selectedPreviewRowViewIds: new Set(currentlySelectedItemIds),
    tableOrderDesc: { availableItemsDesc: false, previewItemsDesc: false },
    unfilteredViewIds: [],
  });

  const availableClasses = useAvailableRowStyle();
  const previewClasses = usePreviewRowStyle();

  const handleAvailableItemClick = React.useCallback((id: number) => {
    updateTableState((draft) => {
      const view = draft.availableItems.get(id);

      if (!view) return draft;

      draft.availableItems.delete(id);
      draft.previewItems.set(id, view);
    });
  }, [updateTableState]);

  const handlePreviewItemClick = React.useCallback((id: number) => {
    updateTableState((draft) => {
      const item = draft.previewItems.get(id);

      if (!item) return;

      draft.previewItems.delete(id);

      // Check if view matches filters
      const viewBelongsToASelectedDepartment = selectedDepartments.some((deptId) => item.departments.includes(deptId));

      if (!viewBelongsToASelectedDepartment) return;

      draft.availableItems.set(id, item);
    });
  }, [selectedDepartments, updateTableState]);

  // Available views select all button on the table header
  const getAvailableItemsTableHeaderButton = () => {
    return (
      <TableHeaderSelectAll
        handleSelectAll={() => updateTableState((draft) => {
          draft.selectAllAvailableIsToggled = !draft.selectAllAvailableIsToggled;
        })}
        isDisabled={!tableState.availableItems.size}
        selectAllIsToggled={tableState.selectAllAvailableIsToggled}
      />
    );
  };

  // Selected views search bar
  const getPreviewTableHeader = () => {
    return (
      <TableHeader
        handleFilter={(e) => {
          updateTableState((draft) => {
            updateColumnFilter(
              tableColumnIds[previewItemsPrimaryColumnId],
              draft,
              ColumnFilterStateProperty.PreviewItemsColumnFilters,
              e.target.value,
            );
          });
        }}
        label={previewItemsTableLabel}
        placeholder="Search Selected"
      />
    );
  };

  // Available views search bar
  const getAvailableItemsTableHeader = () => {
    return (
      <TableHeader
        handleFilter={(e) => {
          updateTableState((draft) => {
            updateColumnFilter(
              tableColumnIds[availableItemsPrimaryColumnId],
              draft,
              ColumnFilterStateProperty.AvailableItemsColumnFilters,
              e.target.value,
            );
          });
        }}
        label={availableItemsTableLabel}
        placeholder={'Search Available'}
      />
    );
  };


  React.useEffect(() => {
    console.log('isLoading: ', tableState.isLoading);
    if (tableState.isLoading) return;

    console.log('We is here');
    const selectedItemIds = [...tableState.selectedPreviewRowViewIds];
    const previousSelected = JSON.stringify(currentlySelectedItemIds);
    const currentSelected = JSON.stringify(selectedItemIds);

    if (previousSelected !== currentSelected) {
      selectedItemsChangedCallback(selectedItemIds);
    }
  }, [
    currentlySelectedItemIds,
    selectedItemsChangedCallback,
    tableState.isLoading,
    tableState.selectedPreviewRowViewIds,
  ]);

  React.useEffect(() => {
    if (tableState.isLoading) return;

    updateTableState((draft) => {
      draft.selectedPreviewRowViewIds = new Set(tableState.previewItems.keys());
    });
  }, [tableState.isLoading, tableState.previewItems, updateTableState]);

  React.useEffect(() => {
    updateTableState((draft) => {
      updateColumnFilter(
        tableColumnIds.templateIds,
        draft,
        ColumnFilterStateProperty.AvailableItemsColumnFilters,
        selectedTemplates,
      );
    });
  }, [selectedTemplates, tableColumnIds.templateIds, updateTableState]);

  React.useEffect(() => {
    if (tableState.selectAllAvailableIsToggled) {
      updateTableState((draft) => {
        for (const [k, v] of draft.availableItems.entries()) {
          draft.previewItems.set(k, v);
        }

        draft.availableItems.clear();
        draft.selectAllAvailableIsToggled = false;
      });
    }
  }, [tableState.selectAllAvailableIsToggled, updateTableState]);

  React.useEffect(() => {
    updateTableState((draft) => {
      const currentColumns = [...availableColumns];

      const currentPrimaryColumnCell = (item: CellContext<APLItem<T>, unknown>) => cellFactory<APLItem<T>>({
        cellContext: item,
        className: availableClasses.row,
        clickHandler: handleAvailableItemClick,
        text: item.row.original.name,
      });
      currentColumns[primaryAvailableColumnIndex] = {
        ...currentColumns[primaryAvailableColumnIndex],
        cell: currentPrimaryColumnCell,
      };

      draft.availableColumns = currentColumns;
    });
  }, [
    availableColumns,
    handleAvailableItemClick,
    primaryAvailableColumnIndex,
    updateTableState,
  ]);

  React.useEffect(() => {
    updateTableState((draft) => {
      const currentColumns = [...previewColumns];

      const currentPrimaryColumnCell = (item: CellContext<APLItem<T>, unknown>) => cellFactory<APLItem<T>>({
        actionIcon: <FaTrashCan className={previewClasses.icon} />,
        cellContext: item,
        clickHandler: handlePreviewItemClick,
        text: item.row.original.name,
      });
      currentColumns[primaryPreviewColumnIndex] = {
        ...currentColumns[primaryPreviewColumnIndex],
        cell: currentPrimaryColumnCell,
      };

      draft.previewColumns = currentColumns;
    });
  }, [handlePreviewItemClick, previewClasses.icon, previewColumns, primaryPreviewColumnIndex, updateTableState]);

  React.useEffect(() => {
    updateTableState((draft) => {
      draft.availableItems = availableItems as Draft<ItemMap<T>>;
    });
  }, [availableItems, updateTableState]);

  React.useEffect(() => {
    updateTableState((draft) => {
      draft.previewItems = previewItems as Draft<ItemMap<T>>;
    });
  }, [previewItems, updateTableState]);

  if (!isOpen) return <></>;

  return (
    <PaginatedPreviewTable
      availableItemsTableColumnFilters={tableState.availableItemsColumnFilters}
      availableItemsTableColumns={tableState.availableColumns}
      // ToDo: Lift out to props
      availableItemsTableColumnVisibility={{
        viewId: false,
      }}
      availableItemsTableData={[...tableState.availableItems.values()]}
      availableItemsTableHeader={getAvailableItemsTableHeader()}
      availableItemsTableHeaderButton={getAvailableItemsTableHeaderButton()}
      availableItemsTableSortingState={[{
        desc: tableState.tableOrderDesc.availableItemsDesc,
        id: tableColumnIds[availableItemsPrimaryColumnId],
      }]}
      // ToDo: Correct prop in PersonnelViewAccess
      availableRowUniqueKey={availableRowUniqueKey}
      headerSortHandlers={[
        {
          columnId: tableColumnIds[availableItemsPrimaryColumnId],
          sortHandler: () => updateTableState((draft) => {
            draft.tableOrderDesc = {
              ...draft.tableOrderDesc,
              availableItemsDesc: !draft.tableOrderDesc.availableItemsDesc,
            };
          }),
        },
      ]}
      // ToDo: pass via props
      isLoading={false}
      previewHeaderSortHandlers={[
        {
          columnId: tableColumnIds[previewItemsPrimaryColumnId],
          sortHandler: () => updateTableState((draft) => {
            draft.tableOrderDesc = {
              ...draft.tableOrderDesc,
              previewItemsDesc: !draft.tableOrderDesc.previewItemsDesc,
            };
          }),
        },
      ]}
      previewRowsAreDraggable={previewRowsAreDraggable}
      previewRowUniqueKey={previewRowUniqueKey}
      previewTableColumnFilters={tableState.previewItemsColumnFilters}
      previewTableColumns={tableState.previewColumns}
      // ToDo: Lift out to props
      previewTableColumnVisibility={{
        viewId: false,
      }}
      previewTableData={[...tableState.previewItems.values()]}
      previewTableHeader={getPreviewTableHeader()}
      previewTableSortingState={[
        {
          desc: tableState.tableOrderDesc.previewItemsDesc,
          id: tableColumnIds[previewItemsPrimaryColumnId],
        },
      ]}
      showColorPicker={showColorPicker}
      showSideControls={showSideControls}
    />
  );
};

export default AvailablePreviewList;
