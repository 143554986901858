/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */

import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { produce } from 'immer';
import _ from 'lodash';
import React from 'react';

import { useGetAssignmentTypesQuery } from '@/API/assignments.api';
import { useGetPersonnelTypesQuery } from '@/API/personnel.api';
import {
  useCreateFilterMutation,
  useCreateThemeMutation,
  useCreateViewMutation,
  useUpdateFilterMutation,
  useUpdateThemeMutation,
  useUpdateViewMutation,
} from '@/API/views.api';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog';
import ViewEditorBasicProperties from '@/components/view-editor/ViewEditorBasicProperties';
import ViewEditorPersonnel from '@/components/view-editor/ViewEditorPersonnel';
import ViewEditorViewerConfiguration from '@/components/view-editor/ViewEditorViewerConfiguration';
import {
  DEFAULT_CREATE_SUCCESS_MESSAGE,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_UPDATE_SUCCESS_MESSAGE,
  ToastTypes,
} from '@/constants/defaults';
import useDepartments from '@/hooks/useDepartments';
import { ApiError } from '@/types/api.types';
import { DropdownAction } from '@/types/ui.types';
import View, {
  ViewEditorDraftSortByAssignmentOptions,
  ViewEditorDraftSortByPersonnelOptions,
  ViewLayoutDataType,
  ViewLayoutType,
} from '@/types/view.types';
import viewNameValidationSchema from '@/utils/validationSchemas/viewName.validation.schema';

import ViewEditorAssignmentsTallies from '../view-editor/ViewEditorAssignmentsTallies';

export enum ViewEditorDrawerMode {
  EDIT,
  CREATE,
}

enum ViewEditorDrawerTabIndices {
  VIEW_PROPERTIES,
  PERSONNEL,
  ASSIGNMENTS_TALLIES,
  VIEWER_CONFIGURATION,
}

export enum ViewEditorColumnFilterFields {
  AUTO_ADD_ASSIGNMENTS = 'autoAddAssignments',
  AUTO_ADD_PERSONNEL = 'autoAddPersonnel',
  ASSIGN_STRUCTURE_ID = 'assignStructureId',
  ASSIGN_TYPE_ID = 'assignTypeId',
  DISPLAY_NAME = 'displayName',
  INACTIVE_ASSIGNMENTS = 'expired',
}

interface ViewEditorDrawerProps {
  isLoading: boolean;
  isOpen: boolean;
  mode: ViewEditorDrawerMode;
  onClose: () => void;
  onSave: (viewId: number) => void;
  readonly view: View | undefined;
}

const defaultView: View = {
  accessibleBy: [],
  filter: {
    filter_id: -1,
    on_assignments: [],
    on_departments: [],
    on_personnel: [],
    on_tallies: [],
    on_templates: [],
    sort_assignments_by: ViewEditorDraftSortByAssignmentOptions.CUSTOM,
    sort_personnel_by: ViewEditorDraftSortByPersonnelOptions.CUSTOM,
  },
  filterId: -1,
  name: '',
  personnel: [],
  publicViewId: null,
  templates: [],
  theme: {
    data: {
      dataType: ViewLayoutDataType.REQUEST,
      layout: ViewLayoutType.STANDARD,
    },
    name: '',
    theme_id: -1,
  },
  themeId: -1,
  updatedBy: '',
  updatedTimestamp: '',
  viewId: -1,
};

interface UIState {
  navBlockedByNameRequired: boolean;
  selectedDepartments: number[];
  showConfirmRemoveDepartment: boolean;
  showConfirmSaveChanges: boolean;
  tabIndex: ViewEditorDrawerTabIndices;
}

const initialUIState: UIState = {
  navBlockedByNameRequired: false,
  selectedDepartments: [],
  showConfirmRemoveDepartment: false,
  showConfirmSaveChanges: false,
  tabIndex: ViewEditorDrawerTabIndices.VIEW_PROPERTIES,
};

const ViewEditorDrawer = (props: ViewEditorDrawerProps): React.JSX.Element => {
  const { isLoading, isOpen, mode, onClose, onSave, view } = props;

  const viewData = mode === ViewEditorDrawerMode.EDIT ? view : defaultView;

  const {
    departments,
    templatesForSelectedDepartments,
    selectedDepartmentIds,
    selectedTemplateIds,
    setSelectedDepartmentIds,
    setSelectedTemplateIds,
  } = useDepartments();

  const { data: assignmentTypes } = useGetAssignmentTypesQuery();
  const { data: personnelTypes } = useGetPersonnelTypesQuery();

  const [updateFilter, updateFilterResponse] = useUpdateFilterMutation();
  const [updateTheme, updateThemeResponse] = useUpdateThemeMutation();
  const [updateView, updateViewResponse] = useUpdateViewMutation();

  const [createFilter, createFilterResponse] = useCreateFilterMutation();
  const [createTheme, createThemeResponse] = useCreateThemeMutation();
  const [createView, createViewResponse] = useCreateViewMutation();

  const [originalView, setOriginalView] = React.useState(viewData);
  const [dirtyView, setDirtyView] = React.useState(viewData);
  const [isViewDirty, setIsViewDirty] = React.useState(false);

  const [uiState, setUiState] = React.useState<UIState>(initialUIState);

  const {
    navBlockedByNameRequired,
    selectedDepartments,
    showConfirmRemoveDepartment,
    showConfirmSaveChanges,
    tabIndex,
  } = uiState;

  const toast = useToast();

  React.useEffect(() => {
    setDirtyView(viewData);
    setOriginalView(viewData);

    if (viewData) {
      setSelectedDepartmentIds(viewData.filter.on_departments);
      setSelectedTemplateIds(viewData.filter.on_templates);
    }
  }, [viewData]);

  React.useMemo(() => {
    // Bail if no view
    if (!dirtyView) return;
    const viewDepartmentIds = produce(dirtyView, (draft) => {
      // eslint-disable-next-line camelcase
      draft.filter.on_departments = selectedDepartmentIds;
    });

    setDirtyView(viewDepartmentIds);
  }, [selectedDepartmentIds]);

  React.useMemo(() => {
    // Bail if no view
    if (!dirtyView) return;

    const viewTemplateIds = produce(dirtyView, (draft) => {
      // eslint-disable-next-line camelcase
      draft.filter.on_templates = selectedTemplateIds;
    });

    setDirtyView(viewTemplateIds);
  }, [selectedTemplateIds]);

  React.useMemo(() => {
    _.isEqual(originalView, dirtyView) ? setIsViewDirty(false) : setIsViewDirty(true);
    (async () => {
      try {
        await viewNameValidationSchema.validate({ viewName: dirtyView?.name });

        setUiState((prevState) => {
          return {
            ...prevState,
            navBlockedByNameRequired: false,
          };
        });
      } catch (error) {
        // If the view name is invalid and somehow the user is able to click on
        // a tab before it is disabled, force the user back to the view properties page
        // to address the invalid view name
        setUiState((prevState) => {
          return {
            ...prevState,
            navBlockedByNameRequired: true,
            tabIndex: ViewEditorDrawerTabIndices.VIEW_PROPERTIES,
          };
        });
      }
    })();
  }, [dirtyView]);

  const handleClose = (bypassConfirm = false) => {
    if (isViewDirty && !bypassConfirm) {
      setUiState((prevState) => {
        return {
          ...prevState,
          showConfirmSaveChanges: true,
        };
      });
    } else {
      onClose();
    }
  };

  const handleCloseWithoutSaving = () => {
    setUiState((prevState) => {
      return {
        ...prevState,
        showConfirmSaveChanges: false,
      };
    });
    onClose();
  };

  const addSuccessToast = (message: string) => {
    toast({
      duration: DEFAULT_TOAST_DURATION,
      isClosable: true,
      position: 'top',
      status: ToastTypes.SUCCESS,
      title: message,
    });
  };

  const addErrorToast = (message?: string) => {
    toast({
      duration: DEFAULT_TOAST_DURATION,
      isClosable: true,
      position: 'top',
      status: ToastTypes.ERROR,
      title: message || DEFAULT_ERROR_MESSAGE,
    });
  };

  // Save should be disabled in Creation mode unless you are in the final tab
  const notLastCreationStep =
    mode === ViewEditorDrawerMode.CREATE && tabIndex !== ViewEditorDrawerTabIndices.VIEWER_CONFIGURATION;

  const handleSave = async () => {
    if (!isViewDirty) handleClose(true);

    if (mode === ViewEditorDrawerMode.CREATE && !navBlockedByNameRequired) {
      // Create new view
      try {
        if (!dirtyView?.filter || !dirtyView?.theme) return;
        const filterResponse = await createFilter({
          filterData: dirtyView.filter,
          filterName: `${dirtyView.name} Filter`,
        }).unwrap();

        const themeResponse = await createTheme({
          themeData: dirtyView.theme.data,
          themeName: `${dirtyView.name} Theme`,
        }).unwrap();

        if (filterResponse.filter_id && themeResponse.theme_id) {
          const viewResponse = await createView({
            ...dirtyView,
            filterId: filterResponse.filter_id,
            themeId: themeResponse.theme_id,
          }).unwrap();

          const createViewHasError = !filterResponse.filter_id || !themeResponse.theme_id || !viewResponse.view_id;

          if (createViewHasError) {
            addErrorToast();
          } else {
            addSuccessToast(DEFAULT_CREATE_SUCCESS_MESSAGE);
          }
          if (viewResponse.view_id) {
            onSave(viewResponse.view_id);
          }
        }
      } catch (error) {
        // Cast unknown error type to ApiError
        const apiError = error as ApiError;
        const message = apiError?.data?.Message ?? DEFAULT_ERROR_MESSAGE;
        addErrorToast(message);
      }
    } else {
      // Edit existing view
      // If no view, bail
      if (!dirtyView) return;

      try {
        await updateFilter(dirtyView.filter).unwrap();

        const { data, name, theme_id } = dirtyView.theme;

        await updateTheme({
          data,
          name,
          // eslint-disable-next-line camelcase
          theme_id,
        }).unwrap();

        await updateView({
          accessibleBy: dirtyView.accessibleBy,
          filterId: dirtyView.filterId,
          name: dirtyView.name,
          themeId: dirtyView.themeId,
          viewId: dirtyView.viewId,
        }).unwrap();

        addSuccessToast(DEFAULT_UPDATE_SUCCESS_MESSAGE);

        if (dirtyView.viewId) {
          onSave(dirtyView.viewId);
        }
      } catch (error) {
        // Cast unknown error type to ApiError
        const apiError = error as ApiError;
        const message = apiError?.data?.Message ?? DEFAULT_ERROR_MESSAGE;
        addErrorToast(message);
      }
    }
  };

  if (!dirtyView) return <></>;

  const handleDepartmentChange = (departments: number[], action?: DropdownAction) => {
    // If the user is removing items (deselecting) or clearing (removing all items from the dropdown
    // show the confirmation dialog and save locally the new selected items
    // Otherwise, the user is selecting departments and we just continue with the selection
    if (action === DropdownAction.DESELECT_OPTION || action === DropdownAction.CLEAR) {
      setUiState((prevState) => {
        return {
          ...prevState,
          selectedDepartments: departments,
          showConfirmRemoveDepartment: true,
        };
      });
    } else {
      setSelectedDepartmentIds(departments);
    }
  };

  // Close the modal and remove selected departments
  const handleCancelDepartmentRemoval = () => {
    setUiState((prevState) => {
      return {
        ...prevState,
        selectedDepartments: [],
        showConfirmRemoveDepartment: false,
      };
    });
  };

  // Set selected departments based on the list of items selected on the dropdown
  const handleConfirmDepartmentRemoval = () => {
    setSelectedDepartmentIds(selectedDepartments);
    handleCancelDepartmentRemoval();
  };

  const closeConfirmSaveChangesDialog = () => {
    setUiState((prevState) => {
      return {
        ...prevState,
        showConfirmSaveChanges: false,
      };
    });
  };

  // Disable save button if form has errors or the user has not navigated to the last step
  const isSaveDisabled = navBlockedByNameRequired || notLastCreationStep;

  const getSaveConfirmationDialog = (): JSX.Element => {
    if (isSaveDisabled) {
      return (
        <ConfirmationDialog
          isOpen={showConfirmSaveChanges}
          title="Confirm Close"
          description="Please make sure the View name is filled in correctly before saving the View."
          closeButtonLabel="Close"
          confirmButtonLabel="Continue"
          handleClose={handleCloseWithoutSaving}
          handleConfirm={closeConfirmSaveChangesDialog}
        />
      );
    }

    return (
      <ConfirmationDialog
        isOpen={showConfirmSaveChanges}
        title="Confirm Close"
        description="Are you sure you want to close without saving?"
        closeButtonLabel="Close"
        confirmButtonLabel="Save"
        handleClose={handleCloseWithoutSaving}
        handleConfirm={handleSave}
      />
    );
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleClose}
      placement={'right'}
      preserveScrollBarGap={false}
      blockScrollOnMount={true}
      returnFocusOnClose={false}
    >
      {getSaveConfirmationDialog()}

      <ConfirmationDialog
        isOpen={showConfirmRemoveDepartment}
        title="Warning"
        description="Changing your selected departments may affect your Available Personnel and Assignments. Are you sure you want to make this change?"
        closeButtonLabel="Cancel"
        confirmButtonLabel="Confirm"
        handleClose={handleCancelDepartmentRemoval}
        handleConfirm={handleConfirmDepartmentRemoval}
      />

      <DrawerOverlay />
      <DrawerContent w={'1300px'} maxW={'1300px'}>
        <DrawerCloseButton />
        {mode === ViewEditorDrawerMode.EDIT ? (
          <DrawerHeader>Editing View - {dirtyView.name}</DrawerHeader>
        ) : (
          <DrawerHeader>Creating New View</DrawerHeader>
        )}
        <DrawerBody>
          {isLoading ? (
            <Box textAlign={'center'}>
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
            </Box>
          ) : (
            <Tabs
              isLazy
              onChange={(index) =>
                setUiState((prevState) => {
                  return {
                    ...prevState,
                    tabIndex: index,
                  };
                })
              }
            >
              <HStack gap={10}>
                <TabList>
                  <Tab>View Properties</Tab>
                  <Tab isDisabled={navBlockedByNameRequired}>Personnel</Tab>
                  <Tab isDisabled={navBlockedByNameRequired}>Assignments & Tallies</Tab>
                  <Tab isDisabled={navBlockedByNameRequired}>Viewer Configuration</Tab>
                </TabList>
                <ButtonGroup>
                  <Button colorScheme={'blue'} onClick={handleSave} isDisabled={isSaveDisabled}>
                    Save
                  </Button>
                  <Button colorScheme={'blue'} variant={'ghost'} onClick={() => handleClose()}>
                    Exit
                  </Button>
                </ButtonGroup>
              </HStack>
              <TabPanels>
                <TabPanel>
                  <ViewEditorBasicProperties
                    departmentOptions={departments}
                    handleDepartmentOptionsChange={handleDepartmentChange}
                    handleTemplateOptionsChange={setSelectedTemplateIds}
                    selectedDepartmentIds={selectedDepartmentIds}
                    selectedTemplateIds={selectedTemplateIds}
                    templateOptions={templatesForSelectedDepartments}
                    updateView={setDirtyView}
                    view={dirtyView}
                  />
                </TabPanel>
                <TabPanel>
                  <ViewEditorPersonnel
                    view={dirtyView}
                    updateView={setDirtyView}
                    personnelTypes={personnelTypes ?? []}
                  />
                </TabPanel>
                <TabPanel>
                  <ViewEditorAssignmentsTallies
                    assignmentTypes={assignmentTypes ?? []}
                    view={dirtyView}
                    updateView={setDirtyView}
                    templates={templatesForSelectedDepartments}
                  />
                </TabPanel>
                <TabPanel>
                  <ViewEditorViewerConfiguration view={dirtyView} updateView={setDirtyView} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </DrawerBody>
      </DrawerContent>
      <DrawerFooter></DrawerFooter>
    </Drawer>
  );
};

export default ViewEditorDrawer;
