/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormControl, FormLabel, HStack, Switch, Tab, TabIndicator, TabList, Tabs, VStack } from '@chakra-ui/react';
import { ColumnFiltersState, Table } from '@tanstack/react-table';
import { produce } from 'immer';
import _ from 'lodash';
import React from 'react';
import Select from 'react-select';

import { useGetAssignmentsQuery } from '@/API/assignments.api';
import { useGetTalliesQuery } from '@/API/tallies.api';
import FilterSelect, { FilterSelectValue } from '@/components/filter-select/FilterSelect';
import ViewEditorAssignments from '@/components/view-editor/ViewEditorAssignments';
import ViewEditorTallies from '@/components/view-editor/ViewEditorTallies';
import { ViewEditorColumnFilterFields } from '@/components/view-editor-drawer/ViewEditorDrawer';
import UIConfig from '@/config/ui.config';
import { DEFAULT_Z_INDEX } from '@/constants/defaults';
import { Assignment, AssignmentType } from '@/types/assignment.types';
import Tally from '@/types/tally.types';
import { TemplateSlim } from '@/types/template.types';
import { AssignmentSortOrderDropDownOption, AssignmentSortOrderDropDownOptions } from '@/types/ui.types';
import View from '@/types/view.types';

interface ViewEditorAssignmentsTalliesProps {
  assignmentTypes: AssignmentType[];
  templates: TemplateSlim[];
  updateView: (view: View) => void;
  view: View;
}

type AssignmentToggleFiltersProps = {
  autoAddAssignments?: boolean;
  hideInactiveAssignments?: boolean;
};

export enum ViewEditorAssignmentsTalliesTabs {
  ASSIGNMENTS,
  TALLIES,
}

const selectedTabStyle = {
  color: 'white',
};

const ViewEditorAssignmentsTallies = (props: ViewEditorAssignmentsTalliesProps): React.JSX.Element => {
  const { assignmentTypes, templates, updateView, view } = props;

  // Contains the current view data
  const { filter } = view;

  const assignmentToggleFiltersInit: AssignmentToggleFiltersProps = {
    autoAddAssignments: filter?.auto_add_assignments,
    hideInactiveAssignments: filter?.hide_inactive_assignments,
  };

  const [selectedTabIndex, setSelectedTabIndex] = React.useState<ViewEditorAssignmentsTalliesTabs>(
    ViewEditorAssignmentsTalliesTabs.ASSIGNMENTS,
  );
  // Fetch assignments for the selected departments and templates
  const { data: assignmentsData, isLoading } = useGetAssignmentsQuery(
    {
      departmentList: view.filter.on_departments,
      templateList: view.filter.on_templates,
    },
    { skip: !view.filter.on_departments.length && !view.filter.on_templates.length },
  );
  const {
    data: talliesData,
    isLoading: isLoadingTallies,
    isFetching: isFetchingTallies,
  } = useGetTalliesQuery(view?.filter?.on_templates ?? [], {
    skip: !view.filter.on_templates.length || selectedTabIndex !== ViewEditorAssignmentsTalliesTabs.TALLIES,
  });

  // UI State

  // Assignments
  const [assignmentToggleFilters, setAssignmentToggleFilters] =
    React.useState<AssignmentToggleFiltersProps>(assignmentToggleFiltersInit);

  const [availableItemsColumnFiltersAssignments, setAvailableItemsColumnFiltersAssignments] =
    React.useState<ColumnFiltersState>([]);

  const [previewItemsColumnFiltersAssignments, setPreviewItemsColumnFiltersAssignments] =
    React.useState<ColumnFiltersState>([]);

  const [unfilteredAssignmentStructureIds, setUnfilteredAssignmentStructureIds] = React.useState<number[]>([]);
  const [unfilteredPreviewAssignmentStructureIds, setUnfilteredPreviewAssignmentStructureIds] = React.useState<
    number[]
  >([]);

  // eslint-disable-next-line no-magic-numbers
  const [selectedAssignmentTypes, setSelectedAssignmentTypes] = React.useState<number[]>([]);
  const [assignmentSortValue, setAssignmentSortValue] = React.useState<AssignmentSortOrderDropDownOption>(
    AssignmentSortOrderDropDownOptions.find(({ value }) => value === filter.sort_assignments_by) ??
      // eslint-disable-next-line no-magic-numbers
      AssignmentSortOrderDropDownOptions[0],
  );

  const tableInstanceRefAssignments = React.useRef<Table<Assignment>>();
  const previewTableInstanceRefAssignments = React.useRef<Table<Assignment>>();

  // Tallies
  const [availableItemsColumnFiltersTallies, setAvailableItemsColumnFiltersTallies] =
    React.useState<ColumnFiltersState>([]);

  const [previewItemsColumnFiltersTallies, setPreviewItemsColumnFiltersTallies] = React.useState<ColumnFiltersState>(
    [],
  );

  const [unfilteredTallyIds, setUnfilteredTallyIds] = React.useState<number[]>([]);
  const [unfilteredPreviewTallyIds, setUnfilteredPreviewTallyIds] = React.useState<number[]>([]);

  const tableInstanceRefTallies = React.useRef<Table<Tally>>();
  const previewTableInstanceRefTallies = React.useRef<Table<Tally>>();

  // When the selected assignment types change, update the view filter
  React.useEffect(() => {
    updateView(
      produce(view, (draft) => {
        // eslint-disable-next-line camelcase
        draft.filter.sort_assignments_by = assignmentSortValue.value;
      }),
    );
  }, [assignmentSortValue]);

  // When the auto add assignment toggle changes, update the view filter
  React.useMemo(() => {
    updateView(
      produce(view, (draft) => {
        // eslint-disable-next-line camelcase
        draft.filter.hide_inactive_assignments = assignmentToggleFilters.hideInactiveAssignments;
        // eslint-disable-next-line camelcase
        draft.filter.auto_add_assignments = assignmentToggleFilters.autoAddAssignments;
      }),
    );
  }, [assignmentToggleFilters]);

  /*
   * React-Table v8 'equals' filter function now operates off Object.is() / === equality;
   * ergo we must remove the filter when the toggle is turned off to show all assignments
   * */
  const handleInactiveAssignmentsToggle = () => {
    // If toggle was just turned on, add the filter
    if (!assignmentToggleFilters.hideInactiveAssignments) {
      setAvailableItemsColumnFiltersAssignments((prev) => {
        const currentFilters = prev.filter(({ id }) => id !== ViewEditorColumnFilterFields.INACTIVE_ASSIGNMENTS);

        return produce(currentFilters, (draft) => {
          draft.push({ id: ViewEditorColumnFilterFields.INACTIVE_ASSIGNMENTS, value: false });
        });
      });
    }
    // If toggle was just turned off, remove the filter
    else {
      setAvailableItemsColumnFiltersAssignments((prev) => {
        return prev.filter(({ id }) => id !== ViewEditorColumnFilterFields.INACTIVE_ASSIGNMENTS);
      });
    }

    setAssignmentToggleFilters((prev) => {
      return {
        ...prev,
        hideInactiveAssignments: !assignmentToggleFilters.hideInactiveAssignments,
      };
    });
  };

  const handleAutoAddAssignmentsToggle = () => {
    // If toggle was just turned on, add the filter
    if (!assignmentToggleFilters.autoAddAssignments) {
      setAvailableItemsColumnFiltersAssignments((prev) => {
        const currentFilters = prev.filter(({ id }) => id !== ViewEditorColumnFilterFields.AUTO_ADD_ASSIGNMENTS);

        return produce(currentFilters, (draft) => {
          draft.push({ id: ViewEditorColumnFilterFields.AUTO_ADD_ASSIGNMENTS, value: true });
        });
      });
    }
    // If toggle was just turned off, remove the filter
    else {
      setAvailableItemsColumnFiltersAssignments((prev) => {
        return prev.filter(({ id }) => id !== ViewEditorColumnFilterFields.AUTO_ADD_ASSIGNMENTS);
      });
    }

    setAssignmentToggleFilters((prev) => {
      return {
        ...prev,
        autoAddAssignments: !assignmentToggleFilters.autoAddAssignments,
      };
    });
  };

  const handleSelectAssignmentType = (values: unknown) => {
    setSelectedAssignmentTypes((values as FilterSelectValue[]).map((assignmentType) => assignmentType.value));
  };

  const handleSetAssignmentTypeFilter = () => {
    setAvailableItemsColumnFiltersAssignments((prev) => {
      const currentFilters = prev.filter(({ id }) => id !== ViewEditorColumnFilterFields.ASSIGN_TYPE_ID);

      return produce(currentFilters, (draft) => {
        draft.push({ id: ViewEditorColumnFilterFields.ASSIGN_TYPE_ID, value: selectedAssignmentTypes });
      });
    });
  };

  const debouncedFilterAssignments = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setAvailableItemsColumnFiltersAssignments((prev) => {
      const currentFilters = prev.filter(({ id }) => id !== ViewEditorColumnFilterFields.DISPLAY_NAME);

      return produce(currentFilters, (draft) => {
        draft.push({ id: ViewEditorColumnFilterFields.DISPLAY_NAME, value: e.target.value });
      });
    });
  }, UIConfig.DEFAULT_DEBOUNCE_TIME_MS);

  const debouncedFilterPreviewAssignments = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setPreviewItemsColumnFiltersAssignments([{ id: ViewEditorColumnFilterFields.DISPLAY_NAME, value: e.target.value }]);
  }, UIConfig.DEFAULT_DEBOUNCE_TIME_MS);

  const debouncedFilterTallies = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setAvailableItemsColumnFiltersTallies((prev) => {
      const currentFilters = prev.filter(({ id }) => id !== ViewEditorColumnFilterFields.DISPLAY_NAME);

      return produce(currentFilters, (draft) => {
        draft.push({ id: ViewEditorColumnFilterFields.DISPLAY_NAME, value: e.target.value });
      });
    });
  }, UIConfig.DEFAULT_DEBOUNCE_TIME_MS);

  const debouncedFilterPreviewTallies = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setPreviewItemsColumnFiltersTallies([{ id: ViewEditorColumnFilterFields.DISPLAY_NAME, value: e.target.value }]);
  }, UIConfig.DEFAULT_DEBOUNCE_TIME_MS);

  const getTopBar = (): React.JSX.Element => {
    return (
      <VStack zIndex={DEFAULT_Z_INDEX} align={'left'} justifyContent={'space-between'} gap={5}>
        <Tabs
          variant={'unstyled'}
          position={'relative'}
          index={selectedTabIndex}
          onChange={(index) => setSelectedTabIndex(index)}
          isLazy
        >
          <TabList>
            <Tab _selected={selectedTabStyle}>Assignments</Tab>
            <Tab _selected={selectedTabStyle}>Tallies</Tab>
          </TabList>
          <TabIndicator
            bg={'blue.500'}
            borderRadius={'10px'}
            height={'40px'}
            mt={'-40px'}
            zIndex={-1}
            position={'inherit'}
            textColor={'white'}
          />
        </Tabs>

        {selectedTabIndex === ViewEditorAssignmentsTalliesTabs.ASSIGNMENTS && (
          <HStack spacing="24px" alignItems="flex-end">
            <VStack alignItems="flex-start">
              <FormLabel>Filter Available Assignments by Assignment Types</FormLabel>
              <FilterSelect
                hasSelectAll={true}
                inputName={'filterByAssignmentType'}
                isMultiSelect={true}
                onChangeHandler={handleSelectAssignmentType}
                onCloseHandler={handleSetAssignmentTypeFilter}
                options={assignmentTypes}
                optionValueKey={'atypeId'}
                optionLabelKey={'name'}
                placeHolderText={'Assignment Types'}
                selectedValues={selectedAssignmentTypes}
              />
            </VStack>

            <VStack alignItems="flex-start">
              <FormLabel>Sort Selected Assignments by</FormLabel>
              <Select
                styles={{
                  control: (styles) => ({
                    ...styles,
                    maxWidth: '250px',
                    minWidth: '250px',
                  }),
                }}
                options={AssignmentSortOrderDropDownOptions}
                value={assignmentSortValue}
                onChange={(value) => setAssignmentSortValue(value as AssignmentSortOrderDropDownOption)}
              />
            </VStack>

            <VStack alignItems="flex-end" height="100%" justifyContent="flex-end">
              <FormControl display="flex" alignItems="center">
                <HStack w={'240px'} justifyContent={'space-between'}>
                  <FormLabel htmlFor="auto-add-assignments" mb="0">
                    Auto-Add Assignments
                  </FormLabel>
                  <Switch
                    id="auto-add-assignments"
                    isChecked={assignmentToggleFilters.autoAddAssignments}
                    onChange={handleAutoAddAssignmentsToggle}
                  />
                </HStack>
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <HStack w={'240px'} justifyContent={'space-between'}>
                  <FormLabel htmlFor="hide-inactive-assignments-from-available" mb="0">
                    Hide Inactive Assignments
                  </FormLabel>
                  <Switch
                    id="hide-inactive-assignments-from-available"
                    isChecked={assignmentToggleFilters.hideInactiveAssignments}
                    onChange={handleInactiveAssignmentsToggle}
                  />
                </HStack>
              </FormControl>
            </VStack>
          </HStack>
        )}
      </VStack>
    );
  };

  // Assignments
  React.useEffect(() => {
    if (availableItemsColumnFiltersAssignments.length) {
      const unfilteredAssignmentStructureIds: number[] =
        // eslint-disable-next-line react/prop-types
        tableInstanceRefAssignments?.current
          ?.getFilteredRowModel()
          .rows.map((row) => row.getValue('assignStructureId')) ?? [];

      setUnfilteredAssignmentStructureIds(unfilteredAssignmentStructureIds);
    }
  }, [availableItemsColumnFiltersAssignments]);

  React.useEffect(() => {
    if (previewItemsColumnFiltersAssignments.length) {
      const unfilteredAssignmentStructureIds: number[] =
        // eslint-disable-next-line react/prop-types
        previewTableInstanceRefAssignments?.current
          ?.getFilteredRowModel()
          .rows.map((row) => row.getValue('assignStructureId')) ?? [];

      setUnfilteredPreviewAssignmentStructureIds(unfilteredAssignmentStructureIds);
    }
  }, [previewItemsColumnFiltersAssignments]);

  // Tallies
  React.useEffect(() => {
    if (availableItemsColumnFiltersTallies.length) {
      const unfilteredTalliesIds: number[] =
        // eslint-disable-next-line react/prop-types
        tableInstanceRefTallies?.current?.getFilteredRowModel().rows.map((row) => row.getValue('tallyId')) ?? [];

      setUnfilteredTallyIds(unfilteredTalliesIds);
    }
  }, [availableItemsColumnFiltersTallies]);

  React.useEffect(() => {
    if (previewItemsColumnFiltersTallies.length) {
      const unfilteredTalliesIds: number[] =
        // eslint-disable-next-line react/prop-types
        previewTableInstanceRefTallies?.current?.getFilteredRowModel().rows.map((row) => row.getValue('tallyId')) ?? [];

      setUnfilteredPreviewTallyIds(unfilteredTalliesIds);
    }
  }, [previewItemsColumnFiltersTallies]);

  // ToDo: Handle no assignments found
  return (
    <VStack gap={5} align={'top'}>
      {getTopBar()}
      {selectedTabIndex === ViewEditorAssignmentsTalliesTabs.ASSIGNMENTS ? (
        <ViewEditorAssignments
          assignmentsData={assignmentsData ?? []}
          availableItemsColumnFilters={availableItemsColumnFiltersAssignments}
          previewItemsColumnFilters={previewItemsColumnFiltersAssignments}
          assignmentSortValue={assignmentSortValue}
          handleDebounceFilter={debouncedFilterAssignments}
          handleDebounceFilterPreview={debouncedFilterPreviewAssignments}
          isAssignmentDataLoading={isLoading}
          tableInstanceRef={tableInstanceRefAssignments}
          previewTableInstanceRef={previewTableInstanceRefAssignments}
          templates={templates}
          unfilteredAssignmentStructureIds={unfilteredAssignmentStructureIds}
          unfilteredPreviewAssignmentStructureIds={unfilteredPreviewAssignmentStructureIds}
          updateView={updateView}
          view={view}
        />
      ) : (
        <ViewEditorTallies
          availableItemsColumnFilters={availableItemsColumnFiltersTallies}
          previewItemsColumnFilters={previewItemsColumnFiltersTallies}
          handleDebounceFilter={debouncedFilterTallies}
          handleDebounceFilterPreview={debouncedFilterPreviewTallies}
          isTalliesDataLoading={isLoadingTallies || isFetchingTallies}
          tableInstanceRef={tableInstanceRefTallies}
          previewTableInstanceRef={previewTableInstanceRefTallies}
          talliesData={talliesData ?? []}
          unfilteredTallyIds={unfilteredTallyIds}
          unfilteredPreviewTallyIds={unfilteredPreviewTallyIds}
          updateView={updateView}
          view={view}
        />
      )}
    </VStack>
  );
};

export default ViewEditorAssignmentsTallies;
