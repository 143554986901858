import { Box, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

interface TableCellProps {
  text: string;
  onClick: () => void;
  isSelected?: boolean;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
}

const FONT_BOLD = 600;
const FONT_NORMAL = 400;
const FULL_SIZE = '100%';

const TableCell = (props: TableCellProps): React.JSX.Element => {
  const { isSelected = false, icon = null, onClick = () => void {}, text = '' } = props;

  return (
    <Box w={FULL_SIZE} h={FULL_SIZE} onClick={onClick} style={props.style}>
      <HStack justifyContent={'space-between'} h={FULL_SIZE} padding={'10px'}>
        <Text
          fontWeight={`${isSelected ? FONT_BOLD : FONT_NORMAL}`}
          color={isSelected ? 'green' : 'inherit'}
        >
          {text}
        </Text>
        <HStack>
          {icon}
          {isSelected && <FaCheckCircle color={'green'} />}
        </HStack>
      </HStack>
    </Box>
  );
};

export default TableCell;
