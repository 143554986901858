/* eslint-disable no-magic-numbers */
const LBS_USER_IDS = [20_001, 4];

export const TOKEN_TYPE_BEARER = 'bearer';

export enum Endpoints {
  Assignments = '/assignments',
  AssignmentsType = '/assignments/type',
  Departments = '/vm/departments',
  Personnel = '/personnel',
  PersonnelSearch = '/personnel/search',
  PersonnelType = '/personnel/type',
  Tally = '/tally',
  Token = '/token',
  Views = '/views',
  ViewsFilter = '/views/filter',
  ViewsPublic = '/views/public',
  ViewsTheme = '/views/theme',
  VMGiveSelfAccess = 'vm/view/access/giveself',
  VMPersonnel = '/vm/personnel',
  VMPersonnelAccess = '/vm/personnel/access',
  VMViewAccess = 'vm/view/access',
  VMViews = '/vm/views',
  VMViewsList = '/vm/views/list',
}

export enum RTKQueryTags {
  PublicViews = 'PublicViews',
  View = 'View',
  ViewsList = 'ViewsList',
}

export enum HeaderKeys {
  Accept = 'Accept',
  Authorization = 'Authorization',
  ContentType = 'Content-Type',
}

export enum ContentTypes {
  ApplicationJSON = 'application/json',
  FormEncoded = 'application/x-www-form-urlencoded;charset=utf-8',
}

const APIConstants = {
  ContentTypes,
  Endpoints,
  HeaderKeys,
  LBS_USER_IDS,
  RTKQueryTags,
};

export default APIConstants;
