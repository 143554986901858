import { ViewLayoutListColumns } from '@/types/view.types';

export const ViewEditorUIOptions = {
  publiclyViewableListColumns: [
    ViewLayoutListColumns.ASSIGNMENT,
    ViewLayoutListColumns.ASSIGNMENT_UNITS,
    ViewLayoutListColumns.DATE,
    ViewLayoutListColumns.LOCATIONS,
    ViewLayoutListColumns.PERSONNEL,
    ViewLayoutListColumns.START_TIME,
    ViewLayoutListColumns.STOP_TIME,
    ViewLayoutListColumns.TEMPLATE,
  ],
};

export default ViewEditorUIOptions;
