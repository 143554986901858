import { Box, Button, Flex, FormControl, FormLabel, HStack, Spacer, Stack, Switch, Text } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useImmer } from 'use-immer';

import ControlledSearch from '@/components/controlled-search/ControlledSearch';
import DepartmentsDropdown from '@/components/departments-dropdown/DepartmentsDropdown';
import PersonnelMembershipDrawer from '@/components/personnel-membership-drawer/PersonnelMembershipDrawer';
import UIConfig from '@/config/ui.config';
import { ROUTES } from '@/constants/config';
import { DEFAULT_HEADER_Z_INDEX } from '@/constants/defaults';
import useDepartments from '@/hooks/useDepartments';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';
import PersonnelListView from '@/views/personnel-list/PersonnelList.view';

interface PersonnelPageState {
  departmentDropdownSelectedIds: number[];
  hideInactive: boolean;
  isDepartmentDropdownOpen: boolean;
  isPersonnelMembershipDrawerOpen: boolean;
  personnelSearchFilterValue: string;
  personnelSearchValue: string;
  selectedPersonnelId: number | undefined;
}

const DEFAULT_STATE: PersonnelPageState = {
  departmentDropdownSelectedIds: [],
  hideInactive: true,
  isDepartmentDropdownOpen: false,
  isPersonnelMembershipDrawerOpen: false,
  personnelSearchFilterValue: '',
  personnelSearchValue: '',
  selectedPersonnelId: undefined,
};

const PersonnelPage = (): React.JSX.Element => {
  const { departments, isFetching, isLoading, selectedDepartmentIds, setSelectedDepartmentIds } = useDepartments();

  const [state, updateState] = useImmer<PersonnelPageState>(DEFAULT_STATE);

  React.useMemo(() => {
    if (!state.isDepartmentDropdownOpen) {
      updateState((draft) => {
        draft.departmentDropdownSelectedIds = selectedDepartmentIds;
      });
    }
  }, [state.isDepartmentDropdownOpen, selectedDepartmentIds]);

  const debouncedPersonnelSearch = React.useCallback(
    // eslint-disable-next-line no-magic-numbers
    _.debounce(
      (value: string) =>
        updateState((draft) => {
          draft.personnelSearchFilterValue = value;
        }),
      UIConfig.DEFAULT_DEBOUNCE_TIME_MS,
    ),
    [],
  );

  const handlePersonnelSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedPersonnelSearch(e.target.value);
    updateState((draft) => {
      draft.personnelSearchValue = e.target.value;
    });
  };

  const getPersonnelMembershipDrawer = () => {
    return (
      <PersonnelMembershipDrawer
        isOpen={state.isPersonnelMembershipDrawerOpen}
        onClose={() =>
          updateState((draft) => {
            draft.isPersonnelMembershipDrawerOpen = false;
          })
        }
        personnelId={state.selectedPersonnelId}
      />
    );
  };

  const handleHideInactivePersonnel = () => {
    updateState((draft) => {
      draft.hideInactive = !draft.hideInactive;
    });
  };

  return (
    <>
      <Box pos={'absolute'} zIndex={100}>
        {getPersonnelMembershipDrawer()}
      </Box>
      <Box margin={'0 auto'} data-testid={ELEMENT_DATA_TEST_IDS.PERSONNEL_PAGE}>
        <Stack>
          <Flex mb={2}>
            <Text fontSize="3xl" as="b">
              Personnel
            </Text>
          </Flex>
          <Flex mt={'auto'} mb={1} zIndex={DEFAULT_HEADER_Z_INDEX}>
            <Flex alignItems="flex-end" gap={4}>
              <Flex>
                <DepartmentsDropdown
                  departmentChangeHandler={setSelectedDepartmentIds}
                  departmentList={departments}
                  departmentListCloseHandler={() =>
                    updateState((draft) => {
                      draft.isDepartmentDropdownOpen = false;
                    })
                  }
                  departmentListOpenHandler={() =>
                    updateState((draft) => {
                      draft.isDepartmentDropdownOpen = true;
                    })
                  }
                  isLoading={isLoading || isFetching}
                  selectedIds={selectedDepartmentIds}
                />
              </Flex>
              <Spacer />
              <Flex mb={2}>
                <FormControl display="flex" alignItems="center">
                  <HStack justifyContent={'space-between'}>
                    <Switch
                      id="hide-inactive-personnel"
                      isChecked={state.hideInactive}
                      onChange={handleHideInactivePersonnel}
                    />
                    <FormLabel htmlFor="hide-inactive-personnel" mb="0">
                      Hide Inactive Personnel
                    </FormLabel>
                  </HStack>
                </FormControl>
              </Flex>
            </Flex>
            <Spacer />
            <Stack direction={'row'} marginTop={'auto'} alignItems={'center'}>
              <ControlledSearch
                onChange={handlePersonnelSearch}
                placeholder={'Search Personnel'}
                value={state.personnelSearchValue}
              />
              <Box>
                <Link to={ROUTES.VIEWS}>
                  <Button size={'md'} leftIcon={<FaUser />} colorScheme={'blue'}>
                    Views List
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Flex>
          <PersonnelListView
            hideInactive={state.hideInactive}
            personnelNameFilter={state.personnelSearchFilterValue}
            selectedDepartmentIds={state.departmentDropdownSelectedIds}
            togglePersonnelMembershipDrawer={(personnelId: number) =>
              updateState((draft) => {
                draft.selectedPersonnelId = personnelId;
                draft.isPersonnelMembershipDrawerOpen = true;
              })
            }
          />
        </Stack>
      </Box>
    </>
  );
};

export default PersonnelPage;
