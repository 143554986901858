/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { produce } from 'immer';
import _ from 'lodash';
import React, { BaseSyntheticEvent } from 'react';

import DepartmentsDropdown from '@/components/departments-dropdown/DepartmentsDropdown';
import TemplatesDropdown from '@/components/templates-dropdown/TemplatesDropdown';
import { DepartmentSlim } from '@/types/department.types';
import { TemplateSlim } from '@/types/template.types';
import { DropdownAction } from '@/types/ui.types';
import View, { ViewLayoutDataType } from '@/types/view.types';
import viewNameValidationSchema from '@/utils/validationSchemas/viewName.validation.schema';

import ViewLayout from './ViewLayout';

export interface ViewNameFieldProps {
  field: {
    name: string;
    value: string;
  };
  form: {
    errors: { [field: string]: string };
    touched: { [field: string]: boolean };
  };
}

interface BasicPropertiesProps {
  departmentOptions: DepartmentSlim[];
  handleDepartmentOptionsChange: (selectedDepartmentIds: number[], action?: DropdownAction) => void;
  handleTemplateOptionsChange: (selectedTemplateIds: number[]) => void;
  selectedDepartmentIds: number[];
  selectedTemplateIds: number[];
  templateOptions: TemplateSlim[];
  updateView: (view: View) => void;
  view: View;
}

const selectedTabStyle = {
  color: 'white',
};

const dataTypeTabIndices = Object.values(ViewLayoutDataType);

const ViewEditorBasicProperties = (props: BasicPropertiesProps): React.JSX.Element => {
  const {
    departmentOptions,
    handleDepartmentOptionsChange,
    handleTemplateOptionsChange,
    selectedDepartmentIds,
    selectedTemplateIds,
    templateOptions,
    view,
    updateView,
  } = props;

  const updateViewRef = React.useRef(updateView);
  updateViewRef.current = updateView;

  // eslint-disable-next-line no-magic-numbers
  if (!view || Object.keys(view).length === 0) {
    return <Box>View not found</Box>;
  }

  const handleTabSelectorChange = (index: number) => {
    const dataType = dataTypeTabIndices[index];

    // ToDo: Immer
    updateView({ ...view, theme: { ...view.theme, data: { ...view.theme.data, dataType } } });
  };

  const getDataTypeContainer = (): React.JSX.Element => {
    const selectedIndex = dataTypeTabIndices.indexOf(view.theme.data.dataType ?? ViewLayoutDataType.REQUEST);

    return (
      <VStack justifyContent={'space-between'} align={'left'}>
        <Text>Data Type</Text>
        <Tabs
          variant={'unstyled'}
          position={'relative'}
          index={selectedIndex}
          onChange={handleTabSelectorChange}
          isLazy
        >
          <TabList>
            <Tab _selected={selectedTabStyle}>Request</Tab>
            <Tab _selected={selectedTabStyle}>Schedule</Tab>
            <Tab _selected={selectedTabStyle}>Both</Tab>
          </TabList>
          <TabIndicator
            bg={'blue.500'}
            borderRadius={'10px'}
            height={'40px'}
            mt={'-40px'}
            zIndex={-1}
            position={'inherit'}
            textColor={'white'}
          />
        </Tabs>
      </VStack>
    );
  };

  const onDepartmentOptionsChange = (selectedDepartmentIds: number[]) => {
    handleDepartmentOptionsChange(selectedDepartmentIds);

    const viewDepartmentIds = produce(view, (draft) => {
      // eslint-disable-next-line camelcase
      draft.filter.on_departments = selectedDepartmentIds;
    });

    updateView(viewDepartmentIds);
  };

  const onTemplateOptionsChange = (selectedTemplateIds: number[]) => {
    handleTemplateOptionsChange(selectedTemplateIds);

    const viewTemplateIds = produce(view, (draft) => {
      // eslint-disable-next-line camelcase
      draft.filter.on_templates = selectedTemplateIds;
    });

    updateView(viewTemplateIds);
  };

  return (
    <Box>
      <VStack align={'left'} justifyContent={'space-between'} gap={'5'}>
        <Formik
          initialValues={{ viewName: view.name }}
          onSubmit={() => {
            return;
          }}
          validationSchema={viewNameValidationSchema}
        >
          <Form
            onChange={(e: BaseSyntheticEvent) => {
              const value = e.target.value;
              updateViewRef.current({ ...view, name:value });
            }}
          >
            <Field name="viewName" value={view.name}>
              {({ field, form }: ViewNameFieldProps) => (
                <FormControl
                  isRequired
                  w={'100%'}
                  isInvalid={(form.errors.viewName && form.touched.viewName) as boolean}
                >
                  <FormLabel>View Name</FormLabel>
                  <Input {...field} type={'text'} placeholder={'Enter a name for this view'}></Input>
                  <FormErrorMessage>{form.errors.viewName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Form>
        </Formik>
        <HStack>
          <DepartmentsDropdown
            departmentList={departmentOptions}
            departmentChangeHandler={handleDepartmentOptionsChange}
            selectedIds={selectedDepartmentIds}
          />
          <TemplatesDropdown
            templateList={templateOptions}
            templateChangeHandler={handleTemplateOptionsChange}
            selectedIds={selectedTemplateIds}
          />
        </HStack>
        {getDataTypeContainer()}
        <ViewLayout updateView={updateView} view={view} />
      </VStack>
    </Box>
  );
};

export default ViewEditorBasicProperties;
