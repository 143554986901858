/* eslint-disable camelcase*/
import { useToast } from '@chakra-ui/react';
import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import { DateTime } from 'luxon';
import React, { createContext } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router';

import { useLoginMutation, useRefreshAuthMutation } from '@/API/api.slice';
import { TOKEN_TYPE_BEARER } from '@/constants/api';
import { COOKIE_NAMES, ToastTypes, TOKEN_NAME } from '@/constants/defaults';
import { useAppDispatch } from '@/store/hooks';
import { authSlice } from '@/store/slices/auth.slice';
import { ConfigHelper, ConfigKeys } from '@/utils/env';
import { getQueryParamsFromSearchString } from '@/utils/queryParams';

type TokenRefreshTimerIntervalId = TimeoutId | null;
type TokenRefreshTimerDelayMs = number | null;

enum QueryParamKeys {
  Bearer = 'bearer',
  Token = 'token',
}

const MS_SCALAR = 1_000;
const TOKEN_EXPIRY_TIME_BUFFER_OFFSET = 200;
const DEFAULT_TOKEN_EXPIRY_SEC = 3_399;

const AUTHENTICATION_ERROR_MESSAGE = 'Error logging in.';

const AuthContext = createContext(null);

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const toast = useToast();
  const [login] = useLoginMutation();
  const [refreshAuth] = useRefreshAuthMutation();
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAMES.REFRESH_TOKEN, COOKIE_NAMES.DEV_BEARER]);
  const queryParams = getQueryParamsFromSearchString(location.search);
  const token = React.useRef<string | undefined>(
    queryParams && ((queryParams[QueryParamKeys.Token] as string) ?? undefined),
  );
  const tokenRefreshDelayMs = React.useRef<TokenRefreshTimerDelayMs>(null);
  const tokenRefreshTimeoutId = React.useRef<TokenRefreshTimerIntervalId>(null);
  const localDevBearerToken = React.useRef<string | undefined>(
    queryParams && ((queryParams[QueryParamKeys.Bearer] as string) ?? undefined),
  );
  const refreshToken = React.useRef(cookies[COOKIE_NAMES.REFRESH_TOKEN]);

  // Save URL token on local storage (needed to perform unity authentication when navigating to Viewer app )
  React.useMemo(() => {
    if (token?.current) {
      localStorage.setItem(TOKEN_NAME, token.current);
    }
  }, [token]);

  const clearCurrentTimer = () => {
    if (tokenRefreshTimeoutId.current) {
      clearTimeout(tokenRefreshTimeoutId.current);
    }
  };

  const startRefreshTimer = (callback: () => Promise<unknown>) => {
    if (!tokenRefreshDelayMs.current) return;

    clearCurrentTimer();

    tokenRefreshTimeoutId.current = setTimeout(async () => {
      await callback();
    }, tokenRefreshDelayMs.current);
  };

  const doTokenRefresh = async () => {
    try {
      const response = await refreshAuth({
        clientId: ConfigHelper.getConfig(ConfigKeys.CLIENT_ID) ?? '',
        refreshToken: refreshToken.current,
      }).unwrap();

      removeCookie(COOKIE_NAMES.REFRESH_TOKEN);

      // Set the refresh token browser cookie and hydrate auth store
      setCookie(COOKIE_NAMES.REFRESH_TOKEN, response.refresh_token, {
        expires: DateTime.now().plus({ seconds: response.expires_in }).toJSDate(),
        path: '/',
      });

      refreshToken.current = response.refresh_token;
      tokenRefreshDelayMs.current =
        (response.expires_in - TOKEN_EXPIRY_TIME_BUFFER_OFFSET || DEFAULT_TOKEN_EXPIRY_SEC) * MS_SCALAR;

      startRefreshTimer(doTokenRefresh);
      dispatch(authSlice.actions.setAuth(response));
    } catch (err) {
      dispatch(authSlice.actions.revokeAuth());
      removeCookie(COOKIE_NAMES.REFRESH_TOKEN);
    }
  };

  React.useEffect(() => {
    dispatch(authSlice.actions.setIsLoading(true));

    try {
      // We have an SSO single-use token
      // So use that to hydrate auth store
      if (token.current) {
        removeCookie(COOKIE_NAMES.REFRESH_TOKEN);

        // IIFE to handle async inside useEffect
        (async () => {
          try {
            const response = await login({
              clientId: ConfigHelper.getConfig(ConfigKeys.CLIENT_ID) ?? '',
              password: '',
              username: token.current ?? '',
            }).unwrap();

            // Populate the refresh (LB_TKN) token cookie
            setCookie(COOKIE_NAMES.REFRESH_TOKEN, response.refresh_token, {
              expires: DateTime.now().plus({ seconds: response.expires_in }).toJSDate(),
              path: '/',
            });

            tokenRefreshDelayMs.current =
              (response.expires_in - TOKEN_EXPIRY_TIME_BUFFER_OFFSET || DEFAULT_TOKEN_EXPIRY_SEC) * MS_SCALAR;
            refreshToken.current = response.refresh_token;

            startRefreshTimer(doTokenRefresh);

            dispatch(authSlice.actions.setAuth(response));
          } catch (err) {
            dispatch(authSlice.actions.revokeAuth());
          }
        })();
      }
      // If we do not have an SSO token, and we have a refresh token
      // utilize the refresh token to hydrate our auth store
      else if (refreshToken.current) {
        (async () => {
          await doTokenRefresh();
        })();
      }
      // For local development against LBAPI
      // Add a DEV_BEARER cookie from bearer token sent via query param
      else if (import.meta.env.VITE_NODE_ENV === 'development' && localDevBearerToken.current) {
        setCookie(COOKIE_NAMES.DEV_BEARER, localDevBearerToken.current);

        dispatch(
          authSlice.actions.setAuth({
            access_token: localDevBearerToken.current,
            expires_in: DEFAULT_TOKEN_EXPIRY_SEC,
            refresh_token: refreshToken.current,
            token_type: TOKEN_TYPE_BEARER,
          }),
        );
      }
      // If we already have a local dev bearer token cookie
      // Just use that bad boiiiii
      else if (cookies.DEV_BEARER && import.meta.env.VITE_NODE_ENV === 'development') {
        dispatch(
          authSlice.actions.setAuth({
            access_token: cookies.DEV_BEARER,
            expires_in: DEFAULT_TOKEN_EXPIRY_SEC,
            refresh_token: refreshToken.current,
            token_type: TOKEN_TYPE_BEARER,
          }),
        );
      }
      // If the user does not provide an SSO token,
      // or a refresh token, a dev bearer token, or dev bearer cookie
      // then clear the auth state and BAN THE IMPOSTER!! PERMA-BANNNNN!
      else {
        removeCookie(COOKIE_NAMES.REFRESH_TOKEN);

        if (import.meta.env.VITE_NODE_ENV === 'development') {
          removeCookie(COOKIE_NAMES.DEV_BEARER);
        }

        dispatch(authSlice.actions.revokeAuth());
      }
    } catch (err) {
      toast({
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: AUTHENTICATION_ERROR_MESSAGE,
      });
    }

    // Clean-up refresh timeout
    // to prevent memory leak
    return () => {
      if (tokenRefreshTimeoutId.current) {
        clearCurrentTimer();
      }
    };
  }, []);

  return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
