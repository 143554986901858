/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@chakra-ui/react';
import { CellContext, ColumnFiltersState, createColumnHelper, Row, SortingState, Table } from '@tanstack/react-table';
import { produce } from 'immer';
import React, { useRef } from 'react';

import PaginatedPreviewTable, { CellColors } from '@/components/paginated-preview-table/PaginatedPreviewTable';
import PreviewCell from '@/components/preview-cell/PreviewCell';
import TableCell from '@/components/table-cell/TableCell';
import TableHeader from '@/components/table-header/TableHeader';
import TableHeaderSelectAll from '@/components/table-header-select-all/TableHeaderSelectAll';
import { ALPHANUMERIC_TABLE_SORT } from '@/constants/defaults';
import { Assignment } from '@/types/assignment.types';
import { TemplateSlim } from '@/types/template.types';
import { AssignmentSortOrderDropDownOption, ColoredItem } from '@/types/ui.types';
import View, { ViewColoredItem, ViewEditorDraftSortByAssignmentOptions } from '@/types/view.types';
import { customSortArray } from '@/utils/arrays';
import SortingUtils from '@/utils/sorting';

// Key to sort items on the table
const ASSIGNMENT_SORT_KEY = 'assignStructureId';

// Key to sort items on the view object
const ASSIGNMENT_FILTER_SORT_KEY = 'id';

interface ViewEditorAssignmentsProps {
  assignmentsData: Assignment[];
  availableItemsColumnFilters: ColumnFiltersState;
  assignmentSortValue: AssignmentSortOrderDropDownOption;
  handleDebounceFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDebounceFilterPreview: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isAssignmentDataLoading: boolean;
  tableInstanceRef: React.MutableRefObject<Table<Assignment> | undefined>;
  previewItemsColumnFilters: ColumnFiltersState;
  previewTableInstanceRef: React.MutableRefObject<Table<Assignment> | undefined>;
  templates: TemplateSlim[];
  unfilteredAssignmentStructureIds: number[];
  unfilteredPreviewAssignmentStructureIds: number[];
  updateView: (view: View) => void;
  view: View;
}

enum ViewEditorColumns {
  AUTO_ADD_ASSIGNMENTS = 'autoAddAssignments',
  AUTO_ADD_PERSONNEL = 'autoAddPersonnel',
  ASSIGN_STRUCTURE_ID = 'assignStructureId',
  ASSIGN_TYPE_ID = 'assignTypeId',
  DISPLAY_NAME = 'displayName',
  INACTIVE_ASSIGNMENTS = 'expired',
}

// Sort ascending by default
const DEFAULT_AVAILABLE_TABLE_SORTING: SortingState = [
  {
    desc: false,
    id: ViewEditorColumns.DISPLAY_NAME,
  },
];

const ViewEditorAssignments = (props: ViewEditorAssignmentsProps) => {
  const {
    assignmentsData,
    availableItemsColumnFilters,
    assignmentSortValue,
    handleDebounceFilter,
    handleDebounceFilterPreview,
    isAssignmentDataLoading,
    previewTableInstanceRef,
    previewItemsColumnFilters,
    tableInstanceRef,
    templates,
    unfilteredAssignmentStructureIds,
    unfilteredPreviewAssignmentStructureIds,
    updateView,
    view,
  } = props;
  const { filter } = view;

  // A memoized list of all assignment structure ids which are currently contained in the view filter
  const currentAssignmentStructureIds = React.useMemo(() => {
    return filter.on_assignments.map((assignment) => assignment.id);
  }, [filter]);

  // Contains a list of all available assignments which are currently selected
  const [selectedAssignmentStructureIds, setSelectedAssignmentStructureIds] = React.useState<Set<number>>(
    new Set(currentAssignmentStructureIds as number[]),
  );
  // Contains a list of all assignment rows in the preview table which are currently selected (impacts color highlighting and DnD)
  const [selectedPreviewRowAssignStructureIds, setSelectedPreviewRowAssignStructureIds] = React.useState<Set<number>>(
    new Set(),
  );
  const [selectAllAvailableIsToggled, setSelectAllAvailableIsToggled] = React.useState<boolean>(false);
  const [selectAllPreviewIsToggled, setSelectAllPreviewIsToggled] = React.useState<boolean>(false);

  // Contains a list of all assignments in the preview table. These are the assignments which will be saved to the view
  const [previewData, setPreviewData] = React.useState<Assignment[]>([]);

  const [availableTableSortingState, setAvailableTableSortingState] = React.useState<SortingState>(
    DEFAULT_AVAILABLE_TABLE_SORTING,
  );

  const [previewTableSortingState, setPreviewTableSortingState] = React.useState<SortingState>([]);

  const [orderChanged, setOrderChanged] = React.useState<boolean>(false);

  // A memoized list of all assignment structure ids which are available in the preview table
  // This list includes all assignments which are currently selected, as well as all assignments which are not selected
  const allAssignmentStructureIds = React.useMemo(() => {
    if (assignmentsData?.length) {
      return new Set(assignmentsData.map((assignment) => assignment.assignStructureId));
    }

    return new Set<number>();
  }, [assignmentsData, selectAllAvailableIsToggled]);

  const isDraggable = React.useMemo(
    () =>
      assignmentSortValue.value === ViewEditorDraftSortByAssignmentOptions.TIME_THEN_CUSTOM ||
      assignmentSortValue.value === ViewEditorDraftSortByAssignmentOptions.CUSTOM,
    [assignmentSortValue],
  );

  // Selected assignments IDs
  const selectedIDs = React.useMemo(() => {
    return previewData.map((item) => item.assignStructureId);
  }, [previewData]);

  // Selected assignments
  const currentAssignments = React.useMemo(() => {
    const assignmentItems =
      assignmentsData?.filter((assignment) => selectedAssignmentStructureIds.has(assignment.assignStructureId)) ?? [];

    // Sort current(selected) assignments based on the order they have been saved
    const sortedAssignments = customSortArray(
      assignmentItems,
      [...selectedAssignmentStructureIds],
      ASSIGNMENT_SORT_KEY,
    );

    return sortedAssignments;
  }, [assignmentsData, selectedAssignmentStructureIds]);

  const normalizedAssignmentDataRef = useRef<ViewColoredItem[]>([]);

  normalizedAssignmentDataRef.current = currentAssignments.map((assignment) => {
    // Preserve color selections
    const { color, colorText } = filter.on_assignments.find((a) => a.id === assignment.assignStructureId) ?? {};
    return {
      color: color || null,
      colorText: colorText || null,
      id: assignment.assignStructureId,
    };
  });

  // When the current assignments change, update the preview table data
  React.useMemo(() => {
    setPreviewData(currentAssignments);
  }, [currentAssignments]);

  React.useMemo(() => {
    if (selectAllAvailableIsToggled) {
      // Add all selected assignments
      updateView(
        produce(view, (draft) => {
          // eslint-disable-next-line camelcase
          draft.filter.on_assignments = normalizedAssignmentDataRef.current;
        }),
      );
    } else {
      // Remove all assignments
      updateView(
        produce(view, (draft) => {
          // eslint-disable-next-line camelcase
          draft.filter.on_assignments = [];
        }),
      );
    }
  }, [selectAllAvailableIsToggled]);

  const handleSelectAllAvailableAssignments = React.useCallback(() => {
    if (!assignmentsData?.length) return;

    // Handle selecting of all items
    if (!selectAllAvailableIsToggled) {
      // If there are no filters, select all assignments
      if (!unfilteredAssignmentStructureIds.length) {
        setSelectedAssignmentStructureIds(allAssignmentStructureIds);
      } else {
        // If there are filters, select all assignments that match the filters
        const updatedSelectedAssignmentStructureIds = produce(selectedAssignmentStructureIds, (draft) => {
          unfilteredAssignmentStructureIds.forEach((id) => draft.add(id));
        });

        setSelectedAssignmentStructureIds(updatedSelectedAssignmentStructureIds);
      }

      setSelectAllAvailableIsToggled(true);

      return;
    }

    // Handle deselecting of all items
    // If there are no filters, deselect all assignments
    if (!unfilteredAssignmentStructureIds.length) {
      setSelectedAssignmentStructureIds(produce(selectedAssignmentStructureIds, (draft) => draft.clear()));
    } else {
      // If there are filters, deselect all assignments that match the filters
      const updatedSelectedAssignmentIds = produce(selectedAssignmentStructureIds, (draft) => {
        unfilteredAssignmentStructureIds.forEach((id) => draft.delete(id));
      });

      setSelectedAssignmentStructureIds(updatedSelectedAssignmentIds);
    }

    setSelectAllAvailableIsToggled(false);
  }, [assignmentsData, selectAllAvailableIsToggled, selectedAssignmentStructureIds, unfilteredAssignmentStructureIds]);

  const handleSelectAllPreviewAssignments = React.useCallback(() => {
    if (!previewData?.length) return;

    // Handle selecting of all items
    if (!selectAllPreviewIsToggled) {
      // If there are no filters, select all assignments
      if (!unfilteredPreviewAssignmentStructureIds.length) {
        setSelectedPreviewRowAssignStructureIds(new Set([...currentAssignmentStructureIds]) as Set<number>);
      } else {
        // If there are filters, select all assignments that match the filters
        const updatedSelectedPreviewAssignmentIds = produce(selectedPreviewRowAssignStructureIds, (draft) => {
          unfilteredPreviewAssignmentStructureIds.forEach((id) => draft.add(id));
        });

        setSelectedPreviewRowAssignStructureIds(updatedSelectedPreviewAssignmentIds);
      }

      setSelectAllPreviewIsToggled(true);

      return;
    }

    // Handle deselecting of all items
    // If there are no filters, deselect all assignments
    if (!unfilteredPreviewAssignmentStructureIds.length) {
      setSelectedPreviewRowAssignStructureIds(produce(selectedPreviewRowAssignStructureIds, (draft) => draft.clear()));
    } else {
      // If there are filters, deselect all assignments that match the filters
      const updatedSelectedPreviewAssignmentIds = produce(selectedPreviewRowAssignStructureIds, (draft) => {
        unfilteredPreviewAssignmentStructureIds.forEach((id) => draft.delete(id));
      });

      setSelectedPreviewRowAssignStructureIds(updatedSelectedPreviewAssignmentIds);
    }

    setSelectAllPreviewIsToggled(false);
  }, [
    previewData,
    selectAllPreviewIsToggled,
    selectedPreviewRowAssignStructureIds,
    unfilteredPreviewAssignmentStructureIds,
    currentAssignmentStructureIds,
  ]);

  // Available assignments search bar
  const getAvailableItemsTableHeader = () => {
    return (
      <TableHeader label="Available Assignments" placeholder="Search Available" handleFilter={handleDebounceFilter} />
    );
  };

  // Available assignments select all button on the table header
  const getAvailableItemsTableHeaderButton = () => {
    return (
      <TableHeaderSelectAll
        handleSelectAll={handleSelectAllAvailableAssignments}
        selectAllIsToggled={selectAllAvailableIsToggled}
      />
    );
  };

  // Selected assignments search bar
  const getPreviewTableHeader = () => {
    return (
      <TableHeader
        label="Selected Assignments"
        placeholder="Search Selected"
        handleFilter={handleDebounceFilterPreview}
      />
    );
  };

  // Selected assignments select all button on the table header
  const getPreviewItemsTableHeaderButton = () => {
    return (
      <TableHeaderSelectAll
        handleSelectAll={handleSelectAllPreviewAssignments}
        selectAllIsToggled={selectAllPreviewIsToggled}
      />
    );
  };

  const handleSetCellColors = (colors: CellColors) => {
    const selectedAssignmentStructureIds = previewData.reduce((acc, cur) => {
      if (selectedPreviewRowAssignStructureIds.has(cur.assignStructureId)) {
        acc.push(cur.assignStructureId);
      }

      return acc;
    }, [] as number[]);

    const updatedView = produce(view, (draft) => {
      // eslint-disable-next-line camelcase
      draft.filter.on_assignments = filter.on_assignments.map((onAssign) => {
        const currentOnAssign = { ...onAssign };

        if (selectedAssignmentStructureIds.includes(onAssign.id as number)) {
          currentOnAssign.color = colors.cellColor || null;
          currentOnAssign.colorText = colors.textColor || null;
        }

        return currentOnAssign;
      });
    });

    updateView(updatedView);
  };

  const assignmentTypeFilterFn = (row: Row<Assignment>, id: string, filterValue: number[]) => {
    if (!filterValue.length) return true;

    const assignTypeId = row.getValue(ViewEditorColumns.ASSIGN_TYPE_ID);

    return filterValue.includes(assignTypeId as number);
  };

  assignmentTypeFilterFn.autoRemove = (val: string) => !val;

  const columnHelper = createColumnHelper<Assignment>();

  const handleAssignmentRowClick = (rowAssignStructureId: number, isCurrentlySelected: boolean) => {
    if (isCurrentlySelected) {
      updateView(
        produce(view, (draft) => {
          // eslint-disable-next-line camelcase
          draft.filter.on_assignments = draft.filter.on_assignments.filter(
            (onAssign) => onAssign.id !== rowAssignStructureId,
          );
        }),
      );
      setSelectedAssignmentStructureIds((prev) => {
        return produce(prev, (draft) => {
          draft.delete(rowAssignStructureId);
        });
      });
    } else {
      updateView(
        produce(view, (draft) => {
          // eslint-disable-next-line camelcase
          draft.filter.on_assignments.push({
            color: null,
            colorText: null,
            id: rowAssignStructureId,
          });
        }),
      );
      setSelectedAssignmentStructureIds((prev) =>
        produce(prev, (draft) => {
          draft.add(rowAssignStructureId);
        }),
      );
    }
  };

  const handlePreviewCellClick = (rowAssignStructureId: number) => {
    setSelectedPreviewRowAssignStructureIds((prev) => {
      const updated = new Set(prev);

      if (prev.has(rowAssignStructureId)) updated.delete(rowAssignStructureId);
      else updated.add(rowAssignStructureId);

      return updated;
    });
  };

  const getCell = (props: CellContext<Assignment, unknown>) => {
    // eslint-disable-next-line react/prop-types
    const { row } = props;
    // eslint-disable-next-line react/prop-types
    const { displayName, assignStructureId, templateId } = row.original;

    // eslint-disable-next-line react/prop-types
    if (!row?.original) return null;

    const isSelected = selectedAssignmentStructureIds.has(assignStructureId);
    const { templateName } = templates.find((t) => t.templateId === templateId) ?? {};
    const appendedTemplateName = templateName ? `(${templateName})` : '';

    return (
      <TableCell
        onClick={() => handleAssignmentRowClick(assignStructureId, isSelected)}
        isSelected={isSelected}
        text={`${displayName} ${appendedTemplateName}`}
      />
    );
  };

  const getPreviewCell = (props: CellContext<Assignment, unknown>) => {
    // eslint-disable-next-line react/prop-types
    const { row } = props;

    // eslint-disable-next-line react/prop-types
    if (!row?.original) return null;

    // eslint-disable-next-line react/prop-types
    const { displayName, templateId, assignStructureId } = row.original;

    // eslint-disable-next-line react/prop-types
    const isSelected = selectedPreviewRowAssignStructureIds.has(assignStructureId);
    const { templateName } = templates.find((t) => t.templateId === templateId) ?? {};
    const appendedTemplateName = templateName ? `(${templateName})` : '';
    const { color, colorText } = filter.on_assignments.find((a) => a.id === assignStructureId) ?? {};

    return (
      <PreviewCell
        onClick={() => handlePreviewCellClick(assignStructureId)}
        isSelected={isSelected}
        text={`${displayName} ${appendedTemplateName}`}
        color={color}
        colorText={colorText}
      />
    );
  };

  const availableItemsColumns = [
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.displayName, {
      cell: getCell,
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorColumns.DISPLAY_NAME,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.assignTypeId, {
      cell: undefined,
      enableHiding: true,
      filterFn: assignmentTypeFilterFn,
      id: ViewEditorColumns.ASSIGN_TYPE_ID,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.expired, {
      cell: undefined,
      enableHiding: true,
      filterFn: 'equals',
      id: ViewEditorColumns.INACTIVE_ASSIGNMENTS,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.assignStructureId, {
      cell: undefined,
      enableHiding: true,
      header: undefined,
      id: ViewEditorColumns.ASSIGN_STRUCTURE_ID,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.autoAddAssignments, {
      cell: undefined,
      enableHiding: true,
      header: undefined,
      id: ViewEditorColumns.AUTO_ADD_ASSIGNMENTS,
    }),
  ];

  const previewColumnsHelper = createColumnHelper<Assignment>();

  const timeThenCustomOrder = (rowA: Row<Assignment>, rowB: Row<Assignment>, _columnId: string) => {
    const aTime = rowA.original.defaultStartTime;
    const bTime = rowB.original.defaultStartTime;

    if (aTime === bTime) {
      // eslint-disable-next-line no-magic-numbers
      return 0;
    }

    // eslint-disable-next-line no-magic-numbers
    return aTime < bTime ? -1 : 1;
  };

  const timeThenName = (rowA: Row<Assignment>, rowB: Row<Assignment>, _columnId: string) => {
    const aTime = rowA.original.defaultStartTime;
    const aName = rowA.original.displayName;
    const bTime = rowB.original.defaultStartTime;
    const bName = rowB.original.displayName;

    if (aTime === bTime) {
      if (aName === bName)
        // eslint-disable-next-line no-magic-numbers
        return 0;
      // eslint-disable-next-line no-magic-numbers
      return aName < bName ? -1 : 1;
    }

    // eslint-disable-next-line no-magic-numbers
    return aTime < bTime ? -1 : 1;
  };

  const previewColumnFilterFn = React.useMemo(() => {
    const { value } = assignmentSortValue;

    switch (value as string) {
      case ViewEditorDraftSortByAssignmentOptions.NAME:
        return ALPHANUMERIC_TABLE_SORT;
      case ViewEditorDraftSortByAssignmentOptions.TIME_THEN_CUSTOM:
        return timeThenCustomOrder;
      case ViewEditorDraftSortByAssignmentOptions.TIME_THEN_NAME:
        return timeThenName;
      default:
        return ALPHANUMERIC_TABLE_SORT;
    }
  }, [assignmentSortValue.value]);

  const previewColumns = [
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.displayName, {
      cell: getPreviewCell,
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorColumns.DISPLAY_NAME,
      sortingFn: previewColumnFilterFn,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.assignTypeId, {
      cell: undefined,
      enableHiding: true,
      filterFn: assignmentTypeFilterFn,
      id: ViewEditorColumns.ASSIGN_TYPE_ID,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.expired, {
      cell: undefined,
      enableHiding: true,
      filterFn: 'equals',
      id: ViewEditorColumns.INACTIVE_ASSIGNMENTS,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.assignStructureId, {
      cell: undefined,
      enableHiding: true,
      header: undefined,
      id: ViewEditorColumns.ASSIGN_STRUCTURE_ID,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.autoAddAssignments, {
      cell: undefined,
      enableHiding: true,
      header: undefined,
      id: ViewEditorColumns.AUTO_ADD_ASSIGNMENTS,
    }),
  ];

  const handleOrderAssignments = (data: Assignment[]) => {
    // Set ordered preview data
    setPreviewData(data);
    // Set order changed (needed to update the view filter on personnel)
    setOrderChanged(true);
  };

  // This is needed to save the color when user changes color and then re-orders items
  React.useMemo(() => {
    if (orderChanged) {
      // Current(selected) assignments
      const assignmentsFilter = [...view.filter.on_assignments];

      // Sort current(selected) assignments based on the order the user defines(via drag&drop or with move buttons)
      const sortedAssignments = customSortArray(assignmentsFilter, [...selectedIDs], ASSIGNMENT_FILTER_SORT_KEY);

      const updatedView = produce(view, (draft) => {
        // eslint-disable-next-line camelcase
        draft.filter.on_assignments = sortedAssignments;
      });

      // Update view with the new assignments(ordered)
      updateView(updatedView);
    }
  }, [previewData, orderChanged, selectedIDs]);

  // Selected preview assignments including text, cell color data and name
  // Used to display the selected item name and the correct colors when opening Color Picker Dialog
  const selectedPreviewItems = React.useMemo(() => {
    // Get selected items from the preview list
    const selected = previewData.filter((item) => selectedPreviewRowAssignStructureIds.has(item.assignStructureId));

    const coloredAssignmentsItems = selected.map((item) => {
      // Get the selected assignments colors from the filter data
      const coloredAssignmentItem = filter.on_assignments.find(
        (assignment) => assignment.id === item.assignStructureId,
      );
      const { templateName } = templates.find((t) => t.templateId === item.templateId) ?? {};
      if (coloredAssignmentItem) {
        return {
          ...coloredAssignmentItem,
          name: templateName ? `${item.displayName}(${templateName})` : item.displayName,
        };
      }
    });

    return coloredAssignmentsItems as ColoredItem[];
  }, [previewData, selectedPreviewRowAssignStructureIds]);

  const handlePreviewTableSorting = () => {
    const isDesc = SortingUtils.getSorting(previewTableSortingState, ViewEditorColumns.DISPLAY_NAME);
    SortingUtils.setSorting(ViewEditorColumns.DISPLAY_NAME, isDesc, setPreviewTableSortingState);
    // Set order changed (needed to update the view filter on personnel)
    setOrderChanged(true);
  };

  const handleAvailableTableSorting = () => {
    const isDesc = SortingUtils.getSorting(availableTableSortingState, ViewEditorColumns.DISPLAY_NAME);
    SortingUtils.setSorting(ViewEditorColumns.DISPLAY_NAME, isDesc, setAvailableTableSortingState);
  };

  return (
    <PaginatedPreviewTable
      availableItemsTableColumnFilters={availableItemsColumnFilters}
      availableItemsTableColumns={availableItemsColumns}
      availableItemsTableColumnVisibility={{
        assignStructureId: false,
        assignTypeId: false,
        autoAddAssignments: false,
        expired: false,
      }}
      availableItemsTableData={assignmentsData ?? []}
      availableItemsTableHeader={getAvailableItemsTableHeader()}
      availableItemsTableHeaderButton={getAvailableItemsTableHeaderButton()}
      availableItemsTableInstanceRef={tableInstanceRef}
      availableItemsTableSortingState={availableTableSortingState}
      availableRowUniqueKey={ASSIGNMENT_SORT_KEY}
      handleSetCellColors={handleSetCellColors}
      headerSortHandlers={[
        {
          columnId: ViewEditorColumns.DISPLAY_NAME,
          sortHandler: () => handleAvailableTableSorting(),
        },
      ]}
      hideSortingTable={!isDraggable}
      isLoading={isAssignmentDataLoading}
      previewItemsTableInstanceRef={previewTableInstanceRef}
      previewTableColumnFilters={previewItemsColumnFilters}
      previewTableColumns={previewColumns}
      previewTableColumnVisibility={{
        assignStructureId: false,
        assignTypeId: false,
        autoAddAssignments: false,
        expired: false,
      }}
      previewTableData={previewData}
      previewTableHeader={getPreviewTableHeader()}
      previewTableHeaderButton={getPreviewItemsTableHeaderButton()}
      previewHeaderSortHandlers={[
        {
          columnId: ViewEditorColumns.DISPLAY_NAME,
          sortHandler: () => handlePreviewTableSorting(),
        },
      ]}
      previewTableSortingState={previewTableSortingState}
      sortingFns={{ timeThenCustomOrder, timeThenName }}
      previewRowsAreDraggable={isDraggable}
      previewRowUniqueKey={ASSIGNMENT_SORT_KEY}
      previewTableDragHandler={(items) => handleOrderAssignments(items as Assignment[])}
      previewTableUniqueSelectedIds={selectedPreviewRowAssignStructureIds}
      selectedPreviewItems={selectedPreviewItems}
      handleResetSorting={() => setPreviewTableSortingState([])}
    />
  );
};

export default ViewEditorAssignments;
