import { FormLabel, HStack, Switch, VStack } from '@chakra-ui/react';
import { produce } from 'immer';
import _ from 'lodash';
import React from 'react';

import CustomDropdown from '@/components/custom-dropdown/CustomDropdown';
import { DEFAULT_TABLE_SIZE } from '@/constants/defaults';
import { YesNoAsBoolean } from '@/types/ui.types';
import View, {
  ViewEditorDisplayDepartmentNames,
  ViewLayoutType,
  ViewThemeData,
  ViewThemeStartOnDay,
  ViewThemeStartOnDayMap,
} from '@/types/view.types';

interface ViewEditorViewerConfigurationProps {
  view: View;
  updateView: (view: View) => void;
}

const ViewEditorViewerConfiguration = (props: ViewEditorViewerConfigurationProps): React.JSX.Element => {
  const { view, updateView } = props;
  const { theme } = view;

  const [themeData, setThemeData] = React.useState(theme.data);

  const setStartDayOfWeek = (day: ViewThemeStartOnDay) => {
    setThemeData((prevState) => {
      return { ...prevState, startOnDay: day };
    });
  };

  const setDepartmentNamesDisplay = (option: ViewEditorDisplayDepartmentNames) => {
    setThemeData((prevState) => {
      return { ...prevState, displayDepartmentNames: option };
    });
  };

  type ToggleChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, themeDataKey: keyof ViewThemeData) => void;
  const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>, themeDataKey: keyof ViewThemeData) => {
    const { checked } = e.target;

    setThemeData((prevState) => {
      return { ...prevState, [themeDataKey]: checked ? 'yes' : 'no' };
    });
  };

  const getToggleRow = (
    label: string,
    value: boolean,
    onChange: ToggleChangeHandler,
    themeDataKey: keyof ViewThemeData,
  ) => {
    return (
      <HStack justifyContent={'space-between'} align={'center'}>
        <FormLabel>{label}</FormLabel>
        <Switch isChecked={value} onChange={(e) => onChange(e, themeDataKey)} />
      </HStack>
    );
  };

  const buildOptions = () => {
    const options = [];
    const { layout } = themeData;

    if (layout === ViewLayoutType.STANDARD || layout === ViewLayoutType.CALENDAR) {
      options.push(
        <CustomDropdown
          label={'Start Day of Week'}
          options={Object.values(ViewThemeStartOnDayMap)}
          onChange={(e) =>
            setStartDayOfWeek(
              Object.entries(ViewThemeStartOnDayMap).find(
                ([, value]) => value === e.target.value,
                // eslint-disable-next-line no-magic-numbers
              )?.[0] as ViewThemeStartOnDay,
            )
          }
          selectedOption={
            themeData.startOnDay ? ViewThemeStartOnDayMap[themeData.startOnDay] : ViewThemeStartOnDayMap['Mon']
          }
          width={'300px'}
        />,
      );
    }

    if (
      layout === ViewLayoutType.STANDARD ||
      layout === ViewLayoutType.COLUMNS ||
      layout === ViewLayoutType.CALENDAR
    ) {
      options.push(
        <CustomDropdown
          label={'Show Department Names'}
          options={Object.values(ViewEditorDisplayDepartmentNames).map((option) =>
            option
              .split(' ')
              .map((word) => _.upperFirst(word))
              .join(' '),
          )}
          onChange={(e) => {
            const normalizedValue = e.target.value
              .split(' ')
              .map((word) => _.upperFirst(word))
              .join(' ');
            setDepartmentNamesDisplay(normalizedValue as ViewEditorDisplayDepartmentNames);
          }}
          selectedOption={
            (themeData.displayDepartmentNames
              ? themeData.displayDepartmentNames                  
              : ViewEditorDisplayDepartmentNames.NONE)
              .split(' ')
              .map((word) => _.upperFirst(word))
              .join(' ')
          }
          width={'300px'}
        />,
      );
    }

    options.push(
      getToggleRow(
        'Compact Personnel and Assignment Names',
        themeData.compactMode ? YesNoAsBoolean[themeData.compactMode] : false,
        handleToggleChange,
        'compactMode',
      ),
    );

    if (layout === ViewLayoutType.COLUMNS) {
      options.push(
        getToggleRow(
          'Compact Columns to Fit Screen',
          themeData.condenseColumns ? YesNoAsBoolean[themeData.condenseColumns] : false,
          handleToggleChange,
          'condenseColumns',
        ),
      );
    }

    if (
      layout === ViewLayoutType.STANDARD ||
      layout === ViewLayoutType.COLUMNS ||
      layout === ViewLayoutType.BLOCK
    ) {
      options.push(
        getToggleRow(
          'Hide Blank Rows',
          themeData.hideBlankRows ? YesNoAsBoolean[themeData.hideBlankRows] : false,
          handleToggleChange,
          'hideBlankRows',
        ),
      );
    }

    if (
      layout === ViewLayoutType.STANDARD ||
      layout === ViewLayoutType.COLUMNS ||
      layout === ViewLayoutType.CALENDAR
    ) {
      options.push(
        getToggleRow(
          'Hide Weekends',
          themeData.hideWeekends ? YesNoAsBoolean[themeData.hideWeekends] : false,
          handleToggleChange,
          'hideWeekends',
        ),
      );

      options.push(
        getToggleRow(
          'Show Times',
          themeData.showTimes ? YesNoAsBoolean[themeData.showTimes] : false,
          handleToggleChange,
          'showTimes',
        ),
      );

      options.push(
        getToggleRow(
          'Enable Saved Filters',
          themeData.personalFilter ? YesNoAsBoolean[themeData.personalFilter] : false,
          handleToggleChange,
          'personalFilter',
        ),
      );
    }

    if (layout === ViewLayoutType.STANDARD || layout === ViewLayoutType.COLUMNS) {
      options.push(
        getToggleRow(
          'Tallies Count Personnel Based on Filter',
          themeData.shouldCountTalliesByPersonnelFilter
            ? YesNoAsBoolean[themeData.shouldCountTalliesByPersonnelFilter]
            : false,
          handleToggleChange,
          'shouldCountTalliesByPersonnelFilter',
        ),
      );
    }

    if (layout === ViewLayoutType.LIST) {
      options.push(
        getToggleRow(
          'Hide Pending Slots',
          themeData.hidePending ? YesNoAsBoolean[themeData.hidePending] : false,
          handleToggleChange,
          'hidePending',
        ),
      );
    }

    options.push(
      getToggleRow(
        'Show Logged In User Only',
        themeData.showLoggedInOnly ? YesNoAsBoolean[themeData.showLoggedInOnly] : false,
        handleToggleChange,
        'showLoggedInOnly',
      ),
    );

    return options;
  };

  React.useMemo(() => {
    updateView(
      produce(view, (draft) => {
        draft.theme.data = themeData;
      }),
    );
  }, [themeData]);

  return (
    <VStack align={'left'} justifyContent={'space-between'} gap={6} w={`${DEFAULT_TABLE_SIZE}px`}>
      {buildOptions()}
    </VStack>
  );
};

export default ViewEditorViewerConfiguration;
