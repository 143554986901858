import { Box, useToast } from '@chakra-ui/react';
import { createColumnHelper, Row } from '@tanstack/react-table';
import React from 'react';
import { Updater, useImmer } from 'use-immer';

import { useLazyGetViewsQuery, ViewOrderBy } from '@/API/views.api';
import { cellFactory } from '@/components/available-preview-list/aplUtils';
import { DEFAULT_TOAST_DURATION, ToastTypes } from '@/constants/defaults';
import { PersonnelViewAccessBody } from '@/types/personnel.types';
import { ViewLite } from '@/types/view.types';

import AvailablePreviewList from '../available-preview-list/AvailablePreviewList';

enum TableColumnIds {
  templateIds = 'templateIds',
  viewId = 'viewId',
  viewName = 'viewName',
}

interface TableState {
  availableItems: Map<number, ViewLite>;
  isLoading: boolean;
  previewItems: Map<number, ViewLite>;
}

interface PersonnelViewMembershipProps {
  readonly currentPersonnelViewAccess: PersonnelViewAccessBody;
  readonly employeeName: string;
  readonly isOpen: boolean;
  readonly selectedDepartments: number[];
  readonly selectedTemplates: number[];
  readonly updateEmployeeAccess: Updater<PersonnelViewAccessBody>;
}

const PersonnelViewMembership = (props: PersonnelViewMembershipProps): React.JSX.Element => {
  const {
    currentPersonnelViewAccess,
    employeeName,
    isOpen,
    selectedDepartments,
    selectedTemplates,
    updateEmployeeAccess,
  } = props;

  const toast = useToast();

  const [state, updateTableState] = useImmer<TableState>({
    availableItems: new Map<number, ViewLite>(),
    isLoading: true,
    previewItems: new Map<number, ViewLite>(),
  });

  const [fetchAvailableViews] = useLazyGetViewsQuery();

  const [fetchCurrentMembershipViews] = useLazyGetViewsQuery();

  React.useEffect(() => {
    if (!selectedDepartments.length) {
      updateTableState((draft) => {
        draft.availableItems = new Map();
      });

      return;
    }

    (async () => {
      try {
        const response = await fetchAvailableViews({
          departmentList: selectedDepartments,
          filter: '',
          isAscending: true,
          orderBy: ViewOrderBy.ViewName,
          templateList: [],
        }, true);

        if (response?.data) {
          updateTableState((draft) => {
            const views = new Map();

            response?.data?.forEach((view) => views.set(view.id, view));

            draft.availableItems = views;
          });
        }
      } catch (err) {
        toast({
          description: 'Unable to fetch available views',
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          status: ToastTypes.ERROR,
          title: 'Error',
        });
      }
    })();
  }, [fetchAvailableViews, selectedDepartments, toast, updateTableState]);

  React.useEffect(() => {
    if (!currentPersonnelViewAccess.filterIds.length) {
      updateTableState((draft) => {
        draft.previewItems = new Map();
      });

      return;
    }

    (async () => {
      try {
        const response = await fetchCurrentMembershipViews({
          viewIdsList: currentPersonnelViewAccess.filterIds,
        }, true);

        if (response?.data) {
          updateTableState((draft) => {
            const views = new Map();

            response?.data?.forEach((view) => views.set(view.id, view));

            draft.previewItems = views;
            draft.isLoading = false;
          });
        }
      } catch (err) {
        toast({
          description: 'Unable to fetch current view membership',
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          status: ToastTypes.ERROR,
          title: 'Error',
        });
        updateTableState((draft) => {
          draft.isLoading = false;
        });
      }
    })();
    // Initial load only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowTemplateIdFilterFn = (row: Row<ViewLite>, _: string, filterValue: number[]) => {
    if (!filterValue.length) return true;

    // eslint-disable-next-line react/prop-types
    const rowTemplateIds: number[] = row.getValue(TableColumnIds.templateIds);

    return selectedTemplates.some((templateId) => rowTemplateIds.includes(templateId));
  };

  const availableColumnHelper = createColumnHelper<ViewLite>();

  const availableItemsColumns = [
    // eslint-disable-next-line react/prop-types
    availableColumnHelper.accessor((row) => row.name, {
      cell: (item) => cellFactory<ViewLite>({ cellContext: item, text: item.row.original.name }),
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: TableColumnIds.viewName,
      sortDescFirst: true,
    }),
    // eslint-disable-next-line react/prop-types
    availableColumnHelper.accessor((row) => row.id, {
      cell: undefined,
      enableHiding: true,
      id: TableColumnIds.viewId,
    }),
    // eslint-disable-next-line react/prop-types
    availableColumnHelper.accessor((row) => row.templates, {
      cell: undefined,
      enableHiding: true,
      filterFn: rowTemplateIdFilterFn,
      header: undefined,
      id: TableColumnIds.templateIds,
    }),
  ];

  const previewColumnsHelper = createColumnHelper<ViewLite>();

  const previewColumns = [
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.name, {
      cell: (item) => cellFactory<ViewLite>({ cellContext: item, text: item.row.original.name }),
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: TableColumnIds.viewName,
      sortDescFirst: true,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.id, {
      cell: undefined,
      enableHiding: true,
      id: TableColumnIds.viewId,
    }),
  ];

  const handleSelectedItemsChanged = (selectedItemIds: number[]) => {
    updateEmployeeAccess((draft) => {
      draft.filterIds = selectedItemIds;
    });
  };

  return (
    <AvailablePreviewList
      availableColumns={availableItemsColumns}
      availableItems={state.availableItems}
      availableItemsPrimaryColumnId={TableColumnIds.viewName}
      availableItemsTableLabel="Available Views"
      availableRowUniqueKey={TableColumnIds.viewId}
      currentlySelectedItemIds={currentPersonnelViewAccess.filterIds}
      isOpen={isOpen}
      previewColumns={previewColumns}
      previewItems={state.previewItems}
      previewItemsPrimaryColumnId={TableColumnIds.viewName}
      previewItemsTableLabel={`Views ${employeeName} is a member of`}
      previewRowUniqueKey={TableColumnIds.viewId}
      primaryAvailableColumnIndex={0}
      primaryPreviewColumnIndex={0}
      selectedDepartments={selectedDepartments}
      selectedItemsChangedCallback={handleSelectedItemsChanged}
      selectedTemplates={selectedTemplates}
      tableColumnIds={TableColumnIds}
    />
  );
};

export default PersonnelViewMembership;
