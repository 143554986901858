import { Box, FormControl, FormLabel, HStack, Switch, VStack } from '@chakra-ui/react';
import { CellContext, ColumnFiltersState, createColumnHelper, Row, SortingState, Table } from '@tanstack/react-table';
import { produce } from 'immer';
import _ from 'lodash';
import React, { useRef } from 'react';
import Select from 'react-select';

import { useGetPersonnelBySearchQuery } from '@/API/personnel.api';
import FilterSelect, { FilterSelectValue } from '@/components/filter-select/FilterSelect';
import PaginatedPreviewTable, { CellColors } from '@/components/paginated-preview-table/PaginatedPreviewTable';
import PreviewCell from '@/components/preview-cell/PreviewCell';
import TableCell from '@/components/table-cell/TableCell';
import TableHeader from '@/components/table-header/TableHeader';
import TableHeaderSelectAll from '@/components/table-header-select-all/TableHeaderSelectAll';
import { ViewEditorColumnFilterFields } from '@/components/view-editor-drawer/ViewEditorDrawer';
import UIConfig from '@/config/ui.config';
import { DEFAULT_Z_INDEX } from '@/constants/defaults';
import { PersonnelItemSlim, PersonnelType } from '@/types/personnel.types';
import { ColoredItem, PersonnelSortOrderDropDownOption, PersonnelSortOrderDropDownOptions } from '@/types/ui.types';
import View, { ViewColoredItem, ViewEditorDraftSortByPersonnelOptions } from '@/types/view.types';
import { customSortArray } from '@/utils/arrays';
import SortingUtils from '@/utils/sorting';

// Key to sort items on the table
const PERSONNEL_SORT_KEY = 'id';

// Key to sort items on the view object
const PERSONNEL_FILTER_SORT_KEY = 'id';

interface ViewEditorPersonnelProps {
  personnelTypes: PersonnelType[];
  updateView: (view: View) => void;
  view: View;
}

type PersonnelToggleFiltersProps = {
  autoAddPersonnel?: boolean;
  hideInactivePersonnel?: boolean;
};

enum ViewEditorPersonnelColumns {
  AUTO_ADD_PERSONNEL = 'autoAddPersonnel',
  DISPLAY_NAME = 'displayName',
  INACTIVE_PERSONNEL = 'expired',
  PERSONNEL_ID = 'personnelId',
  PERSONNEL_TYPE_ID = 'personnelTypeId',
}

// Sort ascending by default
const DEFAULT_AVAILABLE_TABLE_SORTING: SortingState = [
  {
    desc: false,
    id: ViewEditorPersonnelColumns.DISPLAY_NAME,
  },
];

const ViewEditorPersonnel = (props: ViewEditorPersonnelProps): React.JSX.Element => {
  const { personnelTypes, updateView, view } = props;

  // Contains the current view data
  const { filter } = view;

  // Fetch personnel for the selected departments
  const { data: personnelData, isLoading } = useGetPersonnelBySearchQuery(
    {
      DepartmentIds: view.filter.on_departments,
      Slim: true,
    },
    { skip: !view.filter.on_departments.length },
  );

  // UI State

  const personnelToggleFiltersInit: PersonnelToggleFiltersProps = {
    autoAddPersonnel: filter?.auto_add_personnel,
    hideInactivePersonnel: filter?.hide_inactive_personnel,
  };

  const [selectedPersonnelTypes, setSelectedPersonnelTypes] = React.useState<number[]>([]);
  const [selectAllAvailableIsToggled, setSelectAllAvailableIsToggled] = React.useState<boolean>(false);
  const [selectAllPreviewIsToggled, setSelectAllPreviewIsToggled] = React.useState<boolean>(false);
  const [personnelSortValue, setPersonnelSortValue] = React.useState<PersonnelSortOrderDropDownOption>(
    PersonnelSortOrderDropDownOptions.find(({ value }) => value === filter.sort_personnel_by) ??
      // eslint-disable-next-line no-magic-numbers
      PersonnelSortOrderDropDownOptions[0],
  );
  const [personnelToggleFilters, setPersonnelToggleFilters] =
    React.useState<PersonnelToggleFiltersProps>(personnelToggleFiltersInit);

  const [availableTableSortingState, setAvailableTableSortingState] = React.useState<SortingState>(
    DEFAULT_AVAILABLE_TABLE_SORTING,
  );
  // End UI State

  // Preview Table State
  // A memoized list of all personnel ids which are currently contained in the view filter
  const currentPersonnelIds = React.useMemo(() => {
    return filter.on_personnel.map((personnel) => personnel.id);
  }, [filter]);

  const [availableItemsColumnFilters, setAvailableItemsColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [previewItemsColumnFilters, setPreviewItemsColumnFilters] = React.useState<ColumnFiltersState>([]);
  // Contains a list of all available personnel which are currently selected
  const [selectedPersonnelIds, setSelectedPersonnelIds] = React.useState<Set<number>>(
    new Set(currentPersonnelIds as number[]),
  );
  const [unfilteredPersonnelIds, setUnfilteredPersonnelIds] = React.useState<number[]>([]);
  const [unfilteredPreviewPersonnelIds, setUnfilteredPreviewPersonnelIds] = React.useState<number[]>([]);
  const tableInstanceRef = React.useRef<Table<PersonnelItemSlim>>();
  const previewTableInstanceRef = React.useRef<Table<PersonnelItemSlim>>();
  // Contains a list of all personnel rows in the preview table which are currently selected (impacts color highlighting and DnD)
  const [selectedPreviewRowPersonnelIds, setSelectedPreviewRowPersonnelIds] = React.useState<Set<number>>(new Set());
  // Contains a list of all personnel in the preview table. These are the personnel which will be saved to the view
  const [previewData, setPreviewData] = React.useState<PersonnelItemSlim[]>([]);
  const [orderChanged, setOrderChanged] = React.useState<boolean>(false);
  const [previewTableSortingState, setPreviewTableSortingState] = React.useState<SortingState>([]);
  // End Preview Table State

  // A memoized list of all personnel ids which are available in the preview table
  // This list includes all personnel which are currently selected, as well as all personnel which are not selected
  const allPersonnelIds = React.useMemo(() => {
    if (personnelData?.length) {
      return new Set(personnelData.map((personnel) => personnel.id));
    }

    return new Set<number>();
  }, [personnelData, selectAllAvailableIsToggled]);

  const isDraggable = React.useMemo(
    () =>
      personnelSortValue.value === ViewEditorDraftSortByPersonnelOptions.TIME_THEN_CUSTOM ||
      personnelSortValue.value === ViewEditorDraftSortByPersonnelOptions.CUSTOM,
    [personnelSortValue],
  );

  // Selected personnel IDs
  const selectedIDs = React.useMemo(() => {
    return previewData.map((item) => item.id);
  }, [previewData]);

  // Selected personnel
  const currentPersonnel = React.useMemo(() => {
    const personnelItems = personnelData?.filter((personnel) => selectedPersonnelIds.has(personnel.id)) ?? [];

    // Sort current(selected) personnel based on the order they have been saved
    const sortedPersonnel = customSortArray(personnelItems, [...selectedPersonnelIds], PERSONNEL_SORT_KEY);

    return sortedPersonnel;
  }, [personnelData, selectedPersonnelIds]);

  const normalizedPersonnelDataRef = useRef<ViewColoredItem[]>([]);

  normalizedPersonnelDataRef.current = currentPersonnel.map((personnel) => {
    // Preserve color selections
    const { color, colorText } = filter.on_personnel.find((p) => p.id === personnel.id) ?? {};
    return {
      color: color || null,
      colorText: colorText || null,
      id: personnel.id,
    };
  });

  // When the current personnel change, update the preview table data
  React.useMemo(() => {
    setPreviewData(currentPersonnel);
  }, [currentPersonnel]);

  React.useMemo(() => {
    if (selectAllAvailableIsToggled) {
      // Add all selected personnel
      updateView(
        produce(view, (draft) => {
          // eslint-disable-next-line camelcase
          draft.filter.on_personnel = normalizedPersonnelDataRef.current;
        }),
      );
    } else {
      // Remove all personnel
      updateView(
        produce(view, (draft) => {
          // eslint-disable-next-line camelcase
          draft.filter.on_personnel = [];
        }),
      );
    }
  }, [selectAllAvailableIsToggled]);

  // When the selected personnel types change, update the view filter
  React.useEffect(() => {
    updateView(
      produce(view, (draft) => {
        // eslint-disable-next-line camelcase
        draft.filter.sort_personnel_by = personnelSortValue.value;
      }),
    );
  }, [personnelSortValue]);

  // When the auto add assignment toggle changes, update the view filter
  React.useMemo(() => {
    updateView(
      produce(view, (draft) => {
        // eslint-disable-next-line camelcase
        draft.filter.hide_inactive_personnel = personnelToggleFilters.hideInactivePersonnel;
        // eslint-disable-next-line camelcase
        draft.filter.auto_add_personnel = personnelToggleFilters.autoAddPersonnel;
      }),
    );
  }, [personnelToggleFilters]);

  const debouncedFilter = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setAvailableItemsColumnFilters((prev) => {
      const currentFilters = prev.filter(({ id }) => id !== ViewEditorColumnFilterFields.DISPLAY_NAME);

      return produce(currentFilters, (draft) => {
        draft.push({ id: ViewEditorColumnFilterFields.DISPLAY_NAME, value: e.target.value });
      });
    });
  }, UIConfig.DEFAULT_DEBOUNCE_TIME_MS);

  const debouncedFilterPreviewTable = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setPreviewItemsColumnFilters([{ id: ViewEditorColumnFilterFields.DISPLAY_NAME, value: e.target.value }]);
  }, UIConfig.DEFAULT_DEBOUNCE_TIME_MS);

  const handleAutoAddPersonnelToggle = () => {
    // If toggle was just turned on, add the filter
    if (!personnelToggleFilters.autoAddPersonnel) {
      setAvailableItemsColumnFilters((prev) => {
        const currentFilters = prev.filter(({ id }) => id !== ViewEditorColumnFilterFields.AUTO_ADD_PERSONNEL);

        return produce(currentFilters, (draft) => {
          draft.push({ id: ViewEditorColumnFilterFields.AUTO_ADD_PERSONNEL, value: true });
        });
      });
    }
    // If toggle was just turned off, remove the filter
    else {
      setAvailableItemsColumnFilters((prev) => {
        return prev.filter(({ id }) => id !== ViewEditorColumnFilterFields.AUTO_ADD_PERSONNEL);
      });
    }
    setPersonnelToggleFilters((prev) => {
      return {
        ...prev,
        autoAddPersonnel: !personnelToggleFilters.autoAddPersonnel,
      };
    });
  };

  const handleSelectAllAvailablePersonnel = React.useCallback(() => {
    if (!personnelData?.length) return;

    // Handle selecting of all items
    if (!selectAllAvailableIsToggled) {
      // If there are no filters, select all personnel
      if (!unfilteredPersonnelIds.length) {
        setSelectedPersonnelIds(allPersonnelIds);
      } else {
        // If there are filters, select all personnel that match the filters
        const updatedSelectedPersonnelIds = produce(selectedPersonnelIds, (draft) => {
          unfilteredPersonnelIds.forEach((id) => draft.add(id));
        });

        setSelectedPersonnelIds(updatedSelectedPersonnelIds);
      }

      setSelectAllAvailableIsToggled(true);

      return;
    }

    // Handle deselecting of all items
    // If there are no filters, deselect all personnel
    if (!unfilteredPersonnelIds.length) {
      setSelectedPersonnelIds(produce(selectedPersonnelIds, (draft) => draft.clear()));
    } else {
      // If there are filters, deselect all personnel that match the filters
      const updatedSelectedPersonnel = produce(selectedPersonnelIds, (draft) => {
        unfilteredPersonnelIds.forEach((id) => draft.delete(id));
      });

      setSelectedPersonnelIds(updatedSelectedPersonnel);
    }

    setSelectAllAvailableIsToggled(false);
  }, [personnelData, selectAllAvailableIsToggled, selectedPersonnelIds, unfilteredPersonnelIds]);

  const handleSelectAllPreviewPersonnel = React.useCallback(() => {
    if (!previewData?.length) return;

    // Handle selecting of all items
    if (!selectAllPreviewIsToggled) {
      // If there are no filters, select all personnel
      if (!unfilteredPreviewPersonnelIds.length) {
        setSelectedPreviewRowPersonnelIds(new Set([...currentPersonnelIds]) as Set<number>);
      } else {
        // If there are filters, select all personnel that match the filters
        const updatedSelectedPreviewPersonnelIds = produce(selectedPreviewRowPersonnelIds, (draft) => {
          unfilteredPreviewPersonnelIds.forEach((id) => draft.add(id));
        });

        setSelectedPreviewRowPersonnelIds(updatedSelectedPreviewPersonnelIds);
      }

      setSelectAllPreviewIsToggled(true);

      return;
    }

    // Handle deselecting of all items
    // If there are no filters, deselect all personnel
    if (!unfilteredPreviewPersonnelIds.length) {
      setSelectedPreviewRowPersonnelIds(produce(selectedPreviewRowPersonnelIds, (draft) => draft.clear()));
    } else {
      // If there are filters, deselect all personnel that match the filters
      const updatedSelectedPreviewPersonnelIds = produce(selectedPreviewRowPersonnelIds, (draft) => {
        unfilteredPreviewPersonnelIds.forEach((id) => draft.delete(id));
      });

      setSelectedPreviewRowPersonnelIds(updatedSelectedPreviewPersonnelIds);
    }

    setSelectAllPreviewIsToggled(false);
  }, [
    previewData,
    selectAllPreviewIsToggled,
    selectedPreviewRowPersonnelIds,
    unfilteredPreviewPersonnelIds,
    currentPersonnelIds,
  ]);

  // Available personnel search bar
  const getAvailableItemsTableHeader = () => {
    return <TableHeader label="Available Personnel" placeholder="Search Available" handleFilter={debouncedFilter} />;
  };

  // Available personnel select all button on the table header
  const getAvailableItemsTableHeaderButton = () => {
    return (
      <TableHeaderSelectAll
        handleSelectAll={handleSelectAllAvailablePersonnel}
        selectAllIsToggled={selectAllAvailableIsToggled}
      />
    );
  };

  // Selected personnel search bar
  const getPreviewTableHeader = () => {
    return (
      <TableHeader
        label="Selected Personnel"
        placeholder="Search Selected"
        handleFilter={debouncedFilterPreviewTable}
      />
    );
  };

  // Selected personnel select all button on the table header
  const getPreviewItemsTableHeaderButton = () => {
    return (
      <TableHeaderSelectAll
        handleSelectAll={handleSelectAllPreviewPersonnel}
        selectAllIsToggled={selectAllPreviewIsToggled}
      />
    );
  };

  const handleSetCellColors = (colors: CellColors) => {
    const selectedPersonnelIds = previewData.reduce((acc, cur) => {
      if (selectedPreviewRowPersonnelIds.has(cur.id)) {
        acc.push(cur.id);
      }

      return acc;
    }, [] as number[]);

    const updatedView = produce(view, (draft) => {
      // eslint-disable-next-line camelcase
      draft.filter.on_personnel = filter.on_personnel.map((onPersonnel) => {
        const currentOnPersonnel = { ...onPersonnel };

        if (selectedPersonnelIds.includes(Number(onPersonnel.id))) {
          currentOnPersonnel.color = colors.cellColor || null;
          currentOnPersonnel.colorText = colors.textColor || null;
        }

        return currentOnPersonnel;
      });
    });

    updateView(updatedView);
  };

  const personnelTypeFilterFn = (row: Row<PersonnelItemSlim>, id: string, filterValue: number[]) => {
    if (!filterValue.length) return true;

    const personnelTypeId = row.getValue('personnelTypeId');

    return filterValue.includes(personnelTypeId as number);
  };

  personnelTypeFilterFn.autoRemove = (val: string) => !val;

  const columnHelper = createColumnHelper<PersonnelItemSlim>();

  const handlePersonnelRowClick = (rowPersonnelId: number, isCurrentlySelected: boolean) => {
    if (isCurrentlySelected) {
      updateView(
        produce(view, (draft) => {
          // eslint-disable-next-line camelcase
          draft.filter.on_personnel = draft.filter.on_personnel.filter(
            (onPersonnel) => onPersonnel.id !== rowPersonnelId,
          );
        }),
      );
      setSelectedPersonnelIds((prev) => {
        return produce(prev, (draft) => {
          draft.delete(rowPersonnelId);
        });
      });
    } else {
      updateView(
        produce(view, (draft) => {
          // eslint-disable-next-line camelcase
          draft.filter.on_personnel.push({
            color: null,
            colorText: null,
            id: rowPersonnelId,
          });
        }),
      );
      setSelectedPersonnelIds((prev) =>
        produce(prev, (draft) => {
          draft.add(rowPersonnelId);
        }),
      );
    }
  };

  const handlePreviewCellClick = (rowPersonnelId: number) => {
    setSelectedPreviewRowPersonnelIds((prev) => {
      const updated = new Set(prev);

      if (prev.has(rowPersonnelId)) updated.delete(rowPersonnelId);
      else updated.add(rowPersonnelId);

      return updated;
    });
  };

  const getCell = (props: CellContext<PersonnelItemSlim, unknown>) => {
    // eslint-disable-next-line react/prop-types
    const { row } = props;
    // eslint-disable-next-line react/prop-types
    const {
      // eslint-disable-next-line react/prop-types
      name: { display },
      // eslint-disable-next-line react/prop-types
      id,
      // eslint-disable-next-line react/prop-types
    } = row.original;

    // eslint-disable-next-line react/prop-types
    if (!row?.original) return null;

    const isSelected = selectedPersonnelIds.has(id);

    return (
      <TableCell
        // eslint-disable-next-line react/prop-types
        onClick={() => handlePersonnelRowClick(row.original.id, isSelected)}
        isSelected={isSelected}
        text={display}
      />
    );
  };

  const getPreviewCell = (props: CellContext<PersonnelItemSlim, unknown>) => {
    // eslint-disable-next-line react/prop-types
    const { row } = props;

    // eslint-disable-next-line react/prop-types
    if (!row?.original) return null;

    // eslint-disable-next-line react/prop-types
    const {
      // eslint-disable-next-line react/prop-types
      name: { display },
      id,
      // eslint-disable-next-line react/prop-types
    } = row.original;

    // eslint-disable-next-line react/prop-types
    const isSelected = selectedPreviewRowPersonnelIds.has(id);
    const { color, colorText } = filter.on_personnel.find((p) => p.id === id) ?? {};

    return (
      <PreviewCell
        onClick={() => handlePreviewCellClick(id)}
        isSelected={isSelected}
        text={display}
        color={color}
        colorText={colorText}
      />
    );
  };

  const availableItemsColumns = [
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.name.display, {
      cell: getCell,
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorPersonnelColumns.DISPLAY_NAME,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.pTypeId, {
      cell: undefined,
      enableHiding: true,
      filterFn: personnelTypeFilterFn,
      id: ViewEditorPersonnelColumns.PERSONNEL_TYPE_ID,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.expired, {
      cell: undefined,
      enableHiding: true,
      filterFn: 'equals',
      id: ViewEditorPersonnelColumns.INACTIVE_PERSONNEL,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.id, {
      cell: undefined,
      enableHiding: true,
      header: undefined,
      id: ViewEditorPersonnelColumns.PERSONNEL_ID,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.autoAddPersonnel, {
      cell: undefined,
      enableHiding: true,
      header: undefined,
      id: ViewEditorPersonnelColumns.AUTO_ADD_PERSONNEL,
    }),
  ];

  const previewColumnsHelper = createColumnHelper<PersonnelItemSlim>();

  const displayName = (rowA: Row<PersonnelItemSlim>, rowB: Row<PersonnelItemSlim>) => {
    return SortingUtils.sortStrings(rowA.original.name.display, rowB.original.name.display);
  };

  const lastName = (rowA: Row<PersonnelItemSlim>, rowB: Row<PersonnelItemSlim>) => {
    return SortingUtils.sortStrings(rowA.original.name.last, rowB.original.name.last);
  };

  const previewColumnFilterFn = React.useMemo(() => {
    const { value } = personnelSortValue;

    switch (value) {
      case ViewEditorDraftSortByPersonnelOptions.LAST_NAME:
        return lastName;
      case ViewEditorDraftSortByPersonnelOptions.DISPLAY_NAME:
        return displayName;
      default:
        return displayName;
    }
  }, [personnelSortValue.value]);

  const previewColumns = [
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.name.display, {
      cell: getPreviewCell,
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorPersonnelColumns.DISPLAY_NAME,
      sortingFn: previewColumnFilterFn,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.pTypeId, {
      cell: undefined,
      enableHiding: true,
      filterFn: personnelTypeFilterFn,
      id: ViewEditorPersonnelColumns.PERSONNEL_TYPE_ID,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.expired, {
      cell: undefined,
      enableHiding: true,
      filterFn: 'equals',
      id: ViewEditorPersonnelColumns.INACTIVE_PERSONNEL,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.id, {
      cell: undefined,
      enableHiding: true,
      header: undefined,
      id: ViewEditorPersonnelColumns.PERSONNEL_ID,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.autoAddPersonnel, {
      cell: undefined,
      enableHiding: true,
      header: undefined,
      id: ViewEditorPersonnelColumns.AUTO_ADD_PERSONNEL,
    }),
  ];

  /*
   * React-Table v8 'equals' filter function now operates off Object.is() / === equality;
   * ergo we must remove the filter when the toggle is turned off to show all personnel
   * */
  const handleInactivePersonnelToggle = () => {
    // If toggle was just turned on, add the filter
    if (!personnelToggleFilters.hideInactivePersonnel) {
      setAvailableItemsColumnFilters((prev) => {
        const currentFilters = prev.filter(({ id }) => id !== ViewEditorColumnFilterFields.INACTIVE_ASSIGNMENTS);

        return produce(currentFilters, (draft) => {
          draft.push({ id: ViewEditorColumnFilterFields.INACTIVE_ASSIGNMENTS, value: false });
        });
      });
    }
    // If toggle was just turned off, remove the filter
    else {
      setAvailableItemsColumnFilters((prev) => {
        return prev.filter(({ id }) => id !== ViewEditorColumnFilterFields.INACTIVE_ASSIGNMENTS);
      });
    }
    setPersonnelToggleFilters((prev) => {
      return {
        ...prev,
        hideInactivePersonnel: !personnelToggleFilters.hideInactivePersonnel,
      };
    });
  };

  const handleSelectPersonnelType = (values: unknown) => {
    setSelectedPersonnelTypes((values as FilterSelectValue[]).map((personnelType) => personnelType.value));
  };

  const handleSetPersonnelTypeFilter = () => {
    setAvailableItemsColumnFilters((prev) => {
      const currentFilters = prev.filter(({ id }) => id !== 'personnelTypeId');

      return produce(currentFilters, (draft) => {
        draft.push({ id: 'personnelTypeId', value: selectedPersonnelTypes });
      });
    });
  };

  const getTopBar = (): React.JSX.Element => {
    return (
      <HStack zIndex={DEFAULT_Z_INDEX} spacing="24px" alignItems="flex-end">
        <VStack alignItems="flex-start">
          <FormLabel>Filter Available Personnel by Personnel Types</FormLabel>
          <FilterSelect
            hasSelectAll={true}
            inputName={'filterByPersonnelType'}
            isMultiSelect={true}
            onChangeHandler={handleSelectPersonnelType}
            onCloseHandler={handleSetPersonnelTypeFilter}
            options={personnelTypes}
            optionValueKey={'ptypeId'}
            optionLabelKey={'name'}
            placeHolderText={'Personnel Types'}
            selectedValues={selectedPersonnelTypes}
          />
        </VStack>

        <VStack alignItems="flex-start">
          <FormLabel>Sort Selected Personnel by</FormLabel>
          <Select
            styles={{
              control: (styles) => ({
                ...styles,
                maxWidth: '250px',
                minWidth: '250px',
              }),
            }}
            options={PersonnelSortOrderDropDownOptions}
            value={personnelSortValue}
            onChange={(value) => setPersonnelSortValue(value as PersonnelSortOrderDropDownOption)}
          />
        </VStack>

        <VStack alignItems="flex-end" height="100%" justifyContent="flex-end">
          <FormControl display="flex" alignItems="center">
            <HStack w={'240px'} justifyContent={'space-between'}>
              <FormLabel htmlFor="auto-add-personnel" mb="0">
                Auto-Add Personnel
              </FormLabel>
              <Switch
                id="auto-add-personnel"
                isChecked={personnelToggleFilters.autoAddPersonnel}
                onChange={handleAutoAddPersonnelToggle}
              />
            </HStack>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <HStack w={'240px'} justifyContent={'space-between'}>
              <FormLabel htmlFor="hide-inactive-from-available" mb="0">
                Hide Inactive Personnel
              </FormLabel>
              <Switch
                id="hide-inactive-from-available"
                isChecked={personnelToggleFilters.hideInactivePersonnel}
                onChange={handleInactivePersonnelToggle}
              />
            </HStack>
          </FormControl>
        </VStack>
      </HStack>
    );
  };

  React.useEffect(() => {
    if (availableItemsColumnFilters.length) {
      const unfilteredPersonnelIds: number[] =
        // eslint-disable-next-line react/prop-types
        tableInstanceRef?.current?.getFilteredRowModel().rows.map((row) => row.getValue('personnelId')) ?? [];

      setUnfilteredPersonnelIds(unfilteredPersonnelIds);
    }
  }, [availableItemsColumnFilters]);

  React.useEffect(() => {
    if (previewItemsColumnFilters.length) {
      const unfilteredPersonnelIds: number[] =
        // eslint-disable-next-line react/prop-types
        previewTableInstanceRef?.current?.getFilteredRowModel().rows.map((row) => row.getValue('personnelId')) ?? [];

      setUnfilteredPreviewPersonnelIds(unfilteredPersonnelIds);
    }
  }, [previewItemsColumnFilters]);

  const handleOrderPersonnel = (data: PersonnelItemSlim[]) => {
    // Set ordered preview data
    setPreviewData(data);
    // Set order changed (needed to update the view filter on personnel)
    setOrderChanged(true);
  };

  // This is needed to save the color when user changes color and then re-orders items
  React.useMemo(() => {
    if (orderChanged) {
      // Current(selected) personnel
      const personnelFilter = [...filter.on_personnel];

      // Sort current(selected) personnel based on the order the user defines(via drag&drop or with move buttons)
      const sortedPersonnel = customSortArray(personnelFilter, [...selectedIDs], PERSONNEL_FILTER_SORT_KEY);

      const updatedView = produce(view, (draft) => {
        // eslint-disable-next-line camelcase
        draft.filter.on_personnel = sortedPersonnel;
      });

      // Update view with the new personnel(ordered)
      updateView(updatedView);
      // Reset order changed
      setOrderChanged(false);
    }
  }, [previewData, orderChanged, selectedIDs]);

  // Selected preview personnel including text, cell color data and name
  // Used to display the selected item name and the correct colors when opening Color Picker Dialog
  const selectedPreviewItems = React.useMemo(() => {
    // Get selected items from the preview list
    const selected = previewData.filter((item) => selectedPreviewRowPersonnelIds.has(item.id));

    const coloredPersonnelItems = selected.map((item) => {
      // Get the selected personnel colors from the filter data
      const coloredPersonnelItem = filter.on_personnel.find((personnel) => personnel.id === item.id);
      if (coloredPersonnelItem) {
        return {
          ...coloredPersonnelItem,
          name: item.name.display,
        };
      }
    });

    return coloredPersonnelItems as ColoredItem[];
  }, [previewData, selectedPreviewRowPersonnelIds]);

  const handlePreviewTableSorting = () => {
    const isDesc = SortingUtils.getSorting(previewTableSortingState, ViewEditorPersonnelColumns.DISPLAY_NAME);
    SortingUtils.setSorting(ViewEditorPersonnelColumns.DISPLAY_NAME, isDesc, setPreviewTableSortingState);
    // Set order changed (needed to update the view filter on personnel)
    setOrderChanged(true);
  };

  const handleAvailableTableSorting = () => {
    const isDesc = SortingUtils.getSorting(availableTableSortingState, ViewEditorPersonnelColumns.DISPLAY_NAME);
    SortingUtils.setSorting(ViewEditorPersonnelColumns.DISPLAY_NAME, isDesc, setAvailableTableSortingState);
  };

  // ToDo: Handle no personnel found
  return (
    <VStack gap={5} align={'top'}>
      {getTopBar()}
      <PaginatedPreviewTable
        availableItemsTableColumnFilters={availableItemsColumnFilters}
        availableItemsTableColumns={availableItemsColumns}
        availableItemsTableColumnVisibility={{
          autoAddPersonnel: false,
          expired: false,
          personnelId: false,
          personnelTypeId: false,
        }}
        availableItemsTableData={personnelData ?? []}
        availableItemsTableHeader={getAvailableItemsTableHeader()}
        availableItemsTableHeaderButton={getAvailableItemsTableHeaderButton()}
        availableItemsTableInstanceRef={tableInstanceRef}
        availableItemsTableSortingState={availableTableSortingState}
        availableRowUniqueKey={'id'}
        handleSetCellColors={handleSetCellColors}
        headerSortHandlers={[
          {
            columnId: ViewEditorPersonnelColumns.DISPLAY_NAME,
            sortHandler: () => handleAvailableTableSorting(),
          },
        ]}
        hideSortingTable={!isDraggable}
        isLoading={isLoading}
        previewItemsTableInstanceRef={previewTableInstanceRef}
        previewTableColumnFilters={previewItemsColumnFilters}
        previewTableColumns={previewColumns}
        previewTableColumnVisibility={{
          autoAddPersonnel: false,
          expired: false,
          personnelId: false,
          personnelTypeId: false,
        }}
        previewTableData={previewData}
        previewTableHeader={getPreviewTableHeader()}
        previewTableHeaderButton={getPreviewItemsTableHeaderButton()}
        previewHeaderSortHandlers={[
          {
            columnId: ViewEditorPersonnelColumns.DISPLAY_NAME,
            sortHandler: () => handlePreviewTableSorting(),
          },
        ]}
        previewTableSortingState={previewTableSortingState}
        previewRowsAreDraggable={isDraggable}
        previewRowUniqueKey={'id'}
        previewTableDragHandler={(items) => handleOrderPersonnel(items as PersonnelItemSlim[])}
        previewTableUniqueSelectedIds={selectedPreviewRowPersonnelIds}
        sortingFns={{ displayName, lastName }}
        selectedPreviewItems={selectedPreviewItems}
        handleResetSorting={() => setPreviewTableSortingState([])}
      />
    </VStack>
  );
};

export default ViewEditorPersonnel;
