import { Button, HStack, Th } from '@chakra-ui/react';
import React from 'react';

import { DEFAULT_DESELECT_ALL, DEFAULT_SELECT_ALL } from '@/constants/defaults';

interface TableHeaderSelectAllProps {
  handleSelectAll: () => void;
  isDisabled?: boolean;
  selectAllIsToggled?: boolean;
}

const FULL_WIDTH = '100%';

const TableHeaderSelectAll = (props: TableHeaderSelectAllProps): React.JSX.Element => {
  const { handleSelectAll = () => void {}, isDisabled= false,selectAllIsToggled = false } = props;

  return (
    <Th w={FULL_WIDTH}>
      <HStack w={FULL_WIDTH} justifyContent={'flex-end'}>
        <Button size="sm" onClick={handleSelectAll} isDisabled={isDisabled}>
          {selectAllIsToggled ? DEFAULT_DESELECT_ALL : DEFAULT_SELECT_ALL}
        </Button>
      </HStack>
    </Th>
  );
};

export default TableHeaderSelectAll;
