import { CellContext } from '@tanstack/react-table';
import React, { CSSProperties } from 'react';

import APLTableCell from '@/components/available-preview-list/apl-table-cell/APLTableCell';

interface CellFactoryProps<T> {
  cellContext: CellContext<T, unknown>;
  text: string;
  actionIcon?: React.ReactNode;
  className?: string;
  clickHandler?: (id: number) => void;
  style?: CSSProperties;
}

export const cellFactory = <T extends {
  id: number
}, >(cellProps: CellFactoryProps<T>) => {
  // eslint-disable-next-line react/prop-types
  const {
    actionIcon,
    cellContext,
    className,
    clickHandler,
    style,
    text,
  } = cellProps;

  // eslint-disable-next-line react/prop-types
  if (!cellContext.row?.original) return null;

  return (
    <APLTableCell
      className={className}
      icon={actionIcon ?? undefined}
      onClick={clickHandler ? () => clickHandler(cellContext.row.original.id) : undefined}
      style={style ?? {}}
      text={text}
    />
  );
};
