import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';

interface RequestAccessModalProps {
  canOpenInViewer: boolean;
  handleGiveAccess: () => void;
  isGetViewAccessLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onEditAccess: () => void;
  openInViewer: () => void;
}

const RequestAccessModal = (props: RequestAccessModalProps): React.JSX.Element => {
  const { canOpenInViewer, handleGiveAccess, isGetViewAccessLoading, isOpen, onClose, onEditAccess, openInViewer } =
    props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'} fontSize={'18px'}>
          You do not have access to this view.
        </ModalHeader>
        <ModalCloseButton />
        <VStack spacing={5} align={'center'}>
          <ModalBody>
            <Flex mb={6} justify={'center'}>
              How would you like to proceed?
            </Flex>
            <Stack spacing={4} direction={'row'} align={'center'}>
              {canOpenInViewer && (
                <Button size={'sm'} colorScheme={'blue'} onClick={openInViewer} isLoading={isGetViewAccessLoading}>
                  Open In Viewer
                </Button>
              )}
              {!canOpenInViewer && (
                <Button size={'sm'} colorScheme={'blue'} onClick={handleGiveAccess} isLoading={isGetViewAccessLoading}>
                  Give Me Access
                </Button>
              )}
              <Button variant={'outline'} size={'sm'} colorScheme={'blue'} onClick={onEditAccess}>
                Edit Access
              </Button>
              <Button size={'sm'} colorScheme={'red'} onClick={onClose} variant={'outline'}>
                Cancel
              </Button>
            </Stack>
          </ModalBody>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default RequestAccessModal;
