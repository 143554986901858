import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Skeleton,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import _ from 'lodash';
import React from 'react';

import { useCreateFilterMutation, useCreateThemeMutation, useCreateViewMutation } from '@/API/views.api';
import { ViewNameFieldProps } from '@/components/view-editor/ViewEditorBasicProperties';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_TOAST_DURATION, ToastTypes } from '@/constants/defaults';
import { ApiError } from '@/types/api.types';
import View, { ViewFilter } from '@/types/view.types';
import viewNameSchema from '@/utils/validationSchemas/viewName.validation.schema';

interface CloneViewModalProps {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  openView: (viewId: number) => void;
  view: View;
}

const CLONE_SUCCESS_MESSAGE = 'View cloned successfully.';

const CloneViewModal = (props: CloneViewModalProps): React.JSX.Element => {
  const { isLoading, isOpen, onClose, openView, view } = props;

  const [createFilter, createFilterResponse] = useCreateFilterMutation();
  const [createTheme, createThemeResponse] = useCreateThemeMutation();
  const [createView, createViewResponse] = useCreateViewMutation();

  const toast = useToast();

  const formikRef = React.useRef<FormikProps<FormikValues>>(null);

  const handleClone = async () => {
    if (!view.filter || !view.theme) return;

    try {
      const createFilterResponse = await createFilter({
        filterData: _.omit(view.filter, [
          'filter_id',
          'name',
          'created_at',
          'created_by',
          'updated_at',
          'updated_by',
        ]) as ViewFilter,
        filterName: `${formikRef.current?.values.viewName} Filter`,
      }).unwrap();

      const createThemeResponse = await createTheme({
        themeData: view.theme.data,
        themeName: `${formikRef.current?.values.viewName} Theme`,
      }).unwrap();

      const createViewResponse = await createView({
        ...view,
        filterId: createFilterResponse?.filter_id,
        name: formikRef.current?.values.viewName,
        themeId: createThemeResponse?.theme_id,
      }).unwrap();

      if (createViewResponse.view_id) {
        toast({
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          position: 'top',
          status: ToastTypes.SUCCESS,
          title: CLONE_SUCCESS_MESSAGE,
        });
        return createViewResponse;
      }
    } catch (error) {
      // Cast unknown error type to ApiError
      const apiError = error as ApiError;
      const message = apiError?.data?.Message ?? DEFAULT_ERROR_MESSAGE;

      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: message,
      });
    } finally {
      // Close the modal
      onClose();
    }
  };

  // Clone the selected view, close the modal and open view in edit mode
  const handleCloneAndEdit = async () => {
    const createdVew = await handleClone();
    if (createdVew) {
      openView(createdVew.view_id);
    }
  };

  const handleClose = () => {
    onClose();
  };

  if (!view) return <></>;

  const isButtonLoading =
    createFilterResponse.isLoading || createThemeResponse.isLoading || createViewResponse.isLoading;

  // ToDo: Resolve type issues with Formik
  return (
    <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'} fontSize={'18px'}>
          {isLoading ? (
            <Skeleton h={'38px'} w={'100%'} startColor="blue.300" endColor="blue.500" />
          ) : (
            `Clone ${view?.name}`
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Progress size="xs" isIndeterminate />
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={{ viewName: `${view?.name ?? ''}(clone)` }}
              onSubmit={() => {
                return;
              }}
              innerRef={formikRef}
              validationSchema={viewNameSchema}
            >
              {({ errors }) => (
                <Form>
                  <Stack align={'center'} spacing={8} w={'100%'} paddingBottom={'20px'}>
                    <Field name="viewName">
                      {({ field, form }: ViewNameFieldProps) => (
                        <FormControl
                          isRequired
                          w={'100%'}
                          isInvalid={(form.errors.viewName && form.touched.viewName) as boolean}
                        >
                          <FormLabel>New View Name</FormLabel>
                          <Input
                            {...field}
                            type={'text'}
                            placeholder={'Enter a unique view name'}
                            value={field.value}
                          ></Input>
                          <FormErrorMessage>{form.errors.viewName}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Stack spacing={4} direction={'row'} align={'center'}>
                      <Button
                        size={'sm'}
                        colorScheme={'blue'}
                        onClick={handleClone}
                        isLoading={isButtonLoading}
                        isDisabled={!!errors.viewName?.length}
                      >
                        Clone View
                      </Button>

                      <Button
                        size={'sm'}
                        colorScheme={'blue'}
                        onClick={handleCloneAndEdit}
                        variant={'outline'}
                        isLoading={isButtonLoading}
                        isDisabled={!!errors.viewName?.length}
                      >
                        Clone and Edit
                      </Button>
                      <Button size={'sm'} colorScheme={'red'} onClick={handleClose} variant={'outline'}>
                        Cancel
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CloneViewModal;
