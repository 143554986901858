import { Box, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import { ColumnDef, ColumnFiltersState, SortingFn, SortingState, Table, VisibilityState } from '@tanstack/react-table';
import React, { ReactNode } from 'react';

import ColorPickerDialog from '@/components/color-picker-dialog/ColorPickerDialog';
import PaginatedTable from '@/components/paginated-table/PaginatedTable';
import {
  DEFAULT_ARRAY_START_INDEX,
  DEFAULT_ONE_ELEMENT_ARRAY_LENGTH,
  DEFAULT_TABLE_WIDTH_MD,
} from '@/constants/defaults';
import { CellColor, ColoredItem, ColorMode, ColorPickerState, HeaderSortHandlers } from '@/types/ui.types';

export type CellColors = Omit<ColorPickerState, 'colorMode'>;

interface PaginatedPreviewTableProps {
  availableItemsTableColumnFilters: ColumnFiltersState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availableItemsTableColumns: ColumnDef<any, any>[];
  availableItemsTableData: unknown[];
  availableRowUniqueKey: string;
  isLoading: boolean;
  previewRowUniqueKey: string;
  previewTableColumnFilters: ColumnFiltersState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previewTableColumns: ColumnDef<any, any>[];
  previewTableData: unknown[];
  availableItemsTableColumnVisibility?: VisibilityState;
  availableItemsTableHeader?: ReactNode;
  availableItemsTableHeaderButton?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availableItemsTableInstanceRef?: React.MutableRefObject<Table<any> | undefined>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previewItemsTableInstanceRef?: React.MutableRefObject<Table<any> | undefined>;
  availableItemsTableSortingState?: SortingState;
  headerSortHandlers?: HeaderSortHandlers;
  hideSortingTable?: boolean;
  handleSetCellColors?: (colors: CellColors) => void;
  handleResetSorting?: () => void;
  loadingMessage?: string;
  previewHeaderSortHandlers?: HeaderSortHandlers;
  previewRowsAreDraggable?: boolean;
  previewTableColumnVisibility?: VisibilityState;
  previewTableDragHandler?: (updatedData: unknown[]) => void;
  previewTableHeader?: ReactNode;
  previewTableHeaderButton?: ReactNode;
  previewTableNoDataFoundMessage?: string;
  previewTableSortingState?: SortingState;
  previewTableUniqueSelectedIds?: Set<number | string>;
  showColorPicker?: boolean;
  showSideControls?: boolean;
  selectedPreviewItems?: ColoredItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortingFns?: Record<string, SortingFn<any>>;
}

const DEFAULT_COLOR_PICKER_STATE: ColorPickerState = {
  cellColor: '#ffffff',
  colorMode: ColorMode.Cell,
  textColor: '#ffffff',
};

const PaginatedPreviewTable = (props: PaginatedPreviewTableProps): React.JSX.Element => {
  const {
    availableItemsTableColumnFilters,
    availableItemsTableColumns,
    availableItemsTableData,
    availableRowUniqueKey,
    isLoading,
    previewTableColumnFilters,
    previewTableColumns,
    previewTableData,
    availableItemsTableColumnVisibility = undefined,
    availableItemsTableHeader = null,
    availableItemsTableHeaderButton = null,
    availableItemsTableInstanceRef = undefined,
    previewItemsTableInstanceRef = undefined,
    availableItemsTableSortingState,
    headerSortHandlers,
    hideSortingTable,
    handleSetCellColors = () => void {},
    handleResetSorting = () => void {},
    loadingMessage = 'Loading...',
    previewHeaderSortHandlers,
    previewRowsAreDraggable = false,
    previewRowUniqueKey,
    previewTableColumnVisibility = undefined,
    previewTableDragHandler = undefined,
    previewTableHeader = null,
    previewTableHeaderButton = null,
    previewTableNoDataFoundMessage = undefined,
    previewTableSortingState,
    previewTableUniqueSelectedIds = new Set<number>(),
    selectedPreviewItems = undefined,
    showColorPicker = true,
    showSideControls = true,
    sortingFns = undefined,
  } = props;

  const [colorPickerIsOpen, setColorPickerIsOpen] = React.useState<boolean>(false);
  const [colorPickerState, setColorPickerState] = React.useState<ColorPickerState>(DEFAULT_COLOR_PICKER_STATE);
  const [colorPickerHeaderText, setColorPickerHeaderText] = React.useState<string>('');

  // This is needed to display the correct colors and header text when opening Color Picker Dialog
  React.useMemo(() => {
    if (selectedPreviewItems) {
      // If the user selects just 1 item, set the colors and the name of that item on the Color Picker Dialog
      if (selectedPreviewItems.length === DEFAULT_ONE_ELEMENT_ARRAY_LENGTH) {
        const selectedItemColors: CellColor = {
          cellColor: selectedPreviewItems[DEFAULT_ARRAY_START_INDEX].color ?? DEFAULT_COLOR_PICKER_STATE.cellColor,
          textColor: selectedPreviewItems[DEFAULT_ARRAY_START_INDEX].colorText ?? DEFAULT_COLOR_PICKER_STATE.textColor,
        };
        const headerText = selectedPreviewItems[DEFAULT_ARRAY_START_INDEX].name;
        setColorPickerState((prev) => ({ ...prev, ...selectedItemColors }));
        setColorPickerHeaderText(headerText);
      } else {
        setColorPickerState((prev) => ({ ...prev, ...DEFAULT_COLOR_PICKER_STATE }));

        // Display the selected item name or the number of the selected items on Color Picker Dialog header
        setColorPickerHeaderText(`${selectedPreviewItems.length} Selected`);
      }
    }
  }, [selectedPreviewItems]);

  const handleConfirmColors = () => {
    setColorPickerIsOpen(false);

    const { cellColor, textColor } = colorPickerState;

    handleSetCellColors({
      cellColor,
      textColor,
    });
  };

  const clearColor = () => {
    colorPickerState.colorMode === ColorMode.Cell
      ? setColorPickerState((prev) => ({
          ...prev,
          cellColor: '',
        }))
      : setColorPickerState((prev) => ({
          ...prev,
          textColor: '',
        }));
  };

  const handleColorPicker = () => {
    setColorPickerIsOpen(true);
  };

  const handleQuickColorPicker = (color: CellColor) => {
    handleSetCellColors(color as CellColors);
  };

  return (
    <Box>
      {colorPickerIsOpen && (
        <ColorPickerDialog
          isOpen={colorPickerIsOpen}
          onClose={() => setColorPickerIsOpen(false)}
          colorPickerState={colorPickerState}
          onConfirm={handleConfirmColors}
          onClear={clearColor}
          onDiscard={() => setColorPickerIsOpen(false)}
          onSelectColor={(colorPickerState) => setColorPickerState(colorPickerState)}
          headerText={colorPickerHeaderText}
        />
      )}
      <VStack align={'left'}>
        <Box>
          <HStack minW={`${DEFAULT_TABLE_WIDTH_MD}px`}>
            {isLoading ? (
              <VStack w={'100%'}>
                <Text textAlign={'center'}>{loadingMessage}</Text>
                <Spinner />
              </VStack>
            ) : (
              <HStack justifyContent={'space-between'} align={'top'} w={'100%'} gap={4}>
                <PaginatedTable
                  columns={availableItemsTableColumns}
                  columnFilters={availableItemsTableColumnFilters}
                  data={availableItemsTableData}
                  headerSortHandlers={headerSortHandlers}
                  TableHeader={availableItemsTableHeader}
                  TableHeaderButton={availableItemsTableHeaderButton}
                  tableInstanceRef={availableItemsTableInstanceRef}
                  columnVisibility={availableItemsTableColumnVisibility}
                  sortingState={availableItemsTableSortingState}
                  uniqueRowKey={availableRowUniqueKey}
                />

                <PaginatedTable
                  columns={previewTableColumns}
                  columnFilters={previewTableColumnFilters}
                  data={previewTableData}
                  TableHeader={previewTableHeader}
                  TableHeaderButton={previewTableHeaderButton}
                  columnVisibility={previewTableColumnVisibility}
                  noDataFoundMessage={previewTableNoDataFoundMessage}
                  draggableRows={previewRowsAreDraggable}
                  headerSortHandlers={previewHeaderSortHandlers}
                  hideSortingTable={hideSortingTable}
                  handleUpdateOrder={previewTableDragHandler}
                  sortingFns={sortingFns}
                  sortingState={previewTableSortingState}
                  uniqueSelectedIds={previewTableUniqueSelectedIds}
                  uniqueRowKey={previewRowUniqueKey}
                  showSideControls={showSideControls}
                  showColorPicker={showColorPicker}
                  handleColorPicker={handleColorPicker}
                  handleQuickColorPicker={handleQuickColorPicker}
                  tableInstanceRef={previewItemsTableInstanceRef}
                  handleResetSorting={handleResetSorting}
                />
              </HStack>
            )}
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default PaginatedPreviewTable;
