import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Spinner,
  Tab,
  TabList,
  Tabs,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { HttpStatusCode } from 'axios';
import _ from 'lodash';
import React from 'react';
import { useImmer } from 'use-immer';

import {
  useGetPersonnelViewMembershipAndAccessQuery,
  useUpdatePersonnelViewMembershipAndAccessMutation,
} from '@/API/personnel.api';
import DepartmentsDropdown from '@/components/departments-dropdown/DepartmentsDropdown';
import PersonnelViewAccess from '@/components/personnel-membership-drawer/PersonnelViewAccess';
import PersonnelViewMembership from '@/components/personnel-membership-drawer/PersonnelViewMembership';
import PaginatedTableSkeleton from '@/components/skeletons/paginated-table-skeleton/PaginatedTableSkeleton';
import TemplatesDropdown from '@/components/templates-dropdown/TemplatesDropdown';
import { DEFAULT_PERSONNEL_ID, DEFAULT_TOAST_DURATION, DEFAULT_Z_INDEX, ToastTypes } from '@/constants/defaults';
import useDepartments from '@/hooks/useDepartments';
import { ApiError } from '@/types/api.types';
import { PersonnelViewAccessBody } from '@/types/personnel.types';

enum PersonnelViewMembershipAccessTabs {
  ACCESS,
  MEMBERSHIP,
}

interface PersonnelMembershipDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  personnelId: number | undefined;
}

interface UIState {
  departmentListIsClosed: boolean;
  isLoading: boolean;
  personnelViewMembershipIsLoading: boolean;
  prevSelectedDepartmentIds: number[],
  prevSelectedTemplateIds: number[],
  selectedTabIndex: PersonnelViewMembershipAccessTabs,
  templateListIsClosed: boolean,
  viewsListIsLoading: boolean,
}

const DEFAULT_UI_STATE: UIState = {
  departmentListIsClosed: true,
  isLoading: true,
  personnelViewMembershipIsLoading: false,
  prevSelectedDepartmentIds: [],
  prevSelectedTemplateIds: [],
  selectedTabIndex: PersonnelViewMembershipAccessTabs.ACCESS,
  templateListIsClosed: true,
  viewsListIsLoading: false,
};

const selectedTabStyle = {
  background: 'blue.500',
  borderRadius: '10px',
  color: 'white',
};

const defaultPersonnelViewAccess: PersonnelViewAccessBody = {
  filterIds: [],
  id: DEFAULT_PERSONNEL_ID,
  viewIds: [],
};

const PERSONNEL_ACCESS_SUCCESS_MESSAGE = 'Personnel access updated successfully.';
const PERSONNEL_ACCESS_ERROR_MESSAGE = 'Error updating personnel access.';

const PersonnelMembershipDrawer = (props: PersonnelMembershipDrawerProps) => {
  const {
    isOpen,
    onClose,
    personnelId = DEFAULT_PERSONNEL_ID,
  } = props;

  const [UIState, updateUIState] = useImmer<UIState>(DEFAULT_UI_STATE);

  const {
    data: personnelViewMembershipData,
    isFetching: personnelViewMembershipIsFetching,
    isLoading: personnelViewMembershipIsLoading,
    status: personnelViewMembershipStatus,
  } = useGetPersonnelViewMembershipAndAccessQuery(personnelId, {
    selectFromResult: ({ data, isLoading, isFetching, isError, status }) => ({
      data: data ?? {
        accessibleViews: [],
        id: DEFAULT_PERSONNEL_ID,
        // eslint-disable-next-line camelcase
        is_staff: false,
        memberOfFilters: [],
        memberOfViews: [],
        metadata: {},
        name: { display: '', first: '', last: '' },
        views: 0,
      },
      isError,
      isFetching,
      isLoading,
      status,
    }),
    // eslint-disable-next-line no-magic-numbers
    skip: !personnelId || personnelId === DEFAULT_PERSONNEL_ID || !isOpen,
  });

  const { accessibleViews, name, memberOfViews } = personnelViewMembershipData;

  const {
    departments,
    templatesForSelectedDepartments,
    selectedDepartmentIds,
    selectedTemplateIds,
    setSelectedDepartmentIds,
    setSelectedTemplateIds,
  } = useDepartments();

  const [updatePersonnelViewAccess, { isLoading: isUpdatingPersonnelViewAccess }] =
    useUpdatePersonnelViewMembershipAndAccessMutation();

  const [currentPersonnelViewAccess, setCurrentPersonnelViewAccess] =
    React.useState<PersonnelViewAccessBody>(defaultPersonnelViewAccess);
  const [personnelViewAccessDraft, updatePersonnelViewAccessDraft] =
    useImmer<PersonnelViewAccessBody>(defaultPersonnelViewAccess);

  const toast = useToast();

  React.useEffect(() => {
    updateUIState((draft) => {
      draft.isLoading = personnelViewMembershipStatus !== 'fulfilled';
    });
  }, [personnelViewMembershipStatus, updateUIState]);

  React.useEffect(() => {
    // eslint-disable-next-line no-magic-numbers
    if (personnelViewMembershipData.id === -1) return;

    const initialPersonnelViewAccess: PersonnelViewAccessBody = {
      filterIds: memberOfViews ?? [],
      // eslint-disable-next-line no-magic-numbers
      id: personnelId ?? -1,
      viewIds: accessibleViews ?? [],
    };

    setCurrentPersonnelViewAccess(initialPersonnelViewAccess);
    updatePersonnelViewAccessDraft(initialPersonnelViewAccess);
  }, [accessibleViews, memberOfViews, personnelId, personnelViewMembershipData]);

  if (!isOpen) {
    return null;
  }

  const handleClose = () => {
    updateUIState(DEFAULT_UI_STATE);
    setSelectedTemplateIds([]);
    setSelectedDepartmentIds([]);

    onClose();
  };

  const getTopBar = (): React.JSX.Element => {
    return (
      <VStack zIndex={DEFAULT_Z_INDEX} align={'left'} justifyContent={'space-between'} gap={5} mb={1}>
        <Tabs
          variant={'unstyled'}
          position={'relative'}
          index={UIState.selectedTabIndex}
          onChange={(index) => updateUIState((draft) => {
            draft.selectedTabIndex = index;
          })}
          isLazy
        >
          <TabList>
            <Tab _selected={selectedTabStyle}>View Access</Tab>
            <Tab _selected={selectedTabStyle}>View Membership</Tab>
          </TabList>
        </Tabs>
        <HStack spacing="24px" alignItems="flex-end">
          <DepartmentsDropdown
            departmentChangeHandler={setSelectedDepartmentIds}
            departmentList={departments}
            selectedIds={selectedDepartmentIds}
            departmentListCloseHandler={() => updateUIState((draft) => {
              draft.departmentListIsClosed = true;
              draft.prevSelectedDepartmentIds = selectedDepartmentIds;
            })}
            departmentListOpenHandler={() => updateUIState((draft) => {
              draft.departmentListIsClosed = false;
            })}
            isLoading={UIState.viewsListIsLoading}
          />
          <TemplatesDropdown
            selectedIds={selectedTemplateIds}
            templateChangeHandler={setSelectedTemplateIds}
            templateList={templatesForSelectedDepartments}
            templateListCloseHandler={() => updateUIState((draft) => {
              draft.templateListIsClosed = true;
              draft.prevSelectedTemplateIds = selectedTemplateIds;
            })}
            templateListOpenHandler={() => updateUIState((draft) => {
              draft.templateListIsClosed = false;
            })}
          />
        </HStack>
      </VStack>
    );
  };

  const accessHasChanged = JSON.stringify(currentPersonnelViewAccess) !== JSON.stringify(personnelViewAccessDraft);

  const handleUpdateEmployeeAccess = async () => {
    // Nothing changed - short circuit
    if (!accessHasChanged) return;

    try {
      const personnelAccessResponse = await updatePersonnelViewAccess(personnelViewAccessDraft).unwrap();

      if (personnelAccessResponse.status === HttpStatusCode.NoContent) {
        toast({
          description: 'Personnel access updated successfully',
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          position: 'top',
          status: ToastTypes.SUCCESS,
          title: PERSONNEL_ACCESS_SUCCESS_MESSAGE,
        });
      }
    } catch (error) {
      // Cast unknown error type to ApiError
      const apiError = error as ApiError;
      const message = apiError?.data?.Message ?? PERSONNEL_ACCESS_ERROR_MESSAGE;

      toast({
        description: 'Error updating personnel access - Network Error',
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: message,
      });
    }
  };

  const getDrawerBody = (): React.JSX.Element => {
    if (personnelViewMembershipIsLoading) {
      return <PaginatedTableSkeleton />;
    }

    return (
      <>
        <PersonnelViewAccess
          currentPersonnelViewAccess={personnelViewAccessDraft}
          employeeName={personnelViewMembershipData.name.display}
          isOpen={UIState.selectedTabIndex === PersonnelViewMembershipAccessTabs.ACCESS}
          selectedDepartments={UIState.prevSelectedDepartmentIds}
          selectedTemplates={UIState.prevSelectedTemplateIds}
          updateEmployeeAccess={updatePersonnelViewAccessDraft}
        />

        <PersonnelViewMembership
          currentPersonnelViewAccess={personnelViewAccessDraft}
          employeeName={personnelViewMembershipData.name.display}
          isOpen={UIState.selectedTabIndex === PersonnelViewMembershipAccessTabs.MEMBERSHIP}
          selectedDepartments={UIState.prevSelectedDepartmentIds}
          selectedTemplates={UIState.prevSelectedTemplateIds}
          updateEmployeeAccess={updatePersonnelViewAccessDraft}
        />
      </>
    );
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement={'right'}
      onClose={handleClose}
      preserveScrollBarGap={false}
      blockScrollOnMount={true}
      returnFocusOnClose={false}
    >
      <DrawerOverlay />
      <DrawerContent maxW={'1300px'}>
        <Box overflowY={'auto'}>
          <DrawerCloseButton />
          <DrawerHeader>{`Editing view ${_.lowerCase(PersonnelViewMembershipAccessTabs[UIState.selectedTabIndex])} for ${
            name.display
          }`}</DrawerHeader>
          <DrawerBody overflow={'unset'}>
            <VStack align={'top'}>
              {getTopBar()}
              {UIState.isLoading ? <Spinner>Loading</Spinner> : getDrawerBody()}
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <ButtonGroup>
              <Button colorScheme={'blue'} onClick={handleUpdateEmployeeAccess}>
                Save
              </Button>
              <Button colorScheme={'blue'} variant={'ghost'} onClick={() => handleClose()}>
                Exit
              </Button>
            </ButtonGroup>
          </DrawerFooter>
        </Box>
      </DrawerContent>
    </Drawer>
  );
};

export default PersonnelMembershipDrawer;
