import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spacer,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { FaFileAlt, FaPlus, FaServer, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { useDeleteViewMutation, useGetPublicViewsQuery, useGetViewQuery } from '@/API/views.api';
import ActionDialog from '@/components/action-dialog/ActionDialog';
import ControlledSearch from '@/components/controlled-search/ControlledSearch';
import DepartmentsDropdown from '@/components/departments-dropdown/DepartmentsDropdown';
import TemplatesDropdown from '@/components/templates-dropdown/TemplatesDropdown';
import ViewAccessDrawer from '@/components/view-access-drawer/ViewAccessDrawer';
import ViewEditorDrawer, { ViewEditorDrawerMode } from '@/components/view-editor-drawer/ViewEditorDrawer';
import UIConfig from '@/config/ui.config';
import { ROUTES } from '@/constants/config';
import { DEFAULT_HEADER_Z_INDEX, DEFAULT_TOAST_DURATION, ToastTypes } from '@/constants/defaults';
import useDepartments from '@/hooks/useDepartments';
import CloneViewModal from '@/modals/clone-view-modal/CloneView.modal';
import ConfirmActionModal from '@/modals/confirm-action-modal/ConfirmAction.modal';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';
import { ApiError } from '@/types/api.types';
import View from '@/types/view.types';
import { openViewInNewTab } from '@/utils/url';
import ViewsListView from '@/views/view-list/ViewsList.view';

interface ViewEditorDrawerState {
  isOpen: boolean;
  mode: ViewEditorDrawerMode;
}

interface UIState {
  isViewAccessDrawerOpen: boolean;
  selectedViewId: number;
  isCloneViewModalOpen: boolean;
  isDeleteViewModalOpen: boolean;
  viewEditorDrawerState: ViewEditorDrawerState;
  isDepartmentDropdownOpen: boolean;
  isTemplateDropdownOpen: boolean;
  showActionDialog: boolean;
}

const initialState: UIState = {
  isCloneViewModalOpen: false,
  isDeleteViewModalOpen: false,
  isDepartmentDropdownOpen: false,
  isTemplateDropdownOpen: false,
  isViewAccessDrawerOpen: false,
  selectedViewId: NaN,
  showActionDialog: false,
  viewEditorDrawerState: {
    isOpen: false,
    mode: ViewEditorDrawerMode.EDIT,
  },
};

const VIEW_DELETED_SUCCESS_MESSAGE = 'View deleted successfully.';
const VIEW_DELETED_ERROR_MESSAGE = 'View could not be deleted.';

const ViewsPage = (): React.JSX.Element => {
  const [deleteView, deleteViewResponse] = useDeleteViewMutation();
  const {
    data: publicViewsData,
    isLoading: publicViewsIsLoading,
    isFetching: publicViewsIsFetching,
  } = useGetPublicViewsQuery();

  const {
    departments,
    isFetching,
    isLoading,
    selectedDepartmentIds,
    selectedTemplateIds,
    setSelectedDepartmentIds,
    setSelectedTemplateIds,
    templatesForSelectedDepartments,
  } = useDepartments();

  const [viewNameSearchValue, setViewNameSearchValue] = React.useState('');
  const [viewNameSearchFilter, setViewNameSearchFilter] = React.useState('');

  const [uiState, setUiState] = React.useState<UIState>(initialState);

  const {
    isCloneViewModalOpen,
    isDeleteViewModalOpen,
    isDepartmentDropdownOpen,
    isTemplateDropdownOpen,
    isViewAccessDrawerOpen,
    selectedViewId,
    showActionDialog,
    viewEditorDrawerState,
  } = uiState;

  const {
    data: viewData,
    isFetching: getViewFetching,
    isLoading: getViewLoading,
  } = useGetViewQuery(selectedViewId, { skip: !selectedViewId });

  const toast = useToast();

  const [selectedViewData, setSelectedViewData] = React.useState<View | undefined>(viewData);

  React.useEffect(() => {
    if (!viewData) return;

    const publicViewId = publicViewsData?.find((view) => view.viewId === selectedViewId)?.guid ?? '';
    setSelectedViewData({ ...viewData, publicViewId });
  }, [viewData]);

  React.useEffect(() => {
    if (!publicViewsData || !selectedViewData) return;

    const publicViewId = publicViewsData.find((view) => view.viewId === selectedViewId)?.guid ?? '';
    setSelectedViewData({ ...selectedViewData, publicViewId });
  }, [publicViewsData]);

  // Since RTK Query caches the view data response and is triggered by the selectedViewId,
  // we need to set the selected view data to the cached response when the selectedViewId changes from NaN to a valid id
  // if the selectedViewId is not the same as the cached response, then RTK Query will fetch the new data which will
  // prevent this from being triggered
  React.useMemo(() => {
    if (!getViewLoading || !getViewFetching) {
      setSelectedViewData(viewData);
    }
  }, [selectedViewId]);

  const debouncedSearch = React.useCallback(
    _.debounce((value: string) => setViewNameSearchFilter(value), UIConfig.DEFAULT_DEBOUNCE_TIME_MS),
    [],
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
    setViewNameSearchValue(e.target.value);
  };

  const getLoadingViewModal = (): JSX.Element => {
    return (
      <Modal isOpen={getViewLoading || getViewFetching} onClose={() => undefined} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Loading View Data</ModalHeader>
          <ModalBody>
            <HStack w={'100%'} h={'80px'} justifyContent={'space-between'}>
              <FaServer size={'30px'} />
              <Progress size="xs" isIndeterminate w={'90%'} />
              <FaFileAlt size={'30px'} />
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  // Open view edit drawer on edit mode from Clone View Dialog and close the dialog after click
  const handleOpenView = React.useCallback(
    (viewId: number) => {
      setUiState((prevState) => {
        return {
          ...prevState,
          selectedViewId: viewId,
          viewEditorDrawerState: {
            isOpen: true,
            mode: ViewEditorDrawerMode.EDIT,
          },
        };
      });
    },
    [viewEditorDrawerState, showActionDialog],
  );

  const isDataLoading =
    isLoading || isFetching || publicViewsIsLoading || publicViewsIsFetching || getViewLoading || getViewFetching;

  const cloneViewModal = React.useMemo(() => {
    if (getViewLoading || getViewFetching) return getLoadingViewModal();

    return (
      <CloneViewModal
        isLoading={isDataLoading}
        isOpen={isCloneViewModalOpen}
        openView={handleOpenView}
        onClose={() => {
          setUiState((prevState) => {
            return { ...prevState, isCloneViewModalOpen: false, selectedViewId: NaN };
          });
          setSelectedViewData(undefined);
        }}
        view={selectedViewData ?? ({} as View)}
      />
    );
  }, [isCloneViewModalOpen, selectedViewData, isDataLoading]);

  const handleCancelDeleteView = () => {
    setUiState((prevState) => {
      return { ...prevState, isDeleteViewModalOpen: false, selectedViewId: NaN };
    });

    setSelectedViewData(undefined);
  };

  const handleDeleteView = async () => {
    try {
      const response = await deleteView(selectedViewId).unwrap();

      // Check if the view was deleted successfully
      if (response?.deleted) {
        toast({
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          position: 'top',
          status: ToastTypes.SUCCESS,
          title: VIEW_DELETED_SUCCESS_MESSAGE,
        });
      }
    } catch (error) {
      // Cast unknown error type to ApiError
      const apiError = error as ApiError;
      const message = apiError?.data?.Message ?? VIEW_DELETED_ERROR_MESSAGE;

      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: message,
      });
    } finally {
      handleCancelDeleteView();
    }
  };

  const getConfirmDeleteViewModalBody = () => {
    return (
      <VStack>
        <Text align={'center'}>
          This will <span color={'red'}>DELETE</span> the <span color={'blue'}>{selectedViewData?.name}</span> view.
        </Text>
        <br></br>
        <Text>Are you sure you want to delete this view?</Text>
      </VStack>
    );
  };

  const confirmDeleteViewModal = React.useMemo(() => {
    if (getViewLoading || getViewFetching) return getLoadingViewModal();

    return (
      <ConfirmActionModal
        body={getConfirmDeleteViewModalBody()}
        confirmAction={handleDeleteView}
        header={'Confirm Delete View'}
        cancelAction={handleCancelDeleteView}
        confirmActionButtonText={'Delete'}
        isLoading={deleteViewResponse?.isLoading}
        isOpen={isDeleteViewModalOpen}
      />
    );
  }, [isDeleteViewModalOpen, selectedViewData, isDataLoading, deleteViewResponse, deleteView]);

  // Close editor drawer but preserve the mode, open the action dialog and retrieve view's data
  const handleSaveView = (viewId: number) => {
    setUiState((prevState) => {
      return {
        ...prevState,
        selectedViewId: viewId,
        showActionDialog: true,
        viewEditorDrawerState: { ...prevState.viewEditorDrawerState, isOpen: false },
      };
    });
  };

  const handleViewEditorDrawerClose = () => {
    setUiState((prevState) => {
      return {
        ...prevState,
        selectedViewId: NaN,
        viewEditorDrawerState: { isOpen: false, mode: ViewEditorDrawerMode.EDIT },
      };
    });

    setSelectedViewData(undefined);
  };

  const getViewAccessDrawer = React.useMemo(() => {
    if (getViewLoading || getViewFetching) return getLoadingViewModal();
    if (!isViewAccessDrawerOpen || !selectedViewData || !Object.keys(selectedViewData).length) return <></>;

    return (
      <ViewAccessDrawer
        departments={departments}
        handleClose={() => {
          setUiState((prevState) => {
            return { ...prevState, isViewAccessDrawerOpen: false, selectedViewId: NaN };
          });
          setSelectedViewData(undefined);
        }}
        isLoading={isDataLoading}
        isOpen={isViewAccessDrawerOpen}
        view={selectedViewData}
      />
    );
  }, [isViewAccessDrawerOpen, selectedViewData, selectedViewId]);

  const getViewEditorDrawer = React.useMemo(() => {
    if (getViewLoading || getViewFetching) return getLoadingViewModal();

    const isEditMode = viewEditorDrawerState.mode === ViewEditorDrawerMode.EDIT;

    if (
      !viewEditorDrawerState.isOpen ||
      (!selectedViewData && isEditMode) ||
      (selectedViewData && !Object.keys(selectedViewData).length && isEditMode)
    )
      return <></>;

    return (
      <ViewEditorDrawer
        isLoading={getViewLoading || getViewFetching}
        isOpen={viewEditorDrawerState.isOpen}
        mode={viewEditorDrawerState.mode}
        onClose={handleViewEditorDrawerClose}
        onSave={handleSaveView}
        view={selectedViewData}
      />
    );
  }, [viewEditorDrawerState, selectedViewData]);

  const departmentDropdownSelectedIds = React.useMemo(() => {
    return selectedDepartmentIds;
  }, [isDepartmentDropdownOpen]);

  const templatesDropdownSelectedIds = React.useMemo(() => {
    return selectedTemplateIds;
  }, [isTemplateDropdownOpen]);

  const handleCreateView = React.useCallback(() => {
    setUiState((prevState) => {
      return {
        ...prevState,
        viewEditorDrawerState: { isOpen: true, mode: ViewEditorDrawerMode.CREATE },
      };
    });
  }, [viewEditorDrawerState]);

  // Open view access drawer from Action Dialog and close the dialog after click
  const handleDialogAccessDrawer = React.useCallback(() => {
    setUiState((prevState) => {
      return {
        ...prevState,
        isViewAccessDrawerOpen: true,
        showActionDialog: false,
        viewEditorDrawerState: { isOpen: false, mode: ViewEditorDrawerMode.EDIT },
      };
    });
  }, [viewEditorDrawerState, isViewAccessDrawerOpen, showActionDialog]);

  // Open view edit drawer on create mode from Action Dialog and close the dialog after click
  const handleDialogCreateView = React.useCallback(() => {
    setSelectedViewData(undefined);

    setUiState((prevState) => {
      return {
        ...prevState,
        selectedViewId: NaN,
        showActionDialog: false,
        viewEditorDrawerState: { isOpen: true, mode: ViewEditorDrawerMode.CREATE },
      };
    });
  }, [viewEditorDrawerState, showActionDialog]);

  // Open view edit drawer on edit mode from Action Dialog and close the dialog after click
  const handleDialogEditView = React.useCallback(() => {
    setUiState((prevState) => {
      return {
        ...prevState,
        showActionDialog: false,
        viewEditorDrawerState: {
          isOpen: true,
          mode: ViewEditorDrawerMode.EDIT,
        },
      };
    });
  }, [viewEditorDrawerState, showActionDialog]);

  // Just close the dialog, the Views List data is already retrieved
  const handleDialogViewList = React.useCallback(() => {
    setUiState((prevState) => {
      return {
        ...prevState,
        showActionDialog: false,
      };
    });
  }, [showActionDialog]);

  // Open the view in viewer app and close the dialog after click
  const handleDialogOpenViewer = React.useCallback(() => {
    if (selectedViewId) {
      openViewInNewTab(selectedViewId);
    }

    setUiState((prevState) => {
      return {
        ...prevState,
        showActionDialog: false,
      };
    });
  }, [showActionDialog, selectedViewId]);

  // Action Dialog after successful view save (edit/create)
  const getActionDialog = () => {
    if (selectedViewData) {
      return (
        <ActionDialog
          mode={viewEditorDrawerState.mode}
          viewName={selectedViewData?.name || ''}
          isOpen={showActionDialog}
          handleClose={() =>
            setUiState((prevState) => {
              return {
                ...prevState,
                showActionDialog: false,
              };
            })
          }
          handleCreateView={handleDialogCreateView}
          handleEditView={handleDialogEditView}
          handleOpenViewer={handleDialogOpenViewer}
          handleViewAccess={handleDialogAccessDrawer}
          handleViewsList={handleDialogViewList}
        />
      );
    }

    return <></>;
  };

  return (
    <div data-testid={ELEMENT_DATA_TEST_IDS.VIEWS_PAGE}>
      {getActionDialog()}
      <Box pos={'absolute'} zIndex={100}>
        {cloneViewModal}
        {confirmDeleteViewModal}
        {getViewAccessDrawer}
        {getViewEditorDrawer}
      </Box>
      <Box>
        <Stack>
          <Flex mb={2}>
            <Text fontSize="3xl" as="b">
              Views
            </Text>
          </Flex>
          <Flex mt={'auto'} mb={1} zIndex={DEFAULT_HEADER_Z_INDEX}>
            <Flex maxW={'800px'} minW={'520px'} alignItems={'center'}>
              <DepartmentsDropdown
                departmentChangeHandler={setSelectedDepartmentIds}
                departmentList={departments}
                departmentListCloseHandler={() =>
                  setUiState((prevState) => {
                    return {
                      ...prevState,
                      isDepartmentDropdownOpen: false,
                    };
                  })
                }
                departmentListOpenHandler={() =>
                  setUiState((prevState) => {
                    return {
                      ...prevState,
                      isDepartmentDropdownOpen: true,
                    };
                  })
                }
                isLoading={isLoading || isFetching}
                selectedIds={selectedDepartmentIds}
              />
              <Spacer />
              <TemplatesDropdown
                isLoading={isLoading || isFetching}
                selectedIds={selectedTemplateIds}
                templateChangeHandler={setSelectedTemplateIds}
                templateList={templatesForSelectedDepartments}
                templateListCloseHandler={() =>
                  setUiState((prevState) => {
                    return {
                      ...prevState,
                      isTemplateDropdownOpen: false,
                    };
                  })
                }
                templateListOpenHandler={() =>
                  setUiState((prevState) => {
                    return {
                      ...prevState,
                      isTemplateDropdownOpen: true,
                    };
                  })
                }
              />
            </Flex>
            <Spacer />
            <Stack direction={'row'} marginTop={'auto'} alignItems={'center'}>
              <ControlledSearch onChange={handleSearch} placeholder={'Search View Name'} value={viewNameSearchValue} />
              <Box>
                <ButtonGroup>
                  <Button size={'md'} leftIcon={<FaPlus />} colorScheme={'blue'} onClick={handleCreateView}>
                    Create View
                  </Button>
                  <Link to={ROUTES.PERSONNEL}>
                    <Button size={'md'} leftIcon={<FaUser />} colorScheme={'blue'}>
                      Personnel
                    </Button>
                  </Link>
                </ButtonGroup>
              </Box>
            </Stack>
          </Flex>

          <ViewsListView
            isLoading={isLoading || isFetching}
            publicViewsData={publicViewsData ?? []}
            selectedDepartmentIds={departmentDropdownSelectedIds}
            selectedTemplateIds={templatesDropdownSelectedIds}
            selectedViewId={selectedViewId}
            setSelectedViewId={(id) =>
              setUiState((prevState) => {
                return { ...prevState, selectedViewId: id };
              })
            }
            toggleViewAccessDrawer={() =>
              setUiState((prevState) => {
                return { ...prevState, isViewAccessDrawerOpen: true };
              })
            }
            toggleConfirmCloneModal={() =>
              setUiState((prevState) => {
                return { ...prevState, isCloneViewModalOpen: true };
              })
            }
            toggleConfirmDeleteModal={() =>
              setUiState((prevState) => {
                return { ...prevState, isDeleteViewModalOpen: true };
              })
            }
            toggleViewEditorDrawer={() =>
              setUiState((prevState) => {
                return {
                  ...prevState,
                  viewEditorDrawerState: {
                    isOpen: true,
                    mode: ViewEditorDrawerMode.EDIT,
                  },
                };
              })
            }
            viewNameFilter={viewNameSearchFilter}
          />
        </Stack>
      </Box>
    </div>
  );
};

export default ViewsPage;
