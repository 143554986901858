import { Box, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import React from 'react';
import { FaSearch } from 'react-icons/fa';

interface ControlledSearchProps {
  /** Change handler which accepts a synthetic input event to take action upon */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** The text to display in the empty input */
  placeholder: string;
  /** The controlled input value of the search box */
  value: string;

  isDisabled?: boolean;
  /** The px width of the search box, defaults to 300px */
  width?: string;
}

const ControlledSearch = (props: ControlledSearchProps): React.JSX.Element => {
  const { width = '300px' } = props;

  return (
    <Box>
      <InputGroup>
        <InputLeftElement pointerEvents={'none'}>
          <FaSearch />
        </InputLeftElement>
        <Input {...props} width={width} />
      </InputGroup>
    </Box>
  );
};

export default ControlledSearch;
