import { apiSlice } from '@/API/api.slice';
import { Endpoints } from '@/constants/api';
import Assignment, { AssignmentDTO, AssignmentType, AssignmentTypeDTO } from '@/types/assignment.types';

export interface AssignmentFilter {
  departmentList?: number[];
  templateList?: number[];
}

export const assignmentsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssignmentTypes: builder.query<AssignmentType[], void>({
      query: () => Endpoints.AssignmentsType,
      transformResponse: (response: AssignmentTypeDTO[]) => {
        return response.map((assignmentType) => ({
          atypeId: assignmentType.atype_id,
          name: assignmentType.name,
        }));
      },
    }),
    getAssignments: builder.query<Assignment[], AssignmentFilter>({
      query: (filter) => {
        const { departmentList, templateList } = filter;
        const delimitedDepartmentList = departmentList?.join('~');
        const delimitedTemplateList = templateList?.join('~');

        return `${Endpoints.Assignments}?dept_list=${delimitedDepartmentList}&template_list=${delimitedTemplateList}`;
      },
      transformResponse: (response: AssignmentDTO[]) => {
        if(!response) return [];

        return response.map((assignment) => ({
          assignId: assignment.assign_id,
          assignStructureId: assignment.assign_structure_id,
          assignTypeId: assignment.atype_id,
          autoAddAssignments: assignment.auto_add_assignments,
          category: assignment.category,
          compactName: assignment.compact_name,
          defaultStartTime: assignment.default_start_time,
          defaultStopTime: assignment.default_stop_time,
          departmentId: assignment.department_id,
          description: assignment.description,
          displayName: assignment.display_name,
          expired: assignment.expired,
          tallies: assignment.tallies,
          templateId: assignment.template_id,
          templateName: assignment.template_name,
        }));
      },
    }),
  }),
});

export const { useGetAssignmentTypesQuery, useGetAssignmentsQuery } = assignmentsApi;
