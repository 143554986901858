import { HStack, Spacer, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import ViewLayoutBlock from '@/components/view-editor/ViewLayoutBlock';
import ViewLayoutDisplayRange from '@/components/view-editor/ViewLayoutDisplayRange';
import ViewLayoutGroupBy from '@/components/view-editor/ViewLayoutGroupBy';
import View, { ViewLayoutType } from '@/types/view.types';

import ViewLayoutList from './ViewLayoutList';

enum ViewLayoutTabIndicies {
  Standard,
  Columns,
  Calendar,
  Gantt,
  List,
  Block,
}

interface ViewLayoutProps {
  updateView: (view: View) => void;
  view: View;
}

const selectedTabStyle = {
  color: 'white',
};

const ViewLayoutTypeTabIndices = Object.values(ViewLayoutType);

const ViewLayout = (props: ViewLayoutProps): JSX.Element => {
  const { updateView, view } = props;
  const displayRange = view?.theme?.data?.range ?? 'month';
  const groupBy = view?.theme?.data?.GridSettings_leftColumnType ?? 'assignment';
  const tabSelectorIndex = ViewLayoutTypeTabIndices.includes(view.theme.data.layout)
    ? ViewLayoutTypeTabIndices.indexOf(view.theme.data.layout ?? ViewLayoutTabIndicies.Standard)
    : ViewLayoutTabIndicies.Standard;

  // ToDo: Immer
  const handleDisplayRangeChange = (value: string) => {
    updateView({
      ...view,
      theme: { ...view.theme, data: { ...view.theme.data, range: value } },
    });
  };
  // ToDo: Immer
  const handleGroupByChange = (value: string) => {
    updateView({
      ...view,
      // eslint-disable-next-line camelcase
      theme: { ...view.theme, data: { ...view.theme.data, GridSettings_leftColumnType: value } },
    });
  };

  const handleTabSelectorChange = (index: number) => {
    const layout = ViewLayoutTypeTabIndices[index] ?? ViewLayoutType.STANDARD;
    let range = displayRange;

    // Handle applying the correct views range for the selected layout
    switch (layout) {
      case ViewLayoutType.STANDARD:
      case ViewLayoutType.COLUMNS:
      case ViewLayoutType.CALENDAR:
        range = 'month';
        break;
      case ViewLayoutType.GANTT:
      case ViewLayoutType.LIST:
        range = 'day';
        break;
      case ViewLayoutType.BLOCK:
        range = 'anchored';
    }

    // ToDo: Immer
    updateView({ ...view, theme: { ...view.theme, data: { ...view.theme.data, layout, range } } });
  };

  return (
    <VStack align={'left'}>
      <Text>View Layout</Text>
      <Tabs variant={'unstyled'} position={'relative'} index={tabSelectorIndex} onChange={handleTabSelectorChange}>
        <TabList>
          <Tab _selected={selectedTabStyle}>Standard</Tab>
          <Tab _selected={selectedTabStyle}>Columns</Tab>
          <Tab _selected={selectedTabStyle}>Calendar</Tab>
          <Tab _selected={selectedTabStyle}>Gantt</Tab>
          <Tab _selected={selectedTabStyle}>List</Tab>
          <Tab _selected={selectedTabStyle}>Block</Tab>
        </TabList>
        <TabIndicator
          bg={'blue.500'}
          borderRadius={'10px'}
          height={'40px'}
          mt={'-40px'}
          zIndex={-1}
          position={'inherit'}
          textColor={'white'}
        />
        <Spacer h={'10px'} borderBottom={'1px solid'} color={'blue.500'} opacity={'0.4'} />
        <TabPanels>
          {/* Standard */}
          <TabPanel>
            {tabSelectorIndex === ViewLayoutTabIndicies.Standard && (
              <HStack w={'400px'} alignItems={'space-between'} gap={10}>
                <ViewLayoutDisplayRange
                  onChange={handleDisplayRangeChange}
                  options={[
                    { label: 'Month', value: 'month' },
                    { label: 'Week', value: 'week' },
                  ]}
                  selected={displayRange}
                />
                <ViewLayoutGroupBy onChange={handleGroupByChange} selected={groupBy} />
              </HStack>
            )}
          </TabPanel>
          {/* Columns */}
          <TabPanel>
            {tabSelectorIndex === ViewLayoutTabIndicies.Columns && (
              <ViewLayoutGroupBy onChange={handleGroupByChange} selected={groupBy} />
            )}
          </TabPanel>
          {/* Calendar */}
          <TabPanel>
            {tabSelectorIndex === ViewLayoutTabIndicies.Calendar && (
              <ViewLayoutGroupBy onChange={handleGroupByChange} selected={groupBy} />
            )}
          </TabPanel>
          {/* Gantt */}
          <TabPanel>
            {tabSelectorIndex === ViewLayoutTabIndicies.Gantt && (
              <ViewLayoutGroupBy onChange={handleGroupByChange} selected={groupBy} />
            )}
          </TabPanel>
          {/* List */}
          <TabPanel>
            {tabSelectorIndex === ViewLayoutTabIndicies.List && (
              <ViewLayoutList
                handleGroupByChange={handleGroupByChange}
                handleDisplayRangeChange={handleDisplayRangeChange}
                groupBy={groupBy}
                displayRange={displayRange}
                {...props}
              />
            )}
          </TabPanel>
          {/* Block */}
          <TabPanel>
            {tabSelectorIndex === ViewLayoutTabIndicies.Block && (
              <ViewLayoutBlock
                handleGroupByChange={handleGroupByChange}
                handleDisplayRangeChange={handleDisplayRangeChange}
                groupBy={groupBy}
                displayRange={displayRange}
                {...props}
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default ViewLayout;
