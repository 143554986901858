import { Alert, AlertIcon, Box, HStack, Icon, VStack } from '@chakra-ui/react';
import { CellContext, ColumnFiltersState, createColumnHelper, SortingState, Table } from '@tanstack/react-table';
import { produce } from 'immer';
import _ from 'lodash';
import React, { useRef } from 'react';
import { FaEye } from 'react-icons/fa';

import PaginatedPreviewTable from '@/components/paginated-preview-table/PaginatedPreviewTable';
import PreviewCell from '@/components/preview-cell/PreviewCell';
import TableCell from '@/components/table-cell/TableCell';
import TableHeader from '@/components/table-header/TableHeader';
import TableHeaderSelectAll from '@/components/table-header-select-all/TableHeaderSelectAll';
import ViewLayoutDisplayRange from '@/components/view-editor/ViewLayoutDisplayRange';
import ViewLayoutGroupBy from '@/components/view-editor/ViewLayoutGroupBy';
import UIConfig from '@/config/ui.config';
import { ViewEditorUIOptions } from '@/config/ViewEditor.config';
import View, { ViewLayoutListColumns } from '@/types/view.types';
import { customSortArray } from '@/utils/arrays';
import SortingUtils from '@/utils/sorting';

interface ViewLayoutListProps {
  displayRange: string;
  groupBy: string;
  handleDisplayRangeChange: (value: string) => void;
  handleGroupByChange: (value: string) => void;
  updateView: (view: View) => void;
  view: View;
}

// Key to sort items on the table
const COLUMN_SORT_KEY = 'columnName';

interface ListColumnData {
  columnName: string;
}

enum ViewEditorListViewColumns {
  COLUMN_NAME = 'columnName',
}

// Sort ascending by default
const DEFAULT_AVAILABLE_TABLE_SORTING: SortingState = [
  {
    desc: false,
    id: ViewEditorListViewColumns.COLUMN_NAME,
  },
];

const allColumns = Object.values(ViewLayoutListColumns);

const tableData: ListColumnData[] = allColumns.map((column) => ({ columnName: column }));
const ViewLayoutList = (props: ViewLayoutListProps): JSX.Element => {
  const { displayRange, groupBy, handleDisplayRangeChange, handleGroupByChange, updateView, view } = props;
  const selectedRange = ['day', 'week'].includes(displayRange) ? displayRange : 'day';

  // A memoized list of all column names which are currently contained in the view theme data
  const currentColumnNames = React.useMemo(() => {
    return view.theme.data.listColumns ?? [];
  }, [view]);

  // Contains a list of all available column names which are currently selected
  const [selectedAvailableColumnNames, setSelectedAvailableColumnNames] = React.useState<Set<string>>(
    new Set(currentColumnNames),
  );

  // Contains a list of all column names rows in the preview table which are currently selected (impacts drag and drop)
  const [selectedPreviewRowColumnNames, setSelectedPreviewRowColumnNames] = React.useState<Set<string>>(new Set());
  const [selectAllAvailableIsToggled, setSelectAllAvailableIsToggled] = React.useState<boolean>(false);
  const [selectAllPreviewIsToggled, setSelectAllPreviewIsToggled] = React.useState<boolean>(false);

  // Contains a list of all column names in the preview table. These are the columns which will be saved to the view theme data
  const [previewData, setPreviewData] = React.useState<ListColumnData[]>([]);
  const [availableTableSortingState, setAvailableTableSortingState] = React.useState<SortingState>(
    DEFAULT_AVAILABLE_TABLE_SORTING,
  );
  const [previewTableSortingState, setPreviewTableSortingState] = React.useState<SortingState>([]);
  const [orderChanged, setOrderChanged] = React.useState<boolean>(false);

  const [unfilteredColumnNames, setUnfilteredColumnNames] = React.useState<string[]>([]);
  const [unfilteredPreviewColumnNames, setUnfilteredPreviewColumnNames] = React.useState<string[]>([]);

  const [availableItemsColumnFilters, setAvailableItemsColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [previewItemsColumnFilters, setPreviewItemsColumnFilters] = React.useState<ColumnFiltersState>([]);

  // Table instance refs
  const tableInstanceRef = React.useRef<Table<ListColumnData>>();
  const previewTableInstanceRef = React.useRef<Table<ListColumnData>>();

  // A memoized list of all column names which are available in the preview table
  // This list includes all column names which are currently selected, as well as all column names which are not selected
  const allColumnNames = React.useMemo(() => {
    if (tableData?.length) {
      return new Set(tableData.map((item) => item.columnName));
    }

    return new Set<string>();
  }, [tableData, selectAllAvailableIsToggled]);

  // Selected columns
  const selectedColumns = React.useMemo(() => {
    return previewData.map((item) => item.columnName);
  }, [previewData]);

  const currentColumns = React.useMemo(() => {
    const columns = tableData?.filter((item) => selectedAvailableColumnNames.has(item.columnName)) ?? [];

    // Sort current(selected) column names based on the order they have been saved
    const sortedColumns = customSortArray(columns, [...selectedAvailableColumnNames], COLUMN_SORT_KEY);

    return sortedColumns;
  }, [tableData, selectedAvailableColumnNames]);

  const normalizedColumnsDataRef = useRef<ViewLayoutListColumns[]>([]);

  normalizedColumnsDataRef.current = currentColumns.map((item) => item.columnName) as ViewLayoutListColumns[];

  // When the current column names change, update the preview table data
  React.useMemo(() => {
    setPreviewData(currentColumns);
  }, [currentColumns]);

  React.useMemo(() => {
    // If user selects all available column names, add all available column names, otherwise add all selected column names
    const columns = selectAllAvailableIsToggled
      ? normalizedColumnsDataRef.current
      : (selectedColumns as ViewLayoutListColumns[]);
    updateView(
      produce(view, (draft) => {
        draft.theme.data.listColumns = columns;
      }),
    );
  }, [selectAllAvailableIsToggled, selectedColumns]);

  const handleSelectAllAvailableColumnNames = React.useCallback(() => {
    if (!tableData?.length) return;

    // Handle selecting of all items
    if (!selectAllAvailableIsToggled) {
      // If there are no filters, select all column names
      if (!unfilteredColumnNames.length) {
        setSelectedAvailableColumnNames(allColumnNames);
      } else {
        // If there are filters, select all column names that match the filters
        const updatedSelectedColumnNames = produce(selectedAvailableColumnNames, (draft) => {
          unfilteredColumnNames.forEach((id) => draft.add(id));
        });

        setSelectedAvailableColumnNames(updatedSelectedColumnNames);
      }

      setSelectAllAvailableIsToggled(true);

      return;
    }

    // Handle deselecting of all items
    // If there are no filters, deselect all column names
    if (!unfilteredColumnNames.length) {
      setSelectedAvailableColumnNames(produce(selectedAvailableColumnNames, (draft) => draft.clear()));
    } else {
      // If there are filters, deselect all column names that match the filters
      const updatedSelectedColumnNames = produce(selectedAvailableColumnNames, (draft) => {
        unfilteredColumnNames.forEach((id) => draft.delete(id));
      });

      setSelectedAvailableColumnNames(updatedSelectedColumnNames);
    }

    setSelectAllAvailableIsToggled(false);
  }, [tableData, selectAllAvailableIsToggled, selectedAvailableColumnNames, unfilteredColumnNames]);

  const handleSelectAllPreviewColumnNames = React.useCallback(() => {
    if (!previewData?.length) return;

    // Handle selecting of all items
    if (!selectAllPreviewIsToggled) {
      // If there are no filters, select all column names
      if (!unfilteredPreviewColumnNames.length) {
        setSelectedPreviewRowColumnNames(new Set([...normalizedColumnsDataRef.current]) as Set<string>);
      } else {
        // If there are filters, select all column names that match the filters
        const updatedSelectedPreviewColumnNames = produce(selectedPreviewRowColumnNames, (draft) => {
          unfilteredPreviewColumnNames.forEach((id) => draft.add(id));
        });

        setSelectedPreviewRowColumnNames(updatedSelectedPreviewColumnNames);
      }

      setSelectAllPreviewIsToggled(true);

      return;
    }

    // Handle deselecting of all items
    // If there are no filters, deselect all column names
    if (!unfilteredPreviewColumnNames.length) {
      setSelectedPreviewRowColumnNames(produce(selectedPreviewRowColumnNames, (draft) => draft.clear()));
    } else {
      // If there are filters, deselect all column names that match the filters
      const updatedSelectedPreviewColumnNames = produce(selectedPreviewRowColumnNames, (draft) => {
        unfilteredPreviewColumnNames.forEach((id) => draft.delete(id));
      });

      setSelectedPreviewRowColumnNames(updatedSelectedPreviewColumnNames);
    }

    setSelectAllPreviewIsToggled(false);
  }, [previewData, selectAllPreviewIsToggled, selectedPreviewRowColumnNames, unfilteredPreviewColumnNames]);

  const debouncedFilterAvailableTable = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setAvailableItemsColumnFilters((prev) => {
      const currentFilters = prev.filter(({ id }) => id !== ViewEditorListViewColumns.COLUMN_NAME);

      return produce(currentFilters, (draft) => {
        draft.push({ id: ViewEditorListViewColumns.COLUMN_NAME, value: e.target.value });
      });
    });
  }, UIConfig.DEFAULT_DEBOUNCE_TIME_MS);

  const debouncedFilterPreviewTable = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setPreviewItemsColumnFilters([{ id: ViewEditorListViewColumns.COLUMN_NAME, value: e.target.value }]);
  }, UIConfig.DEFAULT_DEBOUNCE_TIME_MS);

  // Available column names search bar
  const getAvailableItemsTableHeader = () => {
    return (
      <TableHeader
        label="Available Columns"
        placeholder="Search Available"
        handleFilter={debouncedFilterAvailableTable}
      />
    );
  };

  // Available column names select all button on the table header
  const getAvailableItemsTableHeaderButton = () => {
    return (
      <TableHeaderSelectAll
        handleSelectAll={handleSelectAllAvailableColumnNames}
        selectAllIsToggled={selectAllAvailableIsToggled}
      />
    );
  };

  // Selected column names search bar
  const getPreviewTableHeader = () => {
    return (
      <TableHeader label="Selected Columns" placeholder="Search Selected" handleFilter={debouncedFilterPreviewTable} />
    );
  };

  // Selected column names select all button on the table header
  const getPreviewItemsTableHeaderButton = () => {
    return (
      <TableHeaderSelectAll
        handleSelectAll={handleSelectAllPreviewColumnNames}
        selectAllIsToggled={selectAllPreviewIsToggled}
      />
    );
  };

  const columnHelper = createColumnHelper<ListColumnData>();

  const handleAvailableCellClick = (columnName: string, isCurrentlySelected: boolean) => {
    if (isCurrentlySelected) {
      updateView(
        produce(view, (draft) => {
          // If there are not listColumns, create the list as an empty array (usually when creating a view, the list is undefined)
          if (!draft?.theme?.data?.listColumns) {
            draft.theme.data.listColumns = [];
          }
          draft.theme.data.listColumns = draft.theme.data.listColumns.filter((name) => name !== columnName);
        }),
      );
      setSelectedAvailableColumnNames((prev) =>
        produce(prev, (draft) => {
          draft.delete(columnName);
        }),
      );
    } else {
      updateView(
        // If there are not listColumns, create the list as an empty array
        produce(view, (draft) => {
          if (!draft?.theme?.data?.listColumns) {
            draft.theme.data.listColumns = [];
          }
          draft.theme.data.listColumns.push(columnName as ViewLayoutListColumns);
        }),
      );
      setSelectedAvailableColumnNames((prev) =>
        produce(prev, (draft) => {
          draft.add(columnName);
        }),
      );
    }
  };

  const handlePreviewCellClick = (columnName: string) => {
    setSelectedPreviewRowColumnNames((prev) => {
      const updated = new Set(prev);

      if (prev.has(columnName)) updated.delete(columnName);
      else updated.add(columnName);

      return updated;
    });
  };

  const getCell = React.useCallback(
    (props: CellContext<ListColumnData, unknown>) => {
      // eslint-disable-next-line react/prop-types
      const { row } = props;

      // eslint-disable-next-line react/prop-types
      if (!row?.original) return <></>;

      // eslint-disable-next-line react/prop-types
      const { columnName } = row.original;
      const isSelected = selectedAvailableColumnNames.has(columnName);
      const isPubliclyVisible = ViewEditorUIOptions.publiclyViewableListColumns.includes(
        columnName as ViewLayoutListColumns,
      );

      return (
        <TableCell
          onClick={() => handleAvailableCellClick(columnName, isSelected)}
          isSelected={isSelected}
          text={columnName}
          icon={isPubliclyVisible && <FaEye />}
        />
      );
    },
    [selectedAvailableColumnNames],
  );

  const getPreviewCell = React.useCallback(
    (props: CellContext<ListColumnData, unknown>) => {
      // eslint-disable-next-line react/prop-types
      const { row } = props;

      // eslint-disable-next-line react/prop-types
      if (!row?.original) return null;

      // eslint-disable-next-line react/prop-types
      const { columnName } = row.original;

      // eslint-disable-next-line react/prop-types
      const isSelected = selectedPreviewRowColumnNames.has(columnName);

      const isPubliclyVisible = ViewEditorUIOptions.publiclyViewableListColumns.includes(
        columnName as ViewLayoutListColumns,
      );

      return (
        <PreviewCell
          onClick={() => handlePreviewCellClick(columnName)}
          isSelected={isSelected}
          text={columnName}
          icon={isPubliclyVisible && <FaEye />}
        />
      );
    },
    [selectedAvailableColumnNames],
  );

  const availableItemsColumns = [
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.columnName, {
      cell: getCell,
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorListViewColumns.COLUMN_NAME,
    }),
  ];

  const previewColumnsHelper = createColumnHelper<ListColumnData>();

  const previewColumns = [
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.columnName, {
      cell: getPreviewCell,
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorListViewColumns.COLUMN_NAME,
    }),
  ];

  React.useEffect(() => {
    if (availableItemsColumnFilters.length) {
      const unfilteredColumnNames: string[] =
        // eslint-disable-next-line react/prop-types
        tableInstanceRef?.current
          ?.getFilteredRowModel()
          // eslint-disable-next-line react/prop-types
          .rows.map((row) => row.getValue(ViewEditorListViewColumns.COLUMN_NAME)) ?? [];

      setUnfilteredColumnNames(unfilteredColumnNames);
    }
  }, [availableItemsColumnFilters]);

  React.useEffect(() => {
    if (previewItemsColumnFilters.length) {
      const unfilteredColumnNames: string[] =
        // eslint-disable-next-line react/prop-types
        previewTableInstanceRef?.current
          ?.getFilteredRowModel()
          // eslint-disable-next-line react/prop-types
          .rows.map((row) => row.getValue(ViewEditorListViewColumns.COLUMN_NAME)) ?? [];

      setUnfilteredPreviewColumnNames(unfilteredColumnNames);
    }
  }, [previewItemsColumnFilters]);

  const handleOrderColumnNames = (data: ListColumnData[]) => {
    // Set ordered preview data
    setPreviewData(data);
    // Set order changed (needed to update the view theme data on list columns)
    setOrderChanged(true);
  };

  React.useMemo(() => {
    if (orderChanged) {
      if (view.theme.data.listColumns) {
        // Current(selected) column names
        const currentItems: ListColumnData[] = [...view.theme.data.listColumns].map((column) => ({
          columnName: column,
        }));

        // Sort current(selected) column names based on the order the user defines(via drag&drop or with move buttons)
        const sortedItems = customSortArray(currentItems, [...selectedColumns], COLUMN_SORT_KEY);

        const orderedColumnNames = sortedItems.map((item) => item.columnName) as ViewLayoutListColumns[];

        const updatedView = produce(view, (draft) => {
          // eslint-disable-next-line camelcase
          draft.theme.data.listColumns = orderedColumnNames;
        });

        // Update view theme data with the new column names(ordered)
        updateView(updatedView);
        // Reset order changed
        setOrderChanged(false);
      }
    }
  }, [orderChanged, view]);

  const handlePreviewTableSorting = () => {
    const isDesc = SortingUtils.getSorting(previewTableSortingState, ViewEditorListViewColumns.COLUMN_NAME);
    SortingUtils.setSorting(ViewEditorListViewColumns.COLUMN_NAME, isDesc, setPreviewTableSortingState);
    // Set order changed (needed to update the view theme data on list columns)
    setOrderChanged(true);
  };

  const handleAvailableTableSorting = () => {
    const isDesc = SortingUtils.getSorting(availableTableSortingState, ViewEditorListViewColumns.COLUMN_NAME);
    SortingUtils.setSorting(ViewEditorListViewColumns.COLUMN_NAME, isDesc, setAvailableTableSortingState);
  };

  return (
    <VStack align={'left'} gap={5}>
      <HStack gap={10}>
        <ViewLayoutDisplayRange
          options={[
            { label: 'Day', value: 'day' },
            { label: 'Week', value: 'week' },
          ]}
          selected={selectedRange}
          onChange={handleDisplayRangeChange}
        />
        <ViewLayoutGroupBy onChange={handleGroupByChange} selected={groupBy} />
      </HStack>

      <Alert status="info">
        <AlertIcon />
        <HStack alignItems={'center'} justifyContent={'space-between'}>
          <p>For publicly accessible list views, only those columns marked with</p>
          <Icon as={FaEye} margin={'0 4px'} fontSize={'xl'} verticalAlign={'center'} />
          <p>will be visible.</p>
        </HStack>
      </Alert>

      <VStack gap={5} align={'top'}>
        <PaginatedPreviewTable
          availableItemsTableColumnFilters={availableItemsColumnFilters}
          availableItemsTableColumns={availableItemsColumns}
          availableItemsTableData={tableData ?? []}
          availableItemsTableHeader={getAvailableItemsTableHeader()}
          availableItemsTableHeaderButton={getAvailableItemsTableHeaderButton()}
          availableItemsTableInstanceRef={tableInstanceRef}
          availableItemsTableSortingState={availableTableSortingState}
          availableRowUniqueKey={COLUMN_SORT_KEY}
          headerSortHandlers={[
            {
              columnId: ViewEditorListViewColumns.COLUMN_NAME,
              sortHandler: () => handleAvailableTableSorting(),
            },
          ]}
          isLoading={false}
          previewHeaderSortHandlers={[
            {
              columnId: ViewEditorListViewColumns.COLUMN_NAME,
              sortHandler: () => handlePreviewTableSorting(),
            },
          ]}
          previewTableColumns={previewColumns}
          previewItemsTableInstanceRef={previewTableInstanceRef}
          previewTableColumnFilters={previewItemsColumnFilters}
          previewTableData={previewData}
          previewTableHeader={getPreviewTableHeader()}
          previewTableHeaderButton={getPreviewItemsTableHeaderButton()}
          previewRowsAreDraggable={true}
          previewRowUniqueKey={COLUMN_SORT_KEY}
          previewTableSortingState={previewTableSortingState}
          previewTableDragHandler={(items) => handleOrderColumnNames(items as ListColumnData[])}
          previewTableUniqueSelectedIds={selectedPreviewRowColumnNames}
          showColorPicker={false}
          handleResetSorting={() => setPreviewTableSortingState([])}
        />
      </VStack>
    </VStack>
  );
};

export default ViewLayoutList;
