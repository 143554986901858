import {
  Box,
  Button,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Chrome, ChromeInputType, hsvaToHex, Swatch } from '@uiw/react-color';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { DEFAULT_TOAST_DURATION, ToastTypes } from '@/constants/defaults';
import { ColorMode, ColorPickerState } from '@/types/ui.types';

interface ColorPickerDialogProps {
  colorPickerState: ColorPickerState;
  headerText: string;
  onClose: () => void;
  onClear: () => void;
  onConfirm: () => void;
  onDiscard: () => void;
  onSelectColor: (colorPickerState: ColorPickerState) => void;
  isOpen?: boolean;
}

const DEFAULT_INPUT_TYPE = 'hexa' as ChromeInputType;
const DEFAULT_PREVIEW_TEXT = 'This is a preview';
const DEFAULT_SPACE = 10;
const DEFAULT_SWATCHES_HEIGHT = 290;
const DEFAULT_SWATCHES_WIDTH = 200;
const SWATCH_RECTANGLE_HEIGHT = 24;
const SWATCH_RECTANGLE_WIDTH = 40;

const COLOR_COPY_SUCCESS_MESSAGE = "Color's HEX code copied successfully.";
const COLOR_COPY_ERROR_MESSAGE = "An error occurred when trying to copy color's HEX code.";

const COLOR_SWATCH_HEX_CODES = [
  '#b71c1c', // Dark Red
  '#d32f2f', // Firebrick
  '#f44336', // Red
  '#e57373', // Light Coral
  '#ffcdd2', // Misty Rose
  '#880e4f', // Dark Magenta
  '#c2185b', // Deep Pink
  '#e91e63', // Hot Pink
  '#f06292', // Light Pink
  '#f8bbd0', // Pink Lace
  '#4a148c', // Dark Purple
  '#7b1fa2', // Purple
  '#9c27b0', // Medium Purple
  '#ba68c8', // Plum
  '#e1bee7', // Thistle
  '#311b92', // Indigo
  '#512da8', // Deep Indigo
  '#673ab7', // Blue Violet
  '#9575cd', // Medium Slate Blue
  '#d1c4e9', // Lavender
  '#1a237e', // Midnight Blue
  '#303f9f', // Cobalt Blue
  '#3f51b5', // Royal Blue
  '#7986cb', // Light Slate Blue
  '#c5cae9', // Light Lavender
  '#0d47a1', // Navy Blue
  '#1976d2', // Dodger Blue
  '#2196f3', // Bright Blue
  '#64b5f6', // Light Sky Blue
  '#bbdefb', // Powder Blue
  '#01579b', // Deep Sky Blue
  '#0288d1', // Steel Blue
  '#03a9f4', // Vivid Sky Blue
  '#4fc3f7', // Sky Blue
  '#b3e5fc', // Light Cyan
  '#006064', // Teal
  '#0097a7', // Turquoise
  '#00bcd4', // Cyan
  '#4dd0e1', // Light Sea Green
  '#b2ebf2', // Pale Turquoise
  '#004d40', // Dark Green
  '#00796b', // Sea Green
  '#009688', // Medium Sea Green
  '#4db6ac', // Aquamarine
  '#b2dfdb', // Mint Cream
  '#194D33', // Forest Green
  '#388e3c', // Medium Forest Green
  '#4caf50', // Lime Green
  '#81c784', // Pale Green
  '#c8e6c9', // Honeydew
  '#33691e', // Olive Drab
  '#689f38', // Dark Olive Green
  '#8bc34a', // Yellow Green
  '#aed581', // Green Yellow
  '#dcedc8', // Light Green
  '#827717', // Olive
  '#afb42b', // Dark Khaki
  '#cddc39', // Khaki
  '#dce775', // Pale Goldenrod
  '#f0f4c3', // Beige
  '#f57f17', // Goldenrod
  '#fbc02d', // Dark Goldenrod
  '#ffeb3b', // Gold
  '#fff176', // Light Goldenrod Yellow
  '#fff9c4', // Lemon Chiffon
  '#ff6f00', // Dark Orange
  '#ffa000', // Orange
  '#ffc107', // Amber
  '#ffd54f', // Light Orange
  '#ffecb3', // Moccasin
  '#e65100', // Dark Red Orange
  '#f57c00', // Red Orange
  '#ff9800', // Bright Orange
  '#ffb74d', // Light Orange
  '#ffe0b2', // Peach Puff
  '#bf360c', // Dark Sienna
  '#e64a19', // Burnt Orange
  '#ff5722', // Vermilion
  '#ff8a65', // Salmon
  '#ffccbc', // Light Salmon
  '#3e2723', // Brown
  '#5d4037', // Dark Brown
  '#795548', // Saddle Brown
  '#a1887f', // Rosy Brown
  '#d7ccc8', // Dusty Rose
  '#263238', // Dark Slate Gray
  '#455a64', // Slate Gray
  '#607d8b', // Light Slate Gray
  '#90a4ae', // Light Steel Blue
  '#cfd8dc', // Light Slate
  '#000000', // Black
  '#525252', // Dim Gray
  '#969696', // Gray
  '#D9D9D9', // Light Gray
  '#FFFFFF', // White
];

const ColorPickerDialog = ({
  colorPickerState,
  headerText = DEFAULT_PREVIEW_TEXT,
  isOpen = false,
  onClear,
  onClose,
  onConfirm,
  onDiscard,
  onSelectColor,
}: ColorPickerDialogProps): React.JSX.Element => {
  const { cellColor, textColor, colorMode } = colorPickerState;

  const toast = useToast();

  const handleSetTextColor = (textColor: string) => {
    onSelectColor({ ...colorPickerState, textColor });
  };

  const handleSetCellColor = (cellColor: string) => {
    onSelectColor({ ...colorPickerState, cellColor });
  };

  const handleSetColorMode = (colorMode: ColorMode) => {
    onSelectColor({ ...colorPickerState, colorMode });
  };

  const handleSetColor = (color: string) => {
    if (colorMode === ColorMode.Cell) {
      handleSetCellColor(color);
    } else {
      handleSetTextColor(color);
    }
  };

  const getColorToCopy = () => {
    if (colorMode === ColorMode.Cell) {
      return cellColor;
    }
    return textColor;
  };

  const handleCopyColor = (text: string, result: boolean) => {
    if (text && result) {
      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.INFO,
        title: COLOR_COPY_SUCCESS_MESSAGE,
      });
    } else {
      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: COLOR_COPY_ERROR_MESSAGE,
      });
    }
  };

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <HStack align={'left'} gap={DEFAULT_SPACE} position={'relative'} top={'40px'}>
            <Box
              display="flex"
              justifyContent="center"
              backgroundColor={cellColor}
              borderRadius={'10px'}
              alignItems={'center'}
              padding={'5px'}
              minW="40%"
              maxW="50%"
              maxH="60px"
              height="auto"
            >
              <Text color={textColor} textAlign={'center'}>
                {headerText}
              </Text>
            </Box>

            <VStack align={'left'}>
              <FormLabel>Color Picker Mode</FormLabel>
              <RadioGroup value={colorMode} onChange={(mode: ColorMode) => handleSetColorMode(mode)}>
                <HStack align={'left'} justifyContent={'left'} alignItems={'space-between'}>
                  <Radio value={ColorMode.Cell}>Cell</Radio>
                  <Radio value={ColorMode.Text}>Text</Radio>
                </HStack>
              </RadioGroup>
            </VStack>
          </HStack>
          <HStack align={'top'} marginTop={'50px'}>
            <Chrome
              color={colorMode === ColorMode.Cell ? cellColor : textColor}
              onChange={(color) => handleSetColor(color.hex)}
              inputType={DEFAULT_INPUT_TYPE}
            />
            <Swatch
              colors={COLOR_SWATCH_HEX_CODES}
              rectProps={{
                style: {
                  alignItems: 'center',
                  display: 'flex',
                  height: SWATCH_RECTANGLE_HEIGHT,
                  justifyContent: 'center',
                  width: SWATCH_RECTANGLE_WIDTH,
                },
              }}
              style={{ height: DEFAULT_SWATCHES_HEIGHT, overflowY: 'scroll', width: DEFAULT_SWATCHES_WIDTH }}
              onChange={(color) => handleSetColor(hsvaToHex(color))}
            />
          </HStack>
        </ModalBody>
        <ModalFooter justifyContent={'flex-start'}>
          <HStack justifyContent={'space-between'} width="100%">
            <HStack>
              <CopyToClipboard text={getColorToCopy()} onCopy={handleCopyColor}>
                <Button variant="ghost" colorScheme={'blue'}>
                  Copy
                </Button>
              </CopyToClipboard>

              <Button variant="ghost" onClick={onClear}>
                Clear
              </Button>
            </HStack>
            <HStack>
              <Button colorScheme={'red'} onClick={onDiscard}>
                Discard
              </Button>
              <Button colorScheme={'blue'} onClick={onConfirm}>
                Confirm
              </Button>
            </HStack>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ColorPickerDialog;
