import { Box, HStack, Text } from '@chakra-ui/react';
import React from 'react';

interface PreviewCellProps {
  text: string;
  className?: string;
  color?: string | null;
  colorText?: string | null;
  icon?: React.ReactNode;
  isSelected?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const FULL_SIZE = '100%';

const APLTableCell = (props: PreviewCellProps): React.JSX.Element => {
  const {
    className = 'APLTableCell',
    color = undefined,
    colorText = undefined,
    icon = null,
    isSelected = false,
    onClick = () => {console.log('Click handler not defined')},
    text = '',
    style
  } = props;

  return (
    <Box className={className} w={FULL_SIZE} h={FULL_SIZE} onClick={icon ? ()=>{return;} : onClick} style={style}>
      <HStack
        justifyContent={'space-between'}
        h={FULL_SIZE}
        padding={'10px'}
        // ToDo: Make a constant for the color
        backgroundColor={isSelected ? 'blue.400' : ''}
      >
        <Text>{text}</Text>
        {(color || colorText) && (
          <Box w={'20px'} backgroundColor={color ?? ''} color={colorText ?? ''} h={'20px'} textAlign={'center'}>
            <Text>A</Text>
          </Box>
        )}
        <Box className={'row-action-icon'} onClick={onClick}>{icon}</Box>
      </HStack>
    </Box>
  );
};

export default APLTableCell;
