import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/store/store';
import { DaysOfWeekAbbreviationFromFullString } from '@/types/ui.types';
import {
  ViewEditorDisplayDepartmentNames,
  ViewEditorDraft,
  ViewEditorDraftSortByAssignmentOptions,
  ViewEditorDraftSortByPersonnelOptions,
  ViewEditorUIOptions,
  ViewLayoutDataType,
  ViewLayoutDisplayRange,
  ViewLayoutGroupBy,
  ViewLayoutStandard,
  ViewLayoutStandardViewerOptions,
  ViewLayoutType,
} from '@/types/view.types';

interface ViewEditorState {
  viewEditorDraft: ViewEditorDraft;
  readonly viewEditorOriginal: ViewEditorDraft | undefined;
}

const defaultViewEditorLayout: ViewLayoutStandard = {
  dataType: ViewLayoutDataType.REQUEST,
  displayRange: ViewLayoutDisplayRange.MONTH,
  groupBy: ViewLayoutGroupBy.ASSIGNMENT,
  layout: ViewLayoutType.STANDARD,
};

const defaultViewEditorViewerOptions: ViewLayoutStandardViewerOptions = {
  compactMode: false,
  displayDepartmentNames: ViewEditorDisplayDepartmentNames.NONE,
  enableSavedFilters: false,
  hideBlankRows: false,
  hideWeekends: false,
  shouldCountTalliesByPersonnelFilter: false,
  showLoggedInUserOnly: false,
  showTimes: false,
  startOnDayOfWeek: DaysOfWeekAbbreviationFromFullString.Monday,
};

const defaultViewEditorUIOptions: ViewEditorUIOptions = {
  hideInactiveAssignments: false,
  hideInactivePersonnel: false,
};

const defaultViewEditorDraft: ViewEditorDraft = {
  assignments: [],
  departmentIds: [],
  layout: defaultViewEditorLayout,
  name: '',
  personnel: [],
  sortByAssignment: ViewEditorDraftSortByAssignmentOptions.CUSTOM,
  sortByPersonnel: ViewEditorDraftSortByPersonnelOptions.CUSTOM,
  tallyIds: [],
  templateIds: [],
  uiOptions: defaultViewEditorUIOptions,
  viewerOptions: defaultViewEditorViewerOptions,
};

const initialState: ViewEditorState = { viewEditorDraft: defaultViewEditorDraft, viewEditorOriginal: undefined };

export const viewEditorSlice = createSlice({
  initialState,
  name: 'viewEditor',
  reducers: {
    /** Passing an initial view draft indicates an existing view will be edited.
     * This reducer will initialize the view editor draft and original view drafts
     * with the action payload. Any changes made to the draft can be compared against
     * the original, indicating save logic needs to be performed. */
    initializeViewEditorDraft: (state, action) => {
      state.viewEditorDraft = action.payload;
      state.viewEditorOriginal = action.payload;
    },
    /** Sets the assignments for the view editor draft
     * @param {ViewEditorDraftColoredItem[]} action.payload - An array of themeable assignments containing
     * the assignment id, slot color, and text color to be included in the view.
     * */
    setViewEditorDraftAssignments: (state, action) => {
      state.viewEditorDraft.assignments = action.payload;
    },
    /** Sets the department ids for the view editor draft
     * @param {number[]} action.payload - An array of department ids to be included in the view.
     * */
    setViewEditorDraftDepartmentIds: (state, action) => {
      state.viewEditorDraft.departmentIds = action.payload;
    },
    /** Sets the layout for the view editor draft
     * @param {ViewLayout} action.payload - The layout to be used for the view.
     */
    setViewEditorDraftLayout: (state, action) => {
      state.viewEditorDraft.layout = action.payload;
    },
    /** Sets the name for the view editor draft
     * @param {string} action.payload - The name to be used for the view. Must be unique.
     */
    setViewEditorDraftName: (state, action) => {
      state.viewEditorDraft.name = action.payload;
    },
    /** Sets the personnel for the view editor draft
     * @param {ViewEditorDraftColoredItem[]} action.payload - An array of themeable personnel containing
     * the personnel id, slot color, and text color to be included in the view.
     */
    setViewEditorDraftPersonnel: (state, action) => {
      state.viewEditorDraft.personnel = action.payload;
    },
    /** Sets the sort by assignment for the view editor draft
     * @param {ViewEditorDraftSortByGlobal} action.payload - The sorting method Viewer will use to display assignments.
     */
    setViewEditorDraftSortByAssignment: (state, action) => {
      state.viewEditorDraft.sortByAssignment = action.payload;
    },
    /** Sets the sort by personnel for the view editor draft
     * @param {ViewEditorDraftSortByGlobal} action.payload - The sorting method Viewer will use to display personnel.
     */
    setViewEditorDraftSortByPersonnel: (state, action) => {
      state.viewEditorDraft.sortByPersonnel = action.payload;
    },
    /** Sets the tally ids for the view editor draft
     * @param {number[]} action.payload - An array of tally ids to be included in the view.
     */
    setViewEditorDraftTallyIds: (state, action) => {
      state.viewEditorDraft.tallyIds = action.payload;
    },
    /** Sets the template ids for the view editor draft
     * @param {number[]} action.payload - An array of template ids to be included in the view.
     */
    setViewEditorDraftTemplateIds: (state, action) => {
      state.viewEditorDraft.templateIds = action.payload;
    },
    /** Sets the UI options for the view editor draft
     * @param {ViewEditorUIOptions} action.payload - The UI options to be used for the view.
     * These options only affect how the view is displayed in Osiris and will not trigger any save logic.
     */
    setViewEditorDraftUIOptions: (state, action) => {
      state.viewEditorDraft.uiOptions = action.payload;
    },
    /** Sets the viewer options for the view editor draft
     * @param {ViewLayoutStandardViewerOptions} action.payload - The viewer options to be used for the view. These options
     * affect how the view is displayed in Viewer and will trigger save logic.
     */
    setViewEditorDraftViewerOptions: (state, action) => {
      state.viewEditorDraft.viewerOptions = action.payload;
    },
  },
});

// Start View Editor Selectors

/** Returns the view editor state from the store. */
export const selectViewEditor = (state: RootState): ViewEditorState => state.viewEditor;
/** Returns the view editor draft view from the store. */
export const selectViewEditorDraft = (state: RootState): ViewEditorDraft => state.viewEditor.viewEditorDraft;
/** Returns the view editor original view from the store. */
export const selectViewEditorOriginal = (state: RootState): ViewEditorDraft | undefined =>
  state.viewEditor.viewEditorOriginal;

// End View Editor Selectors

export default viewEditorSlice.reducer;
