import { Button, FormLabel, HStack, Input, Tab, TabIndicator, TabList, Tabs, Text, VStack } from '@chakra-ui/react';
import { produce } from 'immer';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';

import CustomDropdown from '@/components/custom-dropdown/CustomDropdown';
import ViewLayoutGroupBy from '@/components/view-editor/ViewLayoutGroupBy';
import UIConfig from '@/config/ui.config';
import { ISO_8601_DATE_FORMAT } from '@/constants/time';
import { DaysOfWeekAbbreviationFromFullString, DaysOfWeekFullStringFromAbbreviation } from '@/types/ui.types';
import View, {
  ViewLayoutBlockInclusive,
  ViewLayoutBlockLengthDays,
  ViewLayoutBlockRangeStatic,
  ViewLayoutBlockWeekLength,
} from '@/types/view.types';

interface ViewLayoutBlockProps {
  displayRange: string;
  groupBy: string;
  handleDisplayRangeChange: (value: string) => void;
  handleGroupByChange: (value: string) => void;
  updateView: (view: View) => void;
  view: View;
}

const selectedTabStyle = {
  color: 'white',
};

const CURRENT_ISO_DATE = DateTime.now().toFormat(ISO_8601_DATE_FORMAT);

const defaultBlockLayout: ViewLayoutBlockInclusive = {
  blockAnchorDate: CURRENT_ISO_DATE,
  blockLength: 1,
  blockStart: DaysOfWeekAbbreviationFromFullString.Monday,
  blockStaticStart: CURRENT_ISO_DATE,
  blockStaticStop: CURRENT_ISO_DATE,
  blockViewTotalWeeks: 1,
  blockWeekLength: 1,
};

enum ViewLayoutBlockRange {
  ANCHORED = 'anchored',
  STATIC = 'static',
}

const ViewLayoutBlockTotalWeeksComponentConfig = {
  defaultValue: 1,
  maxValue: 52,
  minValue: 1,
};

const ViewLayoutBlock = (props: ViewLayoutBlockProps): JSX.Element => {
  const { displayRange, groupBy, handleDisplayRangeChange, handleGroupByChange, updateView, view } = props;

  const blockRangeIndex = Object.values(ViewLayoutBlockRange).findIndex((i: string) => i === displayRange);

  const {
    blockAnchorDate = defaultBlockLayout.blockAnchorDate,
    blockLength = defaultBlockLayout.blockLength,
    blockStart = defaultBlockLayout.blockStart,
    blockStaticStart = defaultBlockLayout.blockStaticStart,
    blockStaticStop = defaultBlockLayout.blockStaticStop,
    blockViewTotalWeeks = defaultBlockLayout.blockViewTotalWeeks,
    blockWeekLength = defaultBlockLayout.blockWeekLength,
  } = view.theme.data ?? {};
  const blockProps: ViewLayoutBlockInclusive = {
    blockAnchorDate,
    blockLength,
    blockStart,
    blockStaticStart,
    blockStaticStop,
    blockViewTotalWeeks,
    blockWeekLength,
  };

  const [startDate, setStartDate] = React.useState<string>(
    displayRange === ViewLayoutBlockRange.ANCHORED
      ? blockProps.blockAnchorDate
      : (blockProps as unknown as ViewLayoutBlockRangeStatic).blockStaticStart,
  );
  const [stopDate, setStopDate] = React.useState<string>(
    displayRange === ViewLayoutBlockRange.STATIC
      ? (blockProps as unknown as ViewLayoutBlockRangeStatic).blockStaticStop
      : CURRENT_ISO_DATE,
  );
  const [tabIndex, setTabIndex] = React.useState(blockRangeIndex);

  const initAnchorView = () => {
    const anchoredView = produce(view, (draft) => {
      draft.theme.data = {
        blockAnchorDate: startDate,
        blockLength: blockProps.blockLength,
        blockStart: blockProps.blockStart,
        blockViewTotalWeeks: blockProps.blockViewTotalWeeks ?? UIConfig.DEFAULT_BLOCK_LENGTH_WEEKS,
        blockWeekLength: blockProps.blockWeekLength,
        ...draft.theme.data,
      };
      draft.theme.data.range = ViewLayoutBlockRange.ANCHORED;
    });

    updateView(anchoredView);
  };

  // initialize data block for anchored view on load
  React.useEffect(() => {
    if (!view.theme.data?.blockViewTotalWeeks) {
      initAnchorView();
    }
  }, []);

  React.useMemo(() => {
    if (displayRange === ViewLayoutBlockRange.ANCHORED) {
      updateView(
        produce(view, (draft) => {
          if (draft.theme.data.blockAnchorDate) {
            draft.theme.data.blockAnchorDate = startDate;
          }
          draft.theme.data.range = ViewLayoutBlockRange.ANCHORED;
        }),
      );
      return;
    }
    updateView(
      produce(view, (draft) => {
        if (draft?.theme?.data) {
          draft.theme.data.blockStaticStart = startDate;
          draft.theme.data.blockStaticStop = stopDate;
        }
        draft.theme.data.range = ViewLayoutBlockRange.STATIC;
      }),
    );
  }, [startDate, stopDate]);

  const initStaticView = () => {
    const staticView = produce(view, (draft) => {
      (draft.theme.data as unknown as ViewLayoutBlockRangeStatic) = {
        blockLength: blockProps.blockLength,
        blockStart: blockProps.blockStart,
        blockStaticStart: startDate,
        blockStaticStop: stopDate,
        blockWeekLength: blockProps.blockWeekLength,
      };
    });

    updateView(staticView);
  };

  const handleTabSelectorChange = (index: number) => {
    const rangeType = Object.values(ViewLayoutBlockRange)[index];

    setTabIndex(index);

    if (rangeType === ViewLayoutBlockRange.ANCHORED) {
      initAnchorView();
      return;
    }

    initStaticView();

    handleDisplayRangeChange(rangeType);
  };

  const getTabSelector = (label: string, values: string[], onChange: (index: number) => void) => {
    return (
      <VStack justifyContent={'space-between'} align={'left'}>
        <Text>{label}</Text>
        <Tabs variant={'unstyled'} position={'relative'} onChange={onChange} index={tabIndex}>
          <TabList>
            {values.map((value) => (
              <Tab key={value} _selected={selectedTabStyle}>
                {_.capitalize(value)}
              </Tab>
            ))}
          </TabList>
          <TabIndicator
            bg={'blue.500'}
            borderRadius={'10px'}
            height={'40px'}
            mt={'-40px'}
            zIndex={-1}
            position={'inherit'}
            textColor={'white'}
          />
        </Tabs>
      </VStack>
    );
  };

  const getDateInput = (label: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, value: string) => {
    return (
      <VStack justifyContent={'space-between'} align={'left'}>
        <FormLabel>{label}</FormLabel>
        <Input type={'date'} size={'md'} w={'200px'} onChange={onChange} value={value} />
      </VStack>
    );
  };

  const getStaticRangeDateInputs = () => {
    return (
      <>
        {getDateInput(
          'Block Start Date',
          (e: React.ChangeEvent<HTMLInputElement>) => setStartDate(e.target.value),
          startDate,
        )}
        {getDateInput(
          'Block Stop Date',
          (e: React.ChangeEvent<HTMLInputElement>) => setStopDate(e.target.value),
          stopDate,
        )}
      </>
    );
  };

  const setViewTotalWeeks = (value: number) => {
    // Bail to prevent re-rendering if value is the same
    if (value === blockProps.blockViewTotalWeeks) return;

    updateView(
      produce(view, (draft) => {
        if (draft?.theme?.data?.blockViewTotalWeeks) {
          // eslint-disable-next-line camelcase
          draft.theme.data.blockViewTotalWeeks = value;
        }
      }),
    );
  };

  const handleDecreaseViewTotalWeeks = (weeks: number) => {
    const { maxValue, minValue } = ViewLayoutBlockTotalWeeksComponentConfig;
    const step = blockProps.blockWeekLength;

    setViewTotalWeeks(_.clamp(weeks - step, minValue, maxValue));
  };

  const handleIncreaseViewTotalWeeks = (weeks: number) => {
    const { maxValue, minValue } = ViewLayoutBlockTotalWeeksComponentConfig;
    // step changes according to the week length
    const step = blockProps.blockWeekLength;

    setViewTotalWeeks(_.clamp(weeks + step, minValue, maxValue));
  };

  const handleChangeViewTotalWeeks = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { maxValue, minValue } = ViewLayoutBlockTotalWeeksComponentConfig;
    const value = parseInt(e.target.value);

    setViewTotalWeeks(_.clamp(value, minValue, maxValue));
  };

  const getAnchoredRangeDateInputs = () => {
    return (
      <>
        {getDateInput(
          'Block Start Date',
          (e: React.ChangeEvent<HTMLInputElement>) => setStartDate(e.target.value),
          startDate,
        )}
        <VStack align={'left'}>
          <Text>Block View Total Weeks</Text>
          <HStack>
            <Button onClick={() => handleDecreaseViewTotalWeeks(blockProps.blockViewTotalWeeks)}>
              <FaMinus />
            </Button>
            <Input
              maxW={'60px'}
              value={blockProps.blockViewTotalWeeks}
              onChange={handleChangeViewTotalWeeks}
              max={ViewLayoutBlockTotalWeeksComponentConfig.maxValue}
              min={ViewLayoutBlockTotalWeeksComponentConfig.minValue}
              textAlign={'center'}
            />
            <Button onClick={() => handleIncreaseViewTotalWeeks(blockProps.blockViewTotalWeeks)}>
              <FaPlus />
            </Button>
          </HStack>
        </VStack>
      </>
    );
  };

  const setBlockLength = (length: ViewLayoutBlockLengthDays) => {
    updateView(
      produce(view, (draft) => {
        if (draft?.theme?.data?.blockLength) {
          draft.theme.data.blockLength = length;
        }
      }),
    );
  };

  const setWeekLength = (length: ViewLayoutBlockWeekLength) => {
    updateView(
      produce(view, (draft) => {
        if (draft?.theme?.data?.blockWeekLength) {
          draft.theme.data.blockWeekLength = length;
          // init Block View Total Weeks to the same value as blockWeekLength anytime the latter changes
          draft.theme.data.blockViewTotalWeeks = length;
        }
      }),
    );
  };

  const setStartOnDay = (day: DaysOfWeekAbbreviationFromFullString) => {
    updateView(
      produce(view, (draft) => {
        if (draft?.theme?.data?.blockStart) {
          draft.theme.data.blockStart = day;
        }
      }),
    );
  };

  return (
    <VStack align={'left'} justifyContent={'space-between'} gap={'5'}>
      <HStack alignItems={'center'} gap={'5'}>
        {getTabSelector('Range', Object.values(ViewLayoutBlockRange), handleTabSelectorChange)}
      </HStack>
      <HStack gap={'5'}>
        {displayRange === ViewLayoutBlockRange.ANCHORED ? getAnchoredRangeDateInputs() : getStaticRangeDateInputs()}
      </HStack>
      <HStack justifyContent={'space-between'} w={'200px'} gap={'5'}>
        <CustomDropdown
          label={'Block Start Day'}
          options={Object.keys(DaysOfWeekAbbreviationFromFullString)}
          onChange={(e) =>
            setStartOnDay(
              DaysOfWeekAbbreviationFromFullString[e.target.value as keyof typeof DaysOfWeekAbbreviationFromFullString],
            )
          }
          selectedOption={DaysOfWeekFullStringFromAbbreviation[blockProps.blockStart]}
        />
        <CustomDropdown
          label={'Block Length (Days)'}
          options={UIConfig.BLOCK_LENGTH_DAYS}
          onChange={(e) => setBlockLength(Number(e.target.value) as ViewLayoutBlockLengthDays)}
          selectedOption={blockProps.blockLength}
        />
      </HStack>
      <HStack justifyContent={'space-between'} w={'200px'} gap={'5'}>
        <CustomDropdown
          label={'Block Week Length'}
          options={UIConfig.BLOCK_LENGTH_WEEKS}
          onChange={(e) => setWeekLength(Number(e.target.value) as ViewLayoutBlockWeekLength)}
          selectedOption={blockProps.blockWeekLength}
        />
        <ViewLayoutGroupBy onChange={handleGroupByChange} selected={groupBy} />
      </HStack>
    </VStack>
  );
};

export default ViewLayoutBlock;
