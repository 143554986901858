// Reserved for product ui hex colors
import {
  ViewColoredItem,
  ViewEditorDraftSortByAssignmentOptions,
  ViewEditorDraftSortByPersonnelOptions,
} from '@/types/view.types';

export enum Colors {
  Blue = 'blue',
  White = 'white',
}

export enum Size {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}

export enum Variant {
  Solid = 'solid',
  Outline = 'outline',
  Ghost = 'ghost',
  Link = 'link',
}

export enum IconPosition {
  Left,
  Right,
}

export type yesOrNo = 'yes' | 'no';

export const YesNoAsBoolean = {
  no: false,
  yes: true,
};

export enum DaysOfWeekAbbreviationFromFullString {
  Sunday = 'Sun',
  Monday = 'Mon',
  Tuesday = 'Tue',
  Wednesday = 'Wed',
  Thursday = 'Thu',
  Friday = 'Fri',
  Saturday = 'Sat',
}

export enum DaysOfWeekFullStringFromAbbreviation {
  Sun = 'Sunday',
  Mon = 'Monday',
  Tue = 'Tuesday',
  Wed = 'Wednesday',
  Thu = 'Thursday',
  Fri = 'Friday',
  Sat = 'Saturday',
}

export type AssignmentSortOrderDropDownOption = { label: string; value: ViewEditorDraftSortByAssignmentOptions };

export type PersonnelSortOrderDropDownOption = { label: string; value: ViewEditorDraftSortByPersonnelOptions };

export const AssignmentSortOrderDropDownOptions: AssignmentSortOrderDropDownOption[] = [
  { label: 'Custom order', value: ViewEditorDraftSortByAssignmentOptions.CUSTOM },
  { label: 'Name', value: ViewEditorDraftSortByAssignmentOptions.NAME },
  {
    label: 'Time then custom order',
    value: ViewEditorDraftSortByAssignmentOptions.TIME_THEN_CUSTOM,
  },
  {
    label: 'Time then name',
    value: ViewEditorDraftSortByAssignmentOptions.TIME_THEN_NAME,
  },
];

export const PersonnelSortOrderDropDownOptions: PersonnelSortOrderDropDownOption[] = [
  { label: 'Custom order', value: ViewEditorDraftSortByPersonnelOptions.CUSTOM },
  { label: 'Display name', value: ViewEditorDraftSortByPersonnelOptions.DISPLAY_NAME },
  { label: 'Last name', value: ViewEditorDraftSortByPersonnelOptions.LAST_NAME },
  {
    label: 'Time then custom order (Rendered in viewer)',
    value: ViewEditorDraftSortByPersonnelOptions.TIME_THEN_CUSTOM,
  },
  {
    label: 'Time then display name (Rendered in viewer)',
    value: ViewEditorDraftSortByPersonnelOptions.TIME_THEN_DISPLAY_NAME,
  },
  {
    label: 'Time then last name (Rendered in viewer)',
    value: ViewEditorDraftSortByPersonnelOptions.TIME_THEN_LAST_NAME,
  },
];

export interface CellColor {
  cellColor: string;
  textColor: string;
}

export enum ColorMode {
  Cell = 'cell',
  Text = 'text',
}

export interface ColorPickerState extends CellColor {
  colorMode: ColorMode;
}

export interface ColoredItem extends ViewColoredItem {
  name: string;
}

export enum DropdownAction {
  CLEAR = 'clear',
  SELECT_OPTION = 'select-option',
  DESELECT_OPTION = 'deselect-option',
  REMOVE_VALUE = 'remove-value',
  POP_VALUE = 'pop-value',
  CREATE_OPTION = 'create-option',
}


export interface HeaderSortHandler {
  columnId: string;
  sortHandler: (args: unknown) => void;
}

export type HeaderSortHandlers = HeaderSortHandler[];