/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, ButtonGroup, Flex, Link, Text, useToast } from '@chakra-ui/react';
import { ColumnDef, ColumnSort, createColumnHelper, SortingState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';

import {
  PublicViewsListItem,
  useGetViewsListQuery,
  useGiveSelfAccessMutation,
  useLazyGetViewAccessQuery,
  ViewOrderBy,
} from '@/API/views.api';
import PaginatedTableControlled from '@/components/paginated-table-controlled/PaginatedTableControlled';
import PaginatedTableSkeleton from '@/components/skeletons/paginated-table-skeleton/PaginatedTableSkeleton';
import { DEFAULT_TOAST_DURATION, paginationDefault, ToastTypes } from '@/constants/defaults';
import { SORTING_ORDER } from '@/constants/ui';
import RequestAccessModal from '@/modals/access-modal/RequestAccess.modal';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { selectIsConfirmActionModalOpen, selectIsViewAccessEditorOpen } from '@/store/slices/ui.slice';
import { selectTargetView } from '@/store/slices/views.slice';
import { ApiError } from '@/types/api.types';
import View, { ViewListItem } from '@/types/view.types';
import { lbBaseUrl, openViewInNewTab } from '@/utils/url';

const DEFAULT_SORTING: SortingState = [
  {
    desc: false,
    id: 'name',
  },
];

const REQUEST_ACCESS_ERROR_MESSAGE = 'There was an error trying to give you access for this view.';
const REQUEST_ACCESS_SUCCESS_MESSAGE = 'You now have access to the view.';

interface ViewsListViewProps {
  readonly isLoading: boolean;
  readonly publicViewsData: PublicViewsListItem[];
  readonly selectedDepartmentIds: number[];
  readonly selectedTemplateIds: number[];
  readonly selectedViewId: number;
  readonly setSelectedViewId: (viewId: number) => void;
  readonly toggleConfirmCloneModal: () => void;
  readonly toggleConfirmDeleteModal: () => void;
  readonly toggleViewAccessDrawer: () => void;
  readonly toggleViewEditorDrawer: () => void;
  readonly viewNameFilter: string;
}

const ViewsListView = (props: ViewsListViewProps): React.JSX.Element => {
  const {
    isLoading,
    publicViewsData,
    selectedDepartmentIds,
    selectedTemplateIds,
    selectedViewId,
    setSelectedViewId,
    toggleConfirmCloneModal,
    toggleConfirmDeleteModal,
    toggleViewAccessDrawer,
    toggleViewEditorDrawer,
    viewNameFilter,
  } = props;

  const toast = useToast();

  const [canOpenInViewer, setCanOpenInViewer] = React.useState(false);
  const [isRequestAccessModalOpen, setIsRequestAccessModalOpen] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(paginationDefault.PageNumber);
  const [pageSize, setPageSize] = React.useState(paginationDefault.PageSize);
  const [sortingState, setSortingState] = React.useState<SortingState>(DEFAULT_SORTING);

  const getSortingStateById = (columnId: string): ColumnSort | undefined =>
    sortingState.find((col) => col.id === columnId);

  const setSortingStateById = (columnId: string, desc: boolean | undefined): void => {
    // Sorting for only one column at a time
    const sorting = desc !== undefined ? [{ desc, id: columnId }] : [];

    setSortingState(sorting);
  };

  const getSortingOrder = (currentValue: boolean | undefined): SORTING_ORDER => {
    if (currentValue === undefined) {
      return SORTING_ORDER.NONE;
    }

    return currentValue ? SORTING_ORDER.DESC : SORTING_ORDER.ASC;
  };

  // Column name we are ordering by
  const getOrderByColumn = (): ViewOrderBy => {
    const viewNameSort = getSortingOrder(getSortingStateById('name')?.desc);
    const lastModifiedSort = getSortingOrder(getSortingStateById('lastModified')?.desc);
    const publicViewSort = getSortingOrder(getSortingStateById('publicViewId')?.desc);

    if (viewNameSort !== SORTING_ORDER.NONE) {
      return ViewOrderBy.ViewName;
    }

    if (lastModifiedSort !== SORTING_ORDER.NONE) {
      return ViewOrderBy.LastModified;
    }

    if (publicViewSort !== SORTING_ORDER.NONE) {
      return ViewOrderBy.PublicView;
    }

    return ViewOrderBy.None;
  };

  const getIsAscending = (): boolean => {
    const viewNameSort = getSortingOrder(getSortingStateById('name')?.desc);
    const lastModifiedSort = getSortingOrder(getSortingStateById('lastModified')?.desc);
    const publicViewSort = getSortingOrder(getSortingStateById('publicViewId')?.desc);

    return (
      viewNameSort === SORTING_ORDER.ASC ||
      lastModifiedSort === SORTING_ORDER.ASC ||
      publicViewSort === SORTING_ORDER.ASC
    );
  };

  const {
    pagination,
    viewsList = [],
    isLoading: isViewsListLoading,
    isFetching: isFetchingViewsList,
    isError: isViewsListError,
  } = useGetViewsListQuery(
    {
      departmentList: selectedDepartmentIds,
      filter: viewNameFilter,
      isAscending: getIsAscending(),
      orderBy: getOrderByColumn(),
      pagination: { ...paginationDefault, PageNumber: pageNumber, PageSize: pageSize },
      templateList: selectedTemplateIds,
    },
    {
      selectFromResult: ({ data, isLoading, isFetching, isError }) => {
        return {
          isError,
          isFetching,
          isLoading,
          pagination: data?.pagination ?? paginationDefault,
          viewsList: data?.viewsList,
        };
      },
    },
  );

  const [pageIndex, setPageIndex] = React.useState(paginationDefault.PageIndex);
  const [getViewAccess] = useLazyGetViewAccessQuery();
  const [giveSelfAccess, giveSelfAccessResponse] = useGiveSelfAccessMutation();
  const isConfirmModalShown = useAppSelector(selectIsConfirmActionModalOpen);
  const isViewAccessEditorOpen = useAppSelector(selectIsViewAccessEditorOpen);
  const targetView = useAppSelector(selectTargetView);
  const [filters, setFilters] = React.useState<{ id: string; value: string }[]>([]);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    // eslint-disable-next-line no-magic-numbers
    setPageIndex(pageNumber - 1);
  }, [pagination?.PageNumber]);

  React.useEffect(() => {
    setPageIndex(paginationDefault.PageIndex);
    setPageNumber(paginationDefault.PageNumber);
  }, [pageSize, selectedDepartmentIds, selectedTemplateIds, viewNameFilter]);

  const handleAccess = ({ id }: ViewListItem) => {
    setSelectedViewId(id);
    toggleViewAccessDrawer();
  };

  const handleClone = ({ id }: ViewListItem) => {
    setSelectedViewId(id);
    toggleConfirmCloneModal();
  };

  const handleDelete = ({ id }: ViewListItem) => {
    setSelectedViewId(id);
    toggleConfirmDeleteModal();
  };

  const handleEdit = ({ id }: ViewListItem) => {
    setSelectedViewId(id);
    toggleViewEditorDrawer();
  };
  
  const openInViewer = () => {
    openViewInNewTab(selectedViewId);
    setIsRequestAccessModalOpen(false);
  };

  const checkViewAccess = async (id: number) => {
    try {
      const response = await getViewAccess(id).unwrap();

      // Check if the user has access to the current view 
      if (response?.hasAccess) {
        openViewInNewTab(id);
      } else {
        setIsRequestAccessModalOpen(true);
      }
    } catch (error) {
      // Cast unknown error type to ApiError
      const apiError = error as ApiError;
      const message = apiError?.data?.Message ?? REQUEST_ACCESS_ERROR_MESSAGE;

      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: message,
      });
    }
  };

  const handleViewer = ({ id }: ViewListItem) => {
    setSelectedViewId(id);
    checkViewAccess(id);
  };

  const handleViewAccessClose = () => {
    setIsRequestAccessModalOpen(false);
    setCanOpenInViewer(false);
  };

  const openEditAccess = () => {
    const selectedView = viewsList.find((v) => v.id === selectedViewId);
    if (selectedView) handleAccess(selectedView);
    handleViewAccessClose();
  };

  const handleGiveAccess = async () => {
    try {
      const response = await giveSelfAccess(selectedViewId).unwrap();

      // Check if the view was deleted successfully
      if (response?.gainedAccess) {
        setCanOpenInViewer(true);
        toast({
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          position: 'top',
          status: ToastTypes.SUCCESS,
          title: REQUEST_ACCESS_SUCCESS_MESSAGE,
        });
      }
    } catch (error) {
      // Cast unknown error type to ApiError
      const apiError = error as ApiError;
      const message = apiError?.data?.Message ?? REQUEST_ACCESS_ERROR_MESSAGE;

      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: message,
      });
    }
  };

  const openAccessModal = (
    <RequestAccessModal
      canOpenInViewer={canOpenInViewer}
      handleGiveAccess={handleGiveAccess}
      isGetViewAccessLoading={giveSelfAccessResponse.isLoading}
      isOpen={isRequestAccessModalOpen}
      onClose={handleViewAccessClose}
      onEditAccess={openEditAccess}
      openInViewer={openInViewer}
    />
  );

  const getRowControls = (view: ViewListItem) => {
    return (
      <ButtonGroup>
        <Button colorScheme={'blue'} variant={'ghost'} onClick={() => handleAccess(view)}>
          Access
        </Button>
        <Button colorScheme={'blue'} variant={'ghost'} onClick={() => handleClone(view)}>
          Clone
        </Button>
        <Button colorScheme={'blue'} variant={'ghost'} onClick={() => handleDelete(view)}>
          Delete
        </Button>
        <Button colorScheme={'blue'} variant={'ghost'} onClick={() => handleEdit(view)}>
          Edit
        </Button>
        <Button colorScheme={'blue'} variant={'ghost'} onClick={() => handleViewer(view)}>
          Viewer
        </Button>
      </ButtonGroup>
    );
  };

  // This can probably be removed now that the backend has proper filtering
  const data = React.useMemo(
    () =>
      viewsList
        ?.map((view) => {
          // View matches selected departments?
          if (!isViewAccessEditorOpen && selectedDepartmentIds.length) {
            const viewDepartmentsMatchSelected = view.departments.some((id) => {
              return selectedDepartmentIds.includes(id);
            });

            // If no view department matches any selected department id,
            // continue to the next view
            if (!viewDepartmentsMatchSelected) return;
          }

          return {
            ...view,
            rowControls: getRowControls(view),
            updatedTimestamp: DateTime.fromISO(view.updatedTimestamp).toFormat("ccc, dd LLL yyyy HH:mm:ss 'GMT'"),
            updatedTimestampCompact: DateTime.fromISO(view.updatedTimestamp).toFormat('yyyy-MM-dd'),
          };
        })
        .filter((view) => view),
    [
      viewsList,
      selectedDepartmentIds,
      selectedTemplateIds,
      pageNumber,
      pageSize,
      pageIndex,
      viewNameFilter,
      publicViewsData,
    ],
  );

  const getViewPublicViewIdCell = (publicViewId: string): React.JSX.Element => {
    return publicViewId && lbBaseUrl ? (
      <Link href={`${lbBaseUrl}/public/${publicViewId}`} color={'blue.400'} isExternal>
        <Flex w={'150px'} h={'100%'} justify={'space-around'} style={{ textAlign: 'center' }} alignItems={'center'}>
          Visit Public View
          <FaExternalLinkAlt />
        </Flex>
      </Link>
    ) : (
      <Box>No Public View</Box>
    );
  };

  const columnHelper = createColumnHelper<ViewListItem>();

  const columns: ColumnDef<ViewListItem, string>[] = [
    columnHelper.accessor((view) => view.name, {
      cell: (info) => info.getValue(),
      header: () => <Box>View Name</Box>,
      id: 'name',
    }),
    columnHelper.accessor((view) => view.updatedTimestampCompact, {
      cell: (info) => info.getValue(),
      header: () => <Box>Last Modified</Box>,
      id: 'lastModified',
    }),
    columnHelper.accessor((view) => view.publicViewId, {
      cell: (info) => getViewPublicViewIdCell(info.row.original.publicViewId ?? ''),
      header: () => <Box>Public View</Box>,
      id: 'publicViewId',
    }),
    columnHelper.display({
      cell: (info) => getRowControls(info.row.original),
      id: 'rowControls',
    }),
  ];

  const handleGoToPage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };
  const handleNextPage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handlePreviousPage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handleSetPageSize = (pageSize: number) => {
    setPageNumber(paginationDefault.PageNumber);
    setPageSize(pageSize);
  };

  const getIsLoading = (): boolean => {
    return isLoading || isFetchingViewsList || isViewsListLoading;
  };

  if (getIsLoading()) {
    return <PaginatedTableSkeleton />;
  }

  // eslint-disable-next-line no-magic-numbers
  if (!data || data.length === 0) {
    return (
      <Box>
        <Text>No views found</Text>
      </Box>
    );
  }

  const getSortingState = (column: string) => {
    const currentSorting = sortingState.find((item) => item.id === column)?.desc;

    // If no sorting, set sorting to ascending (desc: false)
    if (currentSorting === undefined) {
      return false;
    }

    // If sorting is descending, set sorting to undefined (remove sorting)
    if (currentSorting) {
      return undefined;
    }

    // If sorting is ascending, set sorting to descending (desc: true)
    return true;
  };

  return (
    <>
      {openAccessModal}
      <PaginatedTableControlled
        columns={columns}
        data={data as unknown as View[]}
        filters={filters}
        headerSortHandlers={[
          {
            columnId: 'name',
            sortHandler: () => setSortingStateById('name', getSortingState('name')),
          },
          {
            columnId: 'lastModified',
            sortHandler: () => setSortingStateById('lastModified', getSortingState('lastModified')),
          },
          {
            columnId: 'publicViewId',
            sortHandler: () => setSortingStateById('publicViewId', getSortingState('publicViewId')),
          },
        ]}
        isLoading={getIsLoading()}
        manualSorting={true}
        onGoToPage={handleGoToPage}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
        onSetPageSize={handleSetPageSize}
        overridePaginationModel={true}
        pagination={{ ...pagination, PageIndex: pageIndex }}
        sortingState={sortingState}
      />
    </>
  );
};

export default ViewsListView;
