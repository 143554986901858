import React from 'react';

import { useGetDepartmentsQuery } from '@/API/departments.api';
import { DepartmentSlim } from '@/types/department.types';
import { TemplateSlim } from '@/types/template.types';

const useDepartments = () => {
  const departmentIdTemplateMap: Map<number, number[]> = new Map();
  const { isLoading, isFetching, isError } = useGetDepartmentsQuery();
  const { departments, templates } = useGetDepartmentsQuery(undefined, {
    // Used behind the scenes by RTK-Query to select data from the cache
    selectFromResult: ({ data }) => {
      const departments: DepartmentSlim[] = [];
      const templates: Map<number, TemplateSlim> = new Map();

      data?.forEach((department) => {
        departments.push({ departmentId: department.department_id, departmentName: department.name });
        department.templates.forEach((template) => {
          if (!departmentIdTemplateMap.has(department.department_id)) {
            departmentIdTemplateMap.set(department.department_id, [template.template_id]);
          } else {
            const currentDepartmentTemplates = departmentIdTemplateMap.get(department.department_id) ?? [];

            departmentIdTemplateMap.set(department.department_id, [
              ...currentDepartmentTemplates,
              template.template_id,
            ]);
          }

          if (!templates.has(template.template_id)) {
            templates.set(template.template_id, {
              templateId: template.template_id,
              templateName: template.name,
            });
          }
        });
      });

      return {
        departments,
        templates: Array.from(templates.values()),
      };
    },
  });

  const [selectedDepartmentIds, setSelectedDepartmentIds] = React.useState<number[]>([]);
  const [selectedTemplateIds, setSelectedTemplateIds] = React.useState<number[]>([]);

  const templatesForSelectedDepartments = React.useMemo(() => {
    const templatesForSelectedDepartments: Map<number, TemplateSlim> = new Map();

    // Bail if no departments are selected
    if (!selectedDepartmentIds.length) {
      setSelectedTemplateIds([]);
      return [];
    }

    selectedDepartmentIds.forEach((departmentId) => {
      const templatesForDepartment = departmentIdTemplateMap.get(departmentId) ?? [];
      templatesForDepartment.forEach((templateId) => {
        if (!templatesForSelectedDepartments.has(templateId)) {
          const template = templates.find((template) => template.templateId === templateId);
          if (template) {
            templatesForSelectedDepartments.set(templateId, template);
          }
        }
      });
    });

    const availableTemplateIds = Array.from(templatesForSelectedDepartments.keys());
    const availableSelectedTemplates = selectedTemplateIds.filter((templateId) =>
      availableTemplateIds.includes(templateId),
    );

    setSelectedTemplateIds(availableSelectedTemplates);

    return Array.from(templatesForSelectedDepartments.values()).sort((a, b) =>
      a.templateName.localeCompare(b.templateName),
    );
  }, [selectedDepartmentIds]);

  return React.useMemo(
    () => ({
      departmentIdTemplateMap,
      departments,
      isError,
      isFetching,
      isLoading,
      selectedDepartmentIds,
      selectedTemplateIds,
      setSelectedDepartmentIds,
      setSelectedTemplateIds,
      templates,
      templatesForSelectedDepartments,
    }),
    [
      selectedDepartmentIds,
      selectedTemplateIds,
      templatesForSelectedDepartments,
      isError,
      isFetching,
      isLoading,
      selectedDepartmentIds,
      templates,
      templatesForSelectedDepartments,
    ],
  );
};

export default useDepartments;
