import { Box, HStack, Text } from '@chakra-ui/react';
import React from 'react';

interface PreviewCellProps {
  text: string;
  onClick: () => void;
  color?: string | null;
  colorText?: string | null;
  isSelected?: boolean;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
}

const FULL_SIZE = '100%';

const PreviewCell = (props: PreviewCellProps): React.JSX.Element => {
  const {
    color = undefined,
    colorText = undefined,
    icon = <></>,
    isSelected = false,
    onClick = () => void {},
    text = '',
    style
  } = props;

  return (
    <Box w={FULL_SIZE} h={FULL_SIZE} onClick={onClick} style={style}>
      <HStack
        justifyContent={'space-between'}
        h={FULL_SIZE}
        padding={'10px'}
        backgroundColor={isSelected ? 'blue.400' : ''}
      >
        <Text>{text}</Text>
        {(color || colorText) && (
          <Box w={'20px'} backgroundColor={color ?? ''} color={colorText ?? ''} h={'20px'} textAlign={'center'}>
            <Text>A</Text>
          </Box>
        )}
        {icon}
      </HStack>
    </Box>
  );
};

export default PreviewCell;
